body {
  .MuiAccordion-root.MuiPaper-elevation1 {
    &:not(.no-elevation) {
      @include elevation(1);
    }

    &.no-elevation {
      box-shadow: none;

      &::before {
        display: none;
      }
    }

    .MuiAccordionSummary-root {
      min-height: 24px;
      transition: all 500ms ease-in-out;

      .MuiAccordionSummary-content h3 {
        margin-bottom: 0;
      }

      &.Mui-focused, &.Mui-focusVisible {
        @include nte-focus;

        background-color: transparent;
      }
    }
  }

  .MuiAccordionDetails-root {
    ul.link-list,
    ul.facet-list {
      @include scaled-spacing(padding, 3, 16px);

      li {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .MuiCheckbox-root {
      padding: 0 8px 0 0;
    }

    svg.MuiSvgIcon-root {
      height: 32px;
      width: 32px;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .MuiAccordionSummary-content,
  .MuiAccordionSummary-content.Mui-expanded {
    display: inline-block;
    margin: 0;
    transition: none;
  }

  .MuiAccordion-root {
    border-radius: $radius;
    transition: all $global-transition ease-in-out;

    h3 {
      transition: all $global-transition ease-in-out;
    }
  }

  .MuiCollapse-hidden {
    display: none;
  }
}
