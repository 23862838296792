.store-index-list {
  columns: 2;
}

.list-oc-create,
%list-oc-create {
  list-style: disc;
  margin-left: 40px;
  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

body .MuiListItem-root {
  &.Mui-focusVisible,
  &:focus {
    @include nte-focus;
    background-color: transparent;
  }
}
