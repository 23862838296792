// Semantic Colors
$c-beige: #faebbf;
$c-green: #008500;
$c-light-blue: #0b91cf;
$c-blue: #1460aa;
$c-red: #900;
$c-bright-red: #e00000;
$c-yellow: #f5b100;
$c-brown: #85620b;
$c-tan: #f4f3ef;

// Grays
@function gray($intensity, $alpha: 1) {
  @return rgba($intensity, $intensity, $intensity, $alpha);
}

$c-gray-f2: #f2f2f2;
$c-gray-e5: #e5e5e5;
$c-gray-c8: #c8c8c8;
$c-gray-89: #898989;
$c-gray-76: #767676;
$c-gray-aa-readable: $c-gray-76;
$c-gray-50: #505050;
$c-text: #000;

$c-body-background-color: $c-gray-f2;
$c-divider: $c-gray-c8;

// Brand colors (inherited from the above semantic colors)
$c-primary: #000;
$c-secondary: $c-yellow;
$c-yellow-bright: #f5cc00;
$c-gold: #e6c177;
$c-cta: $c-red;
$c-link: $c-blue;
$c-info: $c-brown;
$c-success: $c-green;
$c-warning: $c-bright-red;

// Focus colors
$color-focus-light: $c-gray-c8;
$color-focus-dark: $c-gray-50;

// Grid settings
$grid-base: 4px;

// Multiplies px variables
@function gridx($value) {
  @return $grid-base * $value;
}

$global-height: gridx(14);

// Typography
$family-sans-serif: arial, sans-serif;
$family-h1: proxima-nova-condensed, sans-serif;
$family-condensed: $family-h1;
$family-headers: proxima-nova, sans-serif;

$font-size-base: gridx(4);

$body-line-height: 24px;

// Visual Aids
$box-shadow: 0 6px 12px rgba(10, 10, 10, 0.2);

$global-transition: opacity 300ms ease-in;
$link-transitions-timing: 200ms;

$border-radius-large: 3px;
$border-radius-small: 1px;

$icon-width: gridx(7);
$easing: ease-out;
$radius-small: 2px;
$radius: 3px;
$radius-large: 4px;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: $grid-base * 18;

// Sticky Notes
// mobile: 320
// tablet: 600
// desktop: 960
// widescren: 1280
// fullhd: 1440

$vs_360: 360px;
$sm_600: 600px;
$md_960: 960px;
$lg_1280: 1280px;
$xl_1440: 1440px;

$max-width: $xl_1440;

$breakpoints: (
    vsmall: $vs_360,
    small: $sm_600,
    medium: $md_960,
    large: $lg_1280,
    xlarge: $xl_1440,
);

$sidebar-wide-fixed-width: 260px;
$optimal-readability-width: 720px;

// Animation
$global-transition: 300ms;

// SVG Icons
$data-svg-prefix: 'data:image/svg+xml;utf-8,';

$svg-icons: (
  'caret': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>',
  'nte-radio': '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.997.035c-6.609 0-11.973 5.365-11.973 11.974 0 6.611 5.364 11.976 11.973 11.976 6.608 0 11.972-5.365 11.972-11.976 0-6.609-5.364-11.974-11.972-11.974zm0 21.554c-5.293 0-9.579-4.286-9.579-9.58 0-5.292 4.286-9.58 9.579-9.58 5.292 0 9.577 4.288 9.577 9.58 0 5.294-4.285 9.58-9.577 9.58z"/></svg>',
  'nte-radio-checked': '<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.977.05c-6.608 0-11.972 5.364-11.972 11.973 0 6.608 5.364 11.972 11.972 11.972 6.609 0 11.973-5.364 11.973-11.972 0-6.609-5.364-11.973-11.973-11.973z" fill="#f5b100"/><path d="m12.024 21.299c-5.151 0-9.323-4.172-9.323-9.323 0-5.149 4.172-9.322 9.323-9.322 5.149 0 9.322 4.173 9.322 9.322 0 5.151-4.173 9.323-9.322 9.323z" fill="#fff"/><path d="m12.051 5.029c-3.827 0-6.934 3.123-6.934 6.971s3.107 6.971 6.934 6.971c3.828 0 6.935-3.123 6.935-6.971s-3.107-6.971-6.935-6.971z"/></svg>'
);

