// Spacing: Utility class .marginless
//
// Forcefully removes any bottom margin which may be on an element.
//
// Markup:
// <div class="cg-cols-2"><div class="nte-card"><h3>h3 element inside cards have a native bottom margin</h3><hr></div><div class="nte-card"><h3 class="marginless">h3 element inside card with native bottom margin removed</h3><hr></div></div><hr>
//
// Styleguide Spacing.Utility Classes.marginless
.marginless {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

// Spacing: Utility class .prompt-margin
//
// This class forces a 16px bottom margin on an element.\
// Used when there may be a subtitle under a title
//
// Markup:
// <div class="cg-cols-2"><div class="nte-card"><h2>h2 title element</h2><p>Native margin for h2</p><hr></div><div class="nte-card"><h2 class="prompt-margin">h2 title element</h2><p>Native margin for subtitle pattern</p><hr></div></div><hr>
//
// Styleguide Spacing.Utility Classes.prompt-margin
.prompt-margin {
  margin-bottom: 16px !important;
}
.prompt-margin-top {
  margin-top: 16px !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

.promt-margin-bottom {
  margin-bottom: 32px !important;
}

// Spacing: Utility class .field-margin
//
// In a form, input fields (wrapped in a fieldset.not-mui) have a responsive bottom margin. This
// class will mirror that bottom margin to any element.
//
// Markup:
// <div class="cg-cols-2"><div><fieldset class="not-mui"><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /></fieldset><button class="MuiButtonBase-root MuiButton-root MuiButton-contained">Native button layout (no margin)</button><hr></div><div><fieldset class="not-mui"><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /></fieldset><button class="field-margin MuiButtonBase-root MuiButton-root MuiButton-contained">Button with field margin</button><hr></div></div>
//
// Styleguide Spacing.Utility Classes.field-margin
body {
  .MuiButtonBase-root.MuiButton-root.field-margin:last-child,
  .field-margin,
  %field-margin {
    @include scaled-spacing(margin-bottom, 3, 32px);
    @include media('<=720px') {
      margin-bottom: 32px;
    }
  }
  .field-margin-top,
  %field-margin-top {
    @include scaled-spacing(margin-top, 2, 28px);
    @include media('<=720px') {
      margin-top: 28px;
    }
  }
  .top-margin28,
  %top-margin28 {
    @include scaled-spacing(margin-top, 3, 28px);
  }
  .section-row-top,
  %section-row-top {
    @include scaled-spacing(margin-top, 5, 40px);
    @include media('<=667px') {
      margin-top: 36px;
    }
  }
}

// Spacing: fieldset.not-mui
//
// Creates bottom margin for form fields it wraps. Needs the .not-mui class because
// Material UI is incorrectly using fieldsets inside their field components.\
// ***NOTE:*** The fieldset element should only wrap fields, not the form buttons, for proper spacing.
//
// Markup:
// <fieldset class="not-mui"><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /><input class="form-input-field" type="text" /></fieldset><button class="MuiButtonBase-root MuiButton-root MuiButton-contained">Button</button><hr>
//
// Styleguide Spacing.Forms.fieldset
fieldset.not-mui,
body .fieldset-margin {
  @include scaled-spacing(margin-bottom, 4, 40px);
}

body {
  .h3-margin,
  .bottom-margin28 {
    @include scaled-spacing(margin-bottom, 4, 28px);
  }
  .h2-margin,
  .bottom-margin32 {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }
  .bottom-margin40 {
    @include scaled-spacing(margin-bottom, 5, 40px);
  }
}

ul.link-list li:not(:last-child) {
  margin-bottom: 40px;
  @include media('>=1080px') {
    margin-bottom: 24px;
  }
}

.page-row-margin {
  @include scaled-spacing(margin-bottom, 5, 56px, 8);
}
