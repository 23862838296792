.pwa-prompt-container {
  background-color: white;
  bottom: 0;
  height: 100px;
  position: fixed;
  width: 100%;
  z-index: 10;
  @include elevation(6);
  .pwa-close-icon {
    font-size: 32px;
    position: absolute;
    right: 16px;
    top: 12px;
  }
  .inner-container {
    @extend .full-width;

    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    position: relative;
    .add-app-text {
      color: #1460aa;
      cursor: pointer;
      display: inline-block;
      padding: 16px;
    }

    svg.logo {
      background-color: $c-gray-50;
      border-radius: 10px;
      height: 56px;
      padding: 8px;
      width: 56px;
    }
  }
}


.mobile-footer-order {
  @media (min-width: 768px) {
    display: none;
  }
  background-color: white;
  bottom: 0;
  height: 7em;
  position: fixed;
  left: 0;
  padding-top: 1em;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
  z-index: 10;
  @include elevation(5);
  .pwa-close-icon {
    font-size: 32px;
    position: absolute;
    right: 16px;
    top: 12px;
  }
  .inner-container {
    @extend .full-width;

    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    position: relative;
    .add-app-text {
      color: #1460aa;
      cursor: pointer;
      display: inline-block;
      padding: 16px;
    }

    svg.logo {
      background-color: $c-gray-50;
      border-radius: 10px;
      height: 56px;
      padding: 8px;
      width: 56px;
    }
  }
}
