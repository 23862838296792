.account_dashboard-page {
  .MuiCardContent-root {
    .MuiGrid-root {
      margin-bottom: 16px;

      a {
        font-size: 14px;
      }
    }
  }

  span.badge {
    position: absolute;
    right: 0;
    top: 0;
    @include media('>=768px') {
      left: 4px;
      right: auto;
      top: 4px;
    }
  }
  @include media('<960px') {
    .account-menu-wrapper {
      display: none;
    }
  }

  .nav-left-content {
    @include media('<960px') {
      .nte_accordion.account-menu {
        margin-bottom: 24px;
      }
    }
    @include media('>=960px') {
      column-gap: 32px;
      display: grid;
      grid-template-columns: 240px 1fr;
      grid-template-rows: auto;

      .nte_accordion.account-menu {
        display: none;
      }
    }
    @include media('>1180px') {
      grid-template-columns: 300px 1fr;
    }
  }

  .right {
    span.label {
      @include media('<=600px') {
        display: block;
      }

      color: #000;
      font-size: 11px;
    }

    .content-wrap {
      overflow-wrap: anywhere;
    }

    @include media('<720px') {
      .cg-cols-3 {
        grid-template-columns: repeat(auto-fit, minmax(260px, 680px));
      }
    }

    .product-name a {
      font-size: 16px;
    }
  }

  .MuiFormGroup-root[role='radiogroup'] {
    flex-direction: column;
  }

  @include media('<=960px') {
    .cg-cols-3.billing-address {
      grid: auto / 1fr;
    }
  }

  @include media('<520px') {
    .account_information_card {
      .label {
        display: block;
      }

      .content {
        margin-left: 16px;
      }
    }
  }
  @include media('>=1024px') {
    .account_information_card {
      .label {
        display: block;
      }

      .content {
        margin-left: 16px;
      }
    }
  }
  @include media('>=720px', '<1280px') {
    .account_profile_card {
      grid-area: profile;
    }

    .billing_address_card {
      grid-area: billing;
    }

    .shipping_address_card {
      grid-area: shipping;
    }

    .cg-cols-3.account_dashboard {
      grid:
        [row1-start] 'profile profile' auto [row1-end]
        [row2-start] 'billing shipping' auto [row2-end]
        / 1fr 1fr;
      word-break: break-word;
    }
  }
  @include media('>=1280px') {
    .account_profile_card {
      p {
        span {
          display: block;
        }
      }
    }
  }

  .MuiCardActions-root {
    display: block;

    form {
      margin-left: 0;
    }
  }

  .button-row {
    margin: 0;
    max-width: 300px;
  }

  .address-card {
    position: relative;
  }

  .shipping_address_card,
  .billing_address_card {
    .add-card {
      text-align: center;
    }
  }

  .MuiFormControl-root {
    width: auto;
  }

  .list-header {
    grid-gap: 8px;
    grid-template-columns: auto 164px 1fr;
  }

  .myorders-order-count {
    text-align: left;
    @include media('>425px') {
      text-align: right;
    }
  }
}

.add_shipping_address {
  .address-container {
    width: 300px;
  }
}

.MuiSkeleton-root {
  &.account-dashboard-skeleton {
    height: 50px;
    margin-bottom: 20px;
  }
}

.account-form {
  h2,
  h2 + p,
  h3 {
    @include scaled-spacing(margin-bottom, 4, 24px);
  }

  .button-row {
    margin-left: 0;
    width: 100%;
    @include media('>=small') {
      width: 300px;
    }
  }
}

.password-requirements-container {
  margin-top: 12px;
}

.requirements li {
  display: flex;
  margin: 8px 0;

  p {
    margin-left: 8px;
  }
}

.password-change-wrapper {
  grid-template-columns: 300px 1fr;
  @include media('<720px') {
    display: block;
  }
}

#email-deals {
  .MuiSvgIcon-root {
    fill: green;
  }
}

.account-menu li {
  a {
    color: $c-gray-50;
  }
}

.privacy-policy-link.MuiTypography-root {
  margin-left: 32px;
}

.account-profile-page {
  .elevated-content-block {
    margin-bottom: 32px;
  }
}

.account_registration_form {
  .checkbox-wrapper .MuiFormControlLabel-root {
    align-items: flex-start;

    .MuiTypography-body1 {
      font-size: 14px;
    }
  }

  .set-password-wrapper {
    grid-template-columns: 300px 300px;

    @include media('<960px') {
      grid-template-columns: 300px;
      margin-bottom: 24px;
    }
  }

  .password-input-error {
    margin-bottom: 0;
    label.MuiFormLabel-root {
      color: $c-warning;
    }
  }

  fieldset.not-mui {
    .MuiFormControl-root.password-input-error {
      margin-bottom: 0;
    }
  }
}

.MuiTypography-root.custom-error-text {
  color: $c-warning;
  margin: 0 14px 20px 14px;
}

// Saved Lists
.saved-lists {
  &.cg-cols-auto {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }

  .MuiCard-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 8px;
  }

  .intrinsic-image {
    @include intrinsic-ratio(120 120);
  }

  button.block-wrapper {
    display: block;
    text-align: left;
  }

  .MuiCardActions-root {
    column-gap: 16px;
    display: flex;
  }
}

.saved_list_card {
  a {
    padding: 4px;

    .thumbnail {
      transition: all $global-transition ease-in-out;
    }

    &:hover {
      h3 {
        @include link-animation;
      }

      .thumbnail {
        @include elevation(1);
      }

      p {
        text-decoration: underline;
        text-decoration-color: transparent;
      }
    }
  }

  .cg-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.customer_number {
  .MuiTypography-body1 {
    margin-bottom: 16px;
  }

  ul {
    list-style: disc;
    @include scaled-spacing(margin-bottom, 3, 24px);

    li {
      margin-left: 24px;
    }
  }
}

.saved-products {
  .button-row {
    margin-bottom: 24px;
  }
}

.wishlist-load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.page-number-container {
  background-color: $c-gray-e5;
  border-radius: $radius;
  margin-right: 8px;
  padding: 8px 12px;
}

.saved-card {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;

  .masked {
    min-width: 112px;
  }

  @include media('<=414px') {
    .MuiFormControl-root {
      margin-bottom: 16px;
      margin-right: 12px;
    }
  }
  @include media('>460px') {
    .MuiFormControl-root + .flex-break-row {
      display: none;
    }
  }
  @include media('>=660px') {
    .flex-break-row {
      display: none;
    }
  }

  &.nte30saved {
    .flex-break-row {
      display: none;
    }

    @include media('<378px') {
      .credit-card-image-container,
      p {
        margin-bottom: 16px;
      }
    }
  }
}

.my_tax_information {
  .nte_table {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }

  > div {
    margin: 0;
  }
}

.recent-order-section {
  @include media('>=600px') {
    .recent-order-title {
      padding-bottom: 16px;
    }

    .header-section {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  a {
    padding-right: 16px;
  }
}

.order-history-card {
  background-color: #fff;
  border-right: $radius;
  margin-bottom: 4px;
  @include elevation(1);

  h2 {
    color: $c-green;
  }

  .order_item {
    .order-wrapper-mobile {
      .cg-cols-2 {
        grid: auto / 120px 1fr;
      }

      .button-row {
        max-width: 100%;
      }
    }

    &:not(:last-child) {
      @include scaled-spacing(margin-bottom, 5, 32px);
    }

    &:last-child {
      hr {
        display: none;
      }
    }

    .cg-cols-2.order-wrapper {
      grid-template-columns: 120px 1fr;
      row-gap: 0;
    }
  }

  .order-title-container {
    p.MuiTypography-body1 {
      margin-bottom: 4px;
    }
    @include media('>480px', '<600px') {
      & > div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:not(.order-info) {
          align-items: center;
          column-gap: 8px;
          display: flex;

          p,
          h3:not(.icon-text) {
            display: inline-block;
          }
        }
      }
    }
    @include media('>599px') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }
    @include media('>1260px') {
      display: flex;
      justify-content: space-between;
    }

    p {
      color: $c-gray-aa-readable;
    }

    .shipment-info {
      position: relative;

      .shipped-info-icon {
        cursor: pointer;
      }
    }

    // Order history card mobile rules
    .order_title-order-date > *,
    .order_title-shipment-info > * {
      @include media('<=425px') {
        display: inline-block;
        margin-right: 12px;
      }
    }
  }

  .order-body-container {
    .order-body-header {
      display: flex;
      justify-content: space-between;
    }

    .thumbnail {
      height: 120px;
      width: 120px;
    }

    .product-title {
      margin-bottom: 16px;
    }

    .cg-cols-2 {
      margin-bottom: 24px;
    }

    .product-info-block {
      .cg-cols-2 {
        column-gap: 24px;
        grid-template-columns: auto 1fr;
      }

      .MuiAlert-root {
        margin-left: 0;
      }
    }
  }

  .order_item_asset .pdf-icon {
    fill: $c-blue;
  }

  .MuiAlert-standardWarning {
    margin: 16px auto;
    width: 75%;
  }
}

.guest-order-history-page {
  .page-title {
    padding-top: 16px;
  }
}

.order_lookup_form {
  .MuiFormGroup-root.order_lookup_form--radio-group {
    grid-template-columns: repeat(1, minmax(80px, 300px));
    grid-gap: 0;
    margin-bottom: 24px;
  }
}

.customer-info {
  padding-bottom: 16px;
}

.no-orders-container {
  display: flex;
  height: 400px;

  .MuiAlert-standardWarning {
    margin: auto;
  }
}

.add_new_saved_card {
  .add_debit_credit_card {
    margin-top: 24px;
  }

  .credit-debit-block {
    grid-template-areas:
      'card-input'
      'expiration-cell'
      'button';

    @include media('>=548px') {
      grid-template-areas:
        'card-input expiration-cell'
        'button button';
      grid-template-columns: 300px 1fr;
    }
    @include media('>=768px') {
      grid-template-areas: 'card-input expiration-cell button';
      grid-template-columns: 300px 216px 100px;
    }
  }

  .expiration-cell {
    grid-template-areas:
      'exp-mos exp-yr'
      'security security';
    grid-template-columns: 130px 130px;
    @include media('>768px') {
      grid-template-areas: 'exp-mos exp-yr security';
      grid-template-columns: 96px 96px 120px;
    }
  }
}

.sign_in_page {
  .page-row {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

#share-list-modal {
  button {
    max-width: 160px;
  }
}

.MuiGrid-root.MuiGrid-container.MuiGrid-item.account_card {
  display: block;

  p {
    display: flex;
    margin-bottom: 0;

    span {
      margin-bottom: 0;
    }
  }
}

.send_invoice_form {
  .form-row {
    @include media('<768px') {
      flex-basis: 100%;
      flex-direction: column;
      grid-row-gap: 24px;
    }
  }
}

.nte30saved {
  display: inline-flex;
  .masked {
    margin-left: 12px;
    min-width: 112px;
  }
}

.trevipaySaved {
  display: flex;
  .trevi-saved-card {
    background-color: #f0f0f0;
    padding: 24px 16px;
    margin-bottom: 24px;
    display: grid;
    img {
      width: 265px;
    }
    .powered-by-trevi {
      font-style: italic;
    }
  }

  .remove-trevi {
    margin-left: 8px;
  }
}
