@use '../vendor/nte_bootstrap';

// Bootstrap grid customizations
.cmc-content {
  .row {
    @include scaled-spacing(gap, 5, 32px);
  }
  .test div[class^='g-col'] {
    background-color: $c-gray-c8;
    border: 1px solid $c-gray-50;
  }
}
