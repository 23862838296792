button,
.cmc-content button:not(.inline-text),
a.button-style,
button.MuiButtonBase-root,
.fsSubmitButton {
  @include elevation(1);

  border-radius: $radius;
  cursor: pointer;
  font-family: $family-h1;
  height: 48px;
  min-width: auto;
  padding: 11px 24px;
  position: relative;
  transition: all $global-transition ease-in-out;
  white-space: nowrap;

  &:not(.MuiButton-fullWidth) {
    max-width: 300px;
  }

  &:hover {
    background-color: rgb(0 0 0 / 4%);
  }

  &:focus {
    @include nte-focus;
  }

  &.MuiButton-sizeLarge,
  &.btn-large {
    font-size: 18px;
    height: 56px;
    line-height: 24px;
    padding: 16px 32px;
  }

  &.MuiButton-sizeSmall,
  &.btn-small {
    font-size: 15px;
    height: 40px;
    line-height: 16px;
    padding: 12px 16px;

    &.field-inline {
      height: 48px;
      line-height: 24px;
      padding: 16px;
    }
  }

  &.nte_collapse {
    box-shadow: none;
  }

  span.MuiButton-endIcon {
    left: calc(50% - 12px);
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.MuiButton-contained,
  &.contained {
    background-color: $c-cta;
    color: #fff;
    @include elevation(2);

    &:hover {
      @include elevation(4);

      background-color: darken($c-cta, 4%);
    }
  }

  &.MuiButton-outlined,
  &.outlined {
    border: 1px solid $c-cta;
  }

  &.MuiButton-reverse {
    background-color: #000;
    color: #fff;
  }

  &:disabled,
  &.disabled {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    box-shadow: none;
    color: #737373;
    cursor: none;
  }

  &.MuiButton-text {
    border-radius: 0;
    box-shadow: none;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
    text-align: left;

    &.text-only {
      // Utility class
      height: auto;
      min-width: auto;
      padding: 0;
      width: auto;

      &:hover {
        background-color: transparent;
      }
    }

    &:not(.no-link-effect) {
      .MuiButton-label {
        @include link-effect;

        width: auto;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  &.plain-icon-button,
  &.plain-icon-button.MuiIconButton-root {
    @extend %reset-button;

    padding: 0;
  }
}

// Accommodate the focus drop shadow
button,
.cmc-content button:not(.inline-text),
a.button-style,
.MuiButtonBase-root,
.fsSubmitButton {
  margin: 2px;
}

.cmc-content .accordion button.header {
  max-width: 100%;
}

.fsSubmitButton {
  background-color: $c-cta;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  @include elevation(2);

  &:hover {
    @include elevation(4);

    background-color: darken($c-cta, 4%);
  }
}

a.button-style {
  @extend %no-link-effect;

  display: inline-block;
  font-weight: 700;
  text-decoration: none;

  &:focus {
    --offset: 0;
  }

  &.disabled {
    pointer-events: none;

    .loader-icon,
    svg {
      left: 0;
      position: absolute;
      right: 0;
      top: 40%;
      transform: translateY(-50%);
    }
  }
}

button.inline-text,
button.MuiButtonBase-root.MuiButton-root.inline-text {
  @extend %reset-button;
  @include link-effect;

  background-color: inherit;
  display: inline-block;
  text-decoration: none;
  vertical-align: baseline;

  &.size14,
  &.MuiButton-textSizeSmall {
    font-size: 14px;
  }

  &.link-wrapped-card {
    display: block;
    text-decoration: none;
    transition: all $global-transition ease-in-out;
    width: 100%;

    &:hover {
      @include elevation(6);

      .link-effect {
        text-decoration: underline;
      }
    }
  }

  &.dark-ui {
    background-color: #000;
  }

  &.nte-card {
    height: 100%;
  }
}

// CMC Buttons
.cmc-content {
  button:not(.inline-text) {
    @include media('<=720px') {
      @include truncate(280px);
    }

    border: 1px solid $c-cta;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    outline: none;
    white-space: nowrap;
  }

  // Links styled as buttons
  a {
    &.btn,
    &.btn-small,
    &.btn-large {
      @include elevation(1);
      @include media('<=720px') {
        @include truncate(280px);
      }

      border: 1px solid $c-cta;
      border-radius: $radius;
      color: #000;
      cursor: pointer;
      display: inline-block;
      font-size: 15px;
      font-weight: 700;
      height: 48px;
      line-height: 24px;
      padding: 11px 24px;
      text-align: center;
      text-decoration: none;
      transition: all $global-transition ease-in-out;

      &:hover {
        background-color: rgb(0 0 0 / 4%);
        text-decoration: none;
      }

      &.btn-small {
        font-size: 14px;
        height: 40px;
        line-height: 16px;
        padding: 12px 16px;
      }

      &.btn-large {
        font-size: 18px;
        height: 56px;
        line-height: 24px;
        padding: 16px 32px;
      }

      &.contained {
        background-color: $c-cta;
        color: #fff;
        @include elevation(2);

        &:hover {
          @include elevation(4);

          background-color: darken($c-cta, 4%);
        }
      }
    }
  }
}

body {
  li.MuiListItem-button {
    @include media('<=600px') {
      white-space: unset;
    }

    transition: all $global-transition ease-in-out;

    &:hover {
      background-color: $c-gray-e5;

      a {
        color: #000;
        text-shadow: -1px 1px 2px rgb(134 134 134 / 20%);
      }

      svg {
        fill: #000;
      }
    }
  }
}

button.MuiButtonBase-root.MuiIconButton-root {
  color: $c-gray-aa-readable;
  font-size: 24px;
  padding: 4px 8px;

  &.nte_collapse {
    border-radius: $radius;
    transition: all $global-transition ease-in-out;

    &:hover {
      background-color: $c-gray-e5;
    }
  }

  &.round-button {
    padding: 4px;
  }

  &.close_icon {
    height: 24px;
    padding: 0;
    transition: all $global-transition ease-in-out;
  }

  &.Mui-focusVisible {
    @include nte-focus;
  }
}

.MuiAlert-action button.MuiButtonBase-root.MuiIconButton-root {
  height: 24px;
  padding: 0;
  transition: all $global-transition ease-in-out;
}

span.MuiButtonBase-root.MuiIconButton-root {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}

.MuiChip-root.MuiChip-clickable {
  align-items: center;
  background-color: $c-gray-e5;
  display: flex;
  padding: 4px 8px;
  @include elevation(2);

  &:hover {
    background-color: $c-gray-c8;
    border-bottom-color: transparent;
  }

  span.MuiChip-label {
    color: #000;
  }
}

.nte_collapse.MuiIconButton-root {
  svg {
    font-size: 32px;
    transition: all $global-transition ease-in-out;
  }

  &.expanded {
    svg {
      transform: rotate(180deg);
    }
  }
}

.MuiFormControlLabel-root .MuiSwitch-root {
  .MuiSwitch-switchBase {
    left: 6px;
    top: 8px;
  }
}

// Removes the box shadow for the IconButton component in the password field
.textIconButton {
  box-shadow: none;
}

.cta_link-row {
  &.page-row {
    display: flex;
    justify-content: center;

    &.left {
      justify-content: flex-start;
    }

    &.right {
      justify-content: flex-end;
    }
  }
}

.seeAll_Categories-btn {
  white-space: normal !important;
  height: fit-content !important;
}
