ul.product-list > li {
  margin-bottom: 4px;
}

//
// Generic product lists on cards
html .product-list,
html .enlarged-product-card {
  //
  // Layout spacing
  .thumbnail {
    margin-bottom: 16px;
  }

  .product-name,
  .part-number {
    margin-bottom: 8px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .product-name-grid {
    @include media('<600px') {
      min-height: 8px;
    }
    @include media('>=600px') {
      min-height: 120px;
    }
  }

  .product_attribute-list {
    li {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .description-cell {
    ul.nte-ul-list {
      margin-bottom: 32px;
    }
  }

  div[class^='nte-price'] {
    margin-bottom: 8px;
  }

  .quantity-field {
    @extend %field-margin;
    @extend %field-margin-top;
  }

  .Add-to-cart-button {
    @include media('<600px') {
      margin-top: 16px;
    }
    @include media('>=600px') {
      margin-top: 24px;
    }
    @extend %field-margin;

    span.MuiButton-label {
      font-size: 16px;
    }
  }

  .product_card button.nte_collapse {
    @include elevation(1);

    border: 1px solid $c-gray-c8;
    height: 40px;
    justify-self: end;
    padding: 0 8px;
    width: 64px;

    &.expanded {
      margin-bottom: 24px;
    }
  }

  .desktop-wrapper {
    @extend %field-margin-top;
  }

  .complete_your_solution_products {
    .description-cell,
    .pricing_and_promotions {
      @extend %field-margin;
    }
  }

  // End of layout
  //

  ///
  // Grid layouts
  .product_card,
  .product-card,
  .enlarged-product-card,
  .grid-product-card {
    @include scaled-spacing(column-gap, 4, 32px);

    display: grid;

    .image-cell {
      grid-area: image;
    }

    .description-cell {
      grid-area: description;
    }

    .messages-cell {
      grid-area: messages;
    }

    .message-cell-grid {
      min-height: 127px;
    }

    .price-cell {
      grid-area: price;
    }

    .cta-cell, .cta-cell0 {
      grid-area: cta;
    }

    .nte_collapse {
      grid-area: collapse;
    }
    .empty_space {
      @include media('<600px') {
        height: 0px;
      }
      @include media('>=600px') {
        height: 30px;
      }
    }
    .empty_space_large {
      height: 213px;
    }

    .MuiCollapse-root {
      grid-area: collapse-content;
    }

    //
    // Cart
    .qty-cell {
      grid-area: qty;

      .quantity-field,
      &.button-row {
        @include scaled-spacing(margin-bottom, 3, 32px);
        @include media('<=720px') {
          margin-bottom: 28px;
        }
      }
    }

    .product-replacement-cell {
      column-gap: 16px;
      display: grid;
      grid-area: replacement;
      grid-template-columns: 60px 1fr;
      margin-bottom: 8px;

      @include media('>=800px') {
        grid-template-columns: 120px 1fr auto;
      }

      @include media('>=1340px') {
        column-gap: 32px;
        grid-template-columns: 140px 1fr auto;
      }

      .icon-cell {
        justify-self: center;
        text-align: center;
        width: 60px;
      }

      @include media('<380px') {
        .field-row-2.auto-width {
          gap: 16px;

          button.inline-text {
            font-size: 14px;
          }
        }
      }
    }

    .product-solution-cell {
      grid-area: solution;
    }

    .cart_item_quantity {
      grid-area: qty;
      margin-top: 8px;
    }

    .cta-cell {
      .field-labels {
        span.MuiButtonBase-root.MuiIconButton-root,
        .card_save_list svg {
          margin-right: 4px;
        }
      }
    }

    &.cart_product_card {
      grid-auto-rows: minmax(0, auto);
      grid-template-areas:
        'image description'
        'price price'
        'qty qty'
        'cta cta'
        'messages messages'
        'replacement replacement'
        'solution solution';
      grid-template-columns: 120px 1fr;

      .price-cell {
        .qty-pricing:not(.link-effect) {
          font-weight: 700;
        }

        .link-effect {
          margin-bottom: 8px;
        }
      }

      .qty-cell {
        .nte_select {
          width: 112px;
        }
      }

      .cta-cell.button-row {
        margin-left: 0;
        width: 100%;
        grid: auto/120px 100px;
        @include media('>=1024px') {
          grid: auto/120px 60px;
        }
        /* Ipad Landscape */
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
          grid: auto/120px 100px;
        }
      }

      @include media('<=1080px') {
        .qty-cell {
          margin-bottom: 24px;
        }

        .messages-cell {
          margin-top: 24px;
        }
      }

      @include media('>=600px', '<768px') {
        .cart_item_quantity {
          display: flex;
          grid-area: qty-cta;
          margin-top: 0;
        }

        grid-template-areas:
          'image description description'
          'price qty-cta qty-cta'
          'messages messages messages'
          'replacement replacement replacement'
          'solution solution solution';
        grid-template-columns: 140px 88px 1fr;

        .price-cell,
        .cta-cell {
          padding-bottom: 16px;
        }

        .qty-cell {
          .quantity-field {
            margin: 0;
          }
        }

        .cta-cell.button-row {
          align-self: start;
          justify-content: end;
          margin: 12px 0 0;
        }
      }
      @include media('>=1080px') {
        grid-template-areas:
          'image description price'
          'image messages qty'
          'replacement replacement replacement'
          'solution solution solution';
        grid-template-columns: 140px 1fr 158px;

        .price-cell {
          justify-self: end;
          text-align: right;
        }

        .cart_item_quantity {
          justify-self: end;
        }

        .qty-cell {
          .nte_select,
          .progress-wrapper {
            float: right;
          }

          .progress-wrapper {
            margin-right: 16px;
          }

          .quantity-field {
            margin-top: 0;
          }
        }

        .cta-cell.button-row {
          float: right;
          width: auto;
        }
      }
      @include media('>=1200px') {
        grid-template-columns: 140px 1fr 20%;
      }
    }
  }
}

.plp_page,
.shopping_cart_page {
  .order-today {
    display: none;
  }
}

//
// PLP and saved products cards
.plp_layout,
.search_page,
.product_card_widget,
.wish_list_products {
  .product_card {
    grid-template-areas:
      'image description'
      'price collapse'
      'collapse-content collapse-content';
    grid-template-columns: 120px 1fr;

    .cta-cell {
      .card_save_list {
        svg {
          margin-right: 4px;
        }

        .MuiTypography-root {
          transform: translateY(1px);
        }
      }
    }
  }
}

.plp_layout,
.search_page,
.product_card_widget,
.enlarged-product-card,
.grid-product-card,
.wish_list_products {
  .product_card {
    @include media('>600px', '<768px') {
      grid-template-areas:
        'image description'
        'price price'
        'messages messages'
        'cta cta';
      grid-template-columns: 140px 1fr;

      .cta-cell {
        max-width: 50%;
      }
    }
    @include media('>=768px') {
      grid-template-areas:
        'image description price'
        'image description messages'
        'image description cta';
      grid-template-columns: 150px 1fr minmax(242px, 245px);
    }
    @include media('>=1024px') {
      grid-template-columns: 204px 1fr minmax(242px, 260px);
    }
  }

  .price-cell {
    margin-bottom: 16px;
  }
}

.plp_layout,
.product-list-search {
  .product_card {
    @include media('<=425px') {
      grid-template-areas:
        'image description'
        'price collapse'
        'collapse-content collapse-content'
        '. cta';
    }
  }

  .image-cell {
    @include media('<=600px') {
      margin-bottom: 24px;
    }
  }

  .price-cell {
    @include media('<=600px') {
      margin: 8px 0 0;
    }
  }

  .product-shipping-list--sold-out span {
    white-space: nowrap;
  }
}

#root .enlarged-product-card .product_card {
  grid-template-columns: 140px 1fr;
  @include media('>=768px') {
    grid-template-columns: 30% 1fr 26%;
  }

  .product-name {
    font-family: $family-headers;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
  }

  .price-cell .nte-price24 {
    font-size: 36px;
    line-height: 36px;
  }

  p.sub-product-offer-price {
    font-size: 16px;
    line-height: 24px;
  }
}

.saved-later-section .saved-products {
  .product_card {
    grid-template-areas:
      'image badges'
      'image description'
      'price price'
      'messages messages'
      'cta cta';
    grid-template-columns: 140px 1fr;

    @include media('>=768px') {
      grid-template-areas:
        'image description price'
        'image description messages'
        'image description cta';
      grid-template-columns: 204px 1fr minmax(200px, 262px);
    }

    @include media('<768px') {
      .description-cell {
        margin-top: 16px;
      }
    }
  }
}

.account_dashboard-page .saved-products {
  .product_card {
    grid-template-areas:
      'image description'
      'price price'
      'collapse-content collapse-content'
      'collapse collapse';
    grid-template-columns: 120px 1fr;

    @include media('>=768px') {
      grid-template-areas:
        'image description price'
        'image description messages'
        'image description cta';
      grid-template-columns: 204px 1fr minmax(200px, 262px);
    }

    @include media('<768px') {
      .description-cell {
        margin-top: 16px;
      }
    }
  }
}

.restricted_items .product-card {
  align-items: start;
  grid-template-areas:
    'image description'
    'image cta';
  grid-template-columns: 120px 1fr;

  .thumbnail {
    margin-bottom: 0;
  }

  .cta-cell {
    justify-self: start;
  }
}

.messages-cell {
  *,
  p,
  .MuiTypography-root {
    font-size: 13px;
    line-height: 20px;
  }

  li {
    margin-bottom: 8px;

    &.product_offer {
      svg {
        transform: translateY(-1px);
      }
    }
  }

  .store-pickup {
    display: inline;
  }

  svg.location-icon {
    height: 24px;
  }

  .fullfillment-delivery {
    height: 20px;
    transform: translateY(-2px);
  }
}

.product_card .messages-cell .icon-row h3 {
  display: inline-block;
  font-family: $family-sans-serif;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 14px;
  margin-bottom: 0;
}

.product-block {
  .product_attribute {
    line-height: 20px;
  }
}

.product_card {
  position: relative;

  .advantage-badge {
    height: 32px;
    position: absolute;
    right: 8px;
    top: 4px;
    width: 32px;
    @include media('>=520px') {
      right: 12px;
      top: 8px;
    }
  }

  .field-labels {
    @extend %field-margin-top;

    justify-content: space-between;
  }

  .mobile-wrapper {
    position: relative;

    .nte_collapse {
      background-color: $c-gray-e5;
      bottom: -4px;
      position: absolute;
      right: 0;
    }

    .MuiCollapse-root.MuiCollapse-entered {
      padding-bottom: 8px;
    }

    .field-labels {
      column-gap: 24px;
    }
  }

  .desktop-wrapper {
    .field-labels {
      max-width: 240px;
    }

    button {
      max-width: 600px;
    }

    .field-row-2 {
      align-items: center;
      gap: 8px;
      grid-template-columns: 96px 1fr;

      .card_save_list {
        justify-self: end;
      }
    }
  }

  .product-image-skeleton {
    height: 100%;
  }

  .product-price-skeleton {
    height: 36px;
  }

  .compare-container,
  .bookmark-container {
    cursor: pointer;
    display: inline-block;
  }

  .rating-container {
    text-align: center;
  }
}

.product_card,
.nte-pdp-product-card {
  .badges {
    @include safari-fb-fix(li, 8px);

    column-gap: 8px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
}

.nte-pdp-product-card .badges {
  display: inline-flex;
}

.product-image-section {
  grid-area: image;
}

.product-buy-section {
  grid-area: buy;

  .MuiFormControlLabel-label {
    font-size: 14px;
  }

  .MuiFormControlLabel-root.card_save_list .MuiFormControlLabel-label {
    margin-left: 8px;
  }

  .field-labels {
    span.MuiButtonBase-root.MuiIconButton-root {
      margin-right: 4px;
    }

    .MuiFormControlLabel-root.card_save_list .MuiFormControlLabel-label {
      margin-left: 4px;
    }
  }
}

.delivery_options_children {
  .field-margin {
    margin-bottom: 16px;
  }

  .h4 {
    margin-bottom: 8px;
  }
}

.cart_product_card .product-price-cell,
.product_card .messages-cell,
body .solution-product-card {
  position: relative;

  .qty-pricing {
    display: block;
  }

  h2.MuiTypography-h2 {
    margin-bottom: 8px;
  }

  .sub-product-offer-price {
    margin-bottom: 8px;
  }

  .messages-cell {
    align-content: center;
    display: grid;
    row-gap: 4px;
    @include scaled-spacing(margin-bottom, 3);

    svg {
      vertical-align: middle;
    }
  }

  .icon-row {
    svg {
      align-self: start;
      height: 20px;
      width: 20px;
    }
  }

  .offer-wrapper {
    .promo-description {
      margin-left: 4px;
      margin-right: 4px;
    }

    .link-effect {
      transform: none;
    }
  }

  .store_stock {
    a {
      display: inline;
    }
  }

  .skeleton-container {
    .icon-skeleton {
      display: inline-block;
      height: 24px;
      width: 24px;
    }

    .content-skeleton {
      display: inline-block;
      height: 24px;
      margin-left: 8px;
      width: 70%;
    }
  }
}

.cart_product_card {
  .location-icon {
    align-self: start;
  }

  .store-pickup-message {
    color: $c-gray-76;

    .pickup-message--instock {
      color: $c-green;
    }
  }
}

//
// Complete your solution
.complete_your_solution_products {
  @include media('<=414px') {
    display: block;

    li.solution-product-card:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @include media('>=768px', '<846px') {
    display: block;
  }

  grid-template-columns: repeat(2, minmax(180px, 1fr));

  li.solution-product-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    @include media('<1340px') {
      .price-cell {
        margin-bottom: 24px;
      }
    }
    @include media('>=1340px') {
      display: grid;
      gap: 16px;
      grid-template-areas:
        'image description'
        'image price'
        'cta cta';
      grid-template-columns: 112px 1fr;
      height: 100%;

      button {
        align-self: end;
        grid-area: cta;
        max-width: 148px;
      }
    }
  }

  .thumbnail {
    @include media('<900px') {
      max-width: 112px;
      width: 100%;
    }

    margin-left: auto;
    margin-right: auto;
    width: 112px;
  }
}

.frequently_bought_together_wrapper {
  @include media('<768px') {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%;
  }
}

//
// Compare Products
.product_compare_details {
  column-gap: 4px;
  display: grid;
  grid-auto-flow: column;

  .badge-compare-spacer {
    grid-area: badges;
    height: 42px;
  }

  .product-name {
    grid-area: description;
  }

  li.product-card-compare {
    margin-bottom: 0;
    position: relative;
  }

  .remove-link {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .product_card.compare {
    @include scaled-spacing-height-calc(3, 24px);

    grid-template:
      [row1-start] 'badges' 42px [row1-end]
      'image'
      'description'
      'partnumber'
      'price'
      'messages'
      'cta';
  }

  .header-spacer {
    height: 32px;
  }

  .image-cell {
    @extend %field-margin;
  }

  .thumbnail {
    margin: 0 auto;
    text-align: center;
  }

  .description-cell {
    display: contents;

    .part-number {
      @extend %field-margin;

      grid-area: partnumber;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    grid-area: badges;
    margin-top: 4px;

    .badge {
      margin-right: 8px;
    }
  }

  .price-cell {
    align-items: left !important;
    column-gap: 8px;
    text-align: left;
    margin-bottom: 16px;

    p,
    .nte-price24 {
      margin-bottom: 8px;
    }

    .order-today {
      display: none;
    }

    &.block {
      .price {
        margin-bottom: 16px;
      }
    }
  }

  .messages-cell.product_shipping {
    @extend %field-margin;

    margin-top: 0;
  }

  .cta-cell {
    button {
      @extend %field-margin;

      margin-top: 0;
    }
  }

  .align-right.link-effect {
    display: block;
  }

  .rating-container.offline-page {
    margin-top: 0;
  }

  .pricing_and_promotions {
    @include scaled-spacing(margin-bottom, 5, 32px);

    align-items: flex-end;
    column-gap: 24px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      margin-bottom: 0 !important;
    }
  }

  .advantage-badge {
    float: right;
    position: absolute;
    top: 8px;
  }

  .product-buy-section {
    flex-grow: 1;
  }

  .button-section {
    height: 100px;
  }
}

.product_compare_modal {
  .product_compare_details {
    grid-auto-columns: 290px;
    @include media('>768px') {
      grid-auto-columns: 336px;
    }

    li.product-card-compare {
      padding-top: 48px;
    }

    .product_card.compare {
      grid-template-rows: min-content min-content min-content min-content min-content 1fr min-content;

      .badges {
        margin-top: 16px;
      }
    }
  }
}

.nte_product_page_layout {
  .product_compare_details {
    grid-auto-columns: 288px;
    @include media('>768px') {
      grid-auto-columns: 318px;
    }

    .product_card.compare {
      grid-template-rows: auto auto auto auto auto 1fr 132px;
    }

    .badges {
      margin-bottom: 16px;
    }
  }
  @include media('>=1460px') {
    .product_comparison .scrollable-region {
      overflow: hidden;
      padding: 0;
      transform: rotateX(0);

      .scroll-on-top {
        transform: rotateX(0);
      }
    }
  }
}

/*********** For PLP Grid View ***********/
body .grid-product-card {
  padding: 16px !important;
  height: 100%;

  .thumbnail{
    .resize-lazy-image{
      aspect-ratio: 1/1;
    }
  }

  .product_rating{
    min-height: 24px;
  }

  .image-cell.product_card_section_image {
    padding-right: 4px;
  }

  .left-padding {
    padding-left: 4px;
  }

  .section-visibility {
    visibility: hidden;
  }
}

.product-name-ellipsis {
  -webkit-line-clamp: 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.grid-field-labels {
  @include media('<600px') {
    margin-top: 8px;
  }
  @include media('>=600px') {
    margin-top: 24px;
  }
  .MuiTypography-body1 {
    font-size: 13px;
  }
}

.grid-product-card .messages-cell .icon-row h3 {
  display: inline-block;
  font-family: $family-sans-serif;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 14px;
  margin-bottom: 0;
}

/**********************END***************************/

body .rvi-carousel {
  .carousel-slider {
    padding-bottom: 4px;
  }

  .rvi-card-container {
    align-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    .product-offer-price,
    .offer-price {
      text-align: center;
    }
  }

  @include media('<600px') {
    .image-cell {
      width: 112px;
    }
  }

  @include media('>375px', '<600px') {
    .rvi-carousel-slide.product-card {
      height: auto;
      margin-bottom: 4px;
    }
    .rvi-card-container {
      display: grid;
      gap: 16px;
      grid-template-areas:
        'image description'
        'cta cta';
      margin-top: 16px;

      .rvi-top .thumbnail {
        margin-top: 0;
        padding-top: 0;
      }

      .description-cell.rvi-bottom {
        display: contents;
        grid-area: none;
      }

      .product-info-cell {
        grid-area: description;
      }

      .nte-price18 {
        text-align: right;
      }

      .rvi-card-button {
        grid-area: cta;
        margin-bottom: 0;
      }

      .image-cell {
        width: 112px;
      }
    }
  }

  .image-cell {
    @extend %field-margin;

    max-width: 168px;
  }

  .description-cell {
    grid-area: bottom;

    .nte-price18 {
      font-size: 24px;
    }
  }

  .carousel-slide {
    padding: 0 4px;
  }

  .rvi-carousel-slide {
    background-color: #fff;
    display: block;
    height: calc(100% - 4px);
    position: relative;
    @include elevation(1);

    .thumbnail {
      margin-top: 16px;
    }

    .remove {
      height: 24px;
      position: relative;
      text-align: right;

      a,
      button {
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }

    .thumbnail {
      padding-top: 16px;
    }

    .nte-price18 {
      @include scaled-spacing(margin-bottom, 5, 32px);

      display: block;
    }
  }

  .part-number {
    margin-bottom: 24px;
  }

  .rvi-card-button {
    margin-bottom: 24px;
  }
}

.simple_product_card_widget .product-card {
  grid-template-areas: 'image description';
  grid-template-columns: 172px 1fr;

  .image-cell.product_card_section_image {
    grid-column: 1 / 3;
    grid-row: 1;
    @include media('>425px') {
      grid-column: 1 / 2;
    }
  }

  .description-cell.product_cart_section_description {
    grid-column: 1 / 3;
    grid-row: 2;
    @include media('>425px') {
      grid-column: 2 / 3;
      grid-row: 1;
    }
  }
}

.message-collapse {
  grid-template-areas: '. . . cta-cell cta-cell .';
}

.price-cell-review {
  text-align: right;
}
