@use 'sass:math' as math;

// CSS Utilities
.cursor-pointer {
  *:hover {
    cursor: pointer;
  }
}

.link-effect,
.MuiPaper-root .link-effect {
  @include link-effect;
}

%no-link-effect,
.no-link-effect,
html a .no-link-effect,
.no-link-effect:hover {
  color: inherit;
  text-decoration: none !important;
}

.intrinsic-image,
%intrinsic-image {
  @include intrinsic-ratio(1 1);

  background-position: center;
  border-color: transparent;
}

html body {
  .pxs *,
  .pxs p[class^='MuiTypography-'],
  p.pxs,
  .cmc-content .size13,
  .cmc-content .size13 * {
    font-size: 13px;
    line-height: 16px;
  }

  .size10 {
    font-size: 10px;
    line-height: 12px;
  }

  .size12 {
    font-size: 12px;
    line-height: 24px;
  }

  .size14 {
    font-size: 14px;
  }

  .font12,
  .font14 {
    line-height: 16px;
  }

  .size18 {
    font-size: 18px;
    line-height: 24px;
  }

  .size20 {
    font-size: 20px;
    line-height: 24px;
  }

  .size24 {
    font-size: 24px;
    line-height: 28px;
  }

  .size32 {
    font-size: 32px;
    line-height: 36px;
  }

  .size36 {
    font-size: 36px;
    line-height: 40px;
  }

  .sizeh1 {
    @extend %h1;
  }

  .sizeh2 {
    @extend %h2;
  }

  .sizeh3 {
    @extend %h3;
  }

  .sizeh4 {
    @extend %h4;
  }
}

.fine-print {
  color: $c-gray-aa-readable;
  font-size: 12px;
  line-height: 24px;
}

body .reset-button,
body %reset-button {
  background-color: transparent;
  border-radius: 0;
  border-width: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  height: auto;
  line-height: inherit;
  padding: 0;
  text-align: inherit;
}

html body,
.MuiDrawer-root,
.nte_popper {
  div,
  h2,
  span,
  p {
    &[class^='nte-price'],
    &.price {
      align-self: flex-start;
      font-family: $family-headers;
      font-weight: 700;
      //white-space: nowrap;
    }
  }

  .nte-price14 {
    font-size: 14px;
  }

  .nte-price18 {
    font-size: 18px;
  }

  .nte-price24,
  %nte-price24 {
    font-size: 24px;
  }

  .nte-price36 {
    font-size: 36px;
    line-height: 36px;
  }
}

// Allow espots to contain an attribute to set aspect ratio
// https://css-tricks.com/aspect-ratio-boxes/
[style*='--aspect-ratio'] > :first-child {
  width: 100%;
}

[style*='--aspect-ratio'] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*='--aspect-ratio'] {
    position: relative;
  }

  [style*='--aspect-ratio']::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*='--aspect-ratio'] > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}

@for $i from 1 to 24 {
  body .elevate-#{$i} {
    @include elevation($i);
  }
}

body .elevate-0 {
  box-shadow: none !important;
}

@for $i from 2 to 8 {
  html .nte-flowing-cols-#{$i} {
    @include scaled-spacing(column-gap, 2);

    column-count: $i;
    column-width: math.div(720px, $i);
  }
}

.float {
  position: fixed;
}

.icon-inline.MuiGrid-root {
  svg {
    margin-right: 8px;
  }
}

.icon-row,
.MuiPaper-root a.icon-row {
  align-content: center;
  align-items: center;
  column-gap: 4px;
  display: grid;
  grid-template-columns: 32px 1fr;

  svg,
  img {
    justify-self: center;
    max-height: 32px;
    width: 32px;
  }
}

// Specific to icon inside product grid
.grid-product-card .icon-row svg {
  justify-self: center;
  max-height: 20px;
  width: 20px;
}

body a.icon-row svg {
  fill: $c-link;
}

fieldset.not-mui .form-row {
  column-gap: 24px;
  display: flex;

  &:last-child {
    .form-input-field {
      margin-bottom: 0;
    }
  }
}

.button-row {
  display: grid;
  grid: auto / 48px 48px;
  margin: 0 auto;
  place-items: center;
  row-gap: 24px;

  button {
    width: 100%;

    &.inline-text.MuiButtonBase-root.MuiButton-root {
      padding: 12px 16px;
      text-align: center;
    }
  }

  @include media('>=720px') {
    column-gap: 16px;
    grid: auto / 1fr 1fr;
  }
  @include media('<=720px') {
    column-gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

.nte-ul-list,
%nte-ul-list {
  list-style: disc;
  margin-left: 20px;

  li {
    ul {
      list-style: disc;
      margin-left: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.list-style-none {
  list-style: none;
}

ol.nte-numbered-list,
ol.nte-ol-list {
  counter-reset: nte-counter;
  margin-left: 24px;

  li:not(:last-child) {
    margin-bottom: 8px;
  }

  & > li {
    counter-increment: nte-counter;
    position: relative;

    &::before {
      content: counter(nte-counter) '. ';
      left: -20px;
      position: absolute;
    }

    ul.nte-ul-list {
      margin-bottom: 8px;
    }
  }
}

dl.nte-dl-list {
  dt {
    font-weight: 700;
    margin-bottom: 8px;
  }

  dd:not(:last-child) {
    margin-bottom: 8px;
    margin-left: 20px;
  }
}

.nte-link-list {
  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

html {
  .no-gap,
  .cmc-content {
    &.no-gap,
    .no-gap {
      gap: 0;
    }
  }
}

/// Color utility classes
html body {
  .color-green {
    color: $c-green !important;
  }

  .color-red {
    color: $c-red !important;
  }

  .color-bright-red {
    color: $c-bright-red !important;
  }

  .color-brown {
    color: $c-brown !important;
  }

  .color-blue {
    color: $c-blue !important;
  }

  .color-gold {
    color: $c-yellow !important;
  }

  .color-white {
    color: #fff !important;
  }

  .background-color-yellow {
    background-color: $c-yellow;
  }

  .background-color-c8 {
    background-color: $c-gray-c8;
  }

  .color-gray {
    color: $c-gray-aa-readable;
  }

  .color-gray-89 {
    color: $c-gray-89;
  }

  .bold-weight,
  .weight700 {
    font-weight: 700 !important;
  }

  .weight800 {
    font-weight: 800 !important;
  }

  .weight900 {
    font-weight: 900 !important;
  }

  svg.green-circle-check.MuiSvgIcon-root {
    fill: $c-green;
  }

  .overflow-hidden {
    overflow: hidden;
  }
}

// https://tobiasahlin.com/blog/flexbox-break-to-new-row/
.flex-break-row {
  flex-basis: 100%;
  height: 0;
}

.flex-break-col {
  flex-basis: 100%;
  width: 0;
}

.fb {
  display: flex;
  flex-wrap: wrap;

  &.no-wrap {
    flex-wrap: nowrap;
  }
}

.fbv,
.cmc-content .fb-vert {
  display: flex;
  flex-direction: column;

  &.centered {
    align-items: center;
    justify-content: space-around;
  }
}

.aligned-bottom-cart-btn {
  margin-top: auto;
}

.aligned-bottom-card,
%aligned-bottom-card,
.vertical-space-between,
.fb-vert-sb {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
}

.jcsb {
  justify-content: space-between;
}

.list-header {
  align-items: center;
  margin-bottom: 12px;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block !important;
}

.pointer-events-none {
  pointer-events: none;
}

.flex-align-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.complete-solution {
  background-color: $c-gray-f2 !important;
}

.radio-indent {
  padding-left: 32px !important;
}

.iom-advantage-upsell {
  margin-top: 16px;
}

body {
  .body2,
  a.body2 {
    font-size: 14px;
  }
}

%nte-scrollbar {
  @include scrollbars(8px, $c-gray-c8, #fff);
  @include media('>=960px') {
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
  }
  @include media('<768px') {
    margin-top: 5px;
  }
}

%nte-scrollbar-3px {
  @include scrollbars(3px, $c-gray-c8, #fff);
  @include media('>=960px') {
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
    @include media('<768px') {
      margin-top: 5px;
    }
  }
}

%nte-underline-animation {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(transparent, $c-blue);
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 3px, 0 3px;
  text-decoration: none;
  transition: background-size 300ms ease-in-out;

  &:hover {
    background-size: 0 3px, 100% 3px;
  }
}

.resize-lazy-image {
  @include media('<600px') {
    width: 66%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @include media('>=600px') {
  width: 100%;
  }
}

.scrollable-region,
%scrollable-region {
  @extend %nte-scrollbar;

  overflow-y: auto;
  padding: 0 4px 8px;
}

.ffamily-proxima-nova {
  font-family: $family-headers;
}

.ffamily-proxima-condensed {
  font-family: $family-condensed;
}

// Padding
.content-padding-32 {
  @include scaled-spacing(padding, 5, 32px);
}

.content-padding-40 {
  @include scaled-spacing(padding, 5, 40px);
}

.content-padding-48 {
  @include scaled-spacing(padding, 5, 48px);
}

.content-padding-56 {
  @include scaled-spacing(padding, 5, 56px);
}

.scroll-mobile {
  @extend %nte-scrollbar;

  @include media('<=820px') {
    background-color: #fff;
    min-width: 100%;
    //overflow-x: scroll;
    //position: relative;

    & > table {
      min-width: 724px;
    }

    .sticky {
      position: sticky;
      top: 0;
    }
  }
}

.checkbox-wrapper .MuiTypography-root,
a.MuiTypography-colorPrimary,
.facet_checkbox {
  color: #000000;
  text-decoration: none;
}

.overlay {
  opacity: 0.5;
  pointer-events: none;
}

.strike-price {
  position: relative;
  display: inline-block;
}

.strike-price::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #000000;
  transform: rotate(-15deg);
}

.save-price-badge {
  background-color: #008500 !important;
  color: #fff !important;
  padding: 4px !important;
  margin: 0px 0px 0px 4px;
}

.image-cell a:focus {
  .thumbnail {
    box-shadow: 0 0 0 1px #c8c8c8, 0 0 1px 2px #505050 !important;
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: transparent;
  }
}

.hide-element {
  display: none;
}
