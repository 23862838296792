.search_bar-wrapper {
  align-items: center;
  background-color: #fff;
  border: 2px solid $c-gray-e5;
  border-radius: $radius;
  display: flex;
  div.MuiInputBase-root {
    border: none;
  }
  .search-icons-container {
    margin-right: 8px;
  }
  .searchIcon_search {
    align-items: center;
    display: flex;
  }
}

// Primary Search Bar
body .nte_primary_search_bar {
  height: 48px;
  position: relative;
  width: 100%;

  .search-input-wrapper {
    height: 48px;
    margin-bottom: 0;
    max-width: 100%;

    .Mui-focused input {
      @include media('<=768px') {
        font-size: 14px;
      }
    }

    input {
      max-width: 100%;
      text-overflow: ellipsis;
      width: 100%;
      @include media('<=768px') {
        font-size: 16px;
      }
    }

    label {
      color: $c-gray-aa-readable;
      font-size: 16px;
      font-weight: 700;
      width: auto;

      &.MuiInputLabel-shrink {
        display: none;
      }
    }

    fieldset {
      display: none;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0;

      .MuiAutocomplete-input:first-child {
        padding: 0 12px 0 16px;
      }
    }

    &.Mui-focused,
    &.state-active {
      border-color: $c-light-blue;
    }
  }

  // Condensed Search
  //
  &.condensed-search {
    .MuiAutocomplete-root {
      position: absolute;
      right: 0;
      top: 0;
      transition: width $global-transition ease-in-out;
      width: 100%;
      z-index: 200;

      &.Mui-focused,
      &.state-active {
        width: calc(100% + 128px);

        .animation-stage {
          grid-template-columns: 40px 40px 1px 40px 40px;
        }
      }
    }
    .MuiAutocomplete-popper {
      left: 2px;
      top: 48px;
    }
    .Mui-focused,
    .state-active {
      & + .MuiAutocomplete-popper {
        left: -128px;
        width: calc(100% + 128px);
      }
    }

    .search-icon-gray {
      display: none;
    }
  }
}

.store_finder {
  .menu_list_header.header-region {
    border-bottom: none;
  }
  .nte_primary_search_bar {
    margin-top: 16px;
    position: relative;

    .search-input-wrapper {
      max-width: 100%;
    }

    .searchIcon_search {
      cursor: pointer;
      height: 24px;
      vertical-align: middle;
    }

    .search-icons-container {
      position: absolute;
      right: 12px;
      top: 24px;
    }
  }

  .MuiAlert-root {
    margin-bottom: 0;
    margin-top: 24px;
  }

  .search-bar {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      max-width: 100%;
    }
  }
}

.backdrop-active.safari {
  position: fixed;
}

.search-bar__suggestion-anchor {
  bottom: 12px;
  left: 24px;
  position: absolute;
}

#product-search-popup {
  @extend %nte-scrollbar;

  max-height: calc(100vh - 164px);
  padding: 0 8px 0 6px;
  & > li:not(:last-child) {
    margin-bottom: 16px;
  }

  .highlight-text {
    @include line-clamp(2);
  }
  .pxs {
    margin: 8px 0 0 28px;
  }

  .MuiAutocomplete-groupLabel {
    @include scaled-spacing(margin-bottom, 4, 24px);

    color: $c-text;
    font-family: $family-headers;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 0;
    top: 0;
  }

  ul.MuiAutocomplete-groupUl {
    li {
      align-items: flex-start;
      padding: 16px 8px;
      &:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
      }
      svg {
        margin-right: 4px;
      }
    }
    .fb {
      flex-wrap: nowrap;
    }
  }
}

.product_suggestion_list {
  .truncated span {
    @include line-clamp(2);

    margin-bottom: 4px;
  }
}

.suggestion_list {
  li {
    align-items: start;
  }

  p span {
    display: block;
    @include line-clamp(2);
  }
}

.search-input-wrapper {
  .MuiAutocomplete-inputRoot {
    align-content: center;
    background-color: #fff;
    border: 2px solid $c-gray-50;
    border-radius: 3px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 48px;
    overflow: hidden;
  }

  .search-icons-container {
    height: 48px;
    width: 132px;
  }

  .close-icon {
    display: none;
    opacity: 0;

    svg {
      fill: $c-gray-50;
      height: 32px;
      width: 32px;
    }

    &:hover {
      svg {
        fill: #000;
      }
    }
  }

  .animation-stage {
    align-items: center;
    display: flex;
    height: 44px;
    justify-content: space-around;
    margin-right: 4px;

    @include media('>=480px') {
      right: 4px;
    }

    .MuiDivider-vertical {
      height: 60%;
    }

    a {
      display: inline-block;
      height: 24px;
    }

    button.plain-icon-button {
      padding: 0 8px;
    }

    button.plain-icon-button:not(.barcode),
    hr {
      margin-right: 8px;
    }

    button.plain-icon-button:not(.close-icon) {
      display: flex;
      justify-content: center;
    }
  }

  .combo-icon-root {
    column-gap: 24px;
    display: grid;
    grid-template-columns: repeat(2, 28px);
    place-items: center;
  }

  .barcode-style {
    height: 24px;
    width: 32px;

    &:hover {
      filter: invert(0%) sepia(95%) saturate(21%) hue-rotate(2deg)
        brightness(92%) contrast(108%);
    }
  }

  button.toggle-icons {
    background-color: #fff;
    display: none;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: 6px;
    width: 40px;
    z-index: 1;

    svg {
      transform: scaleX(-1);
    }
  }
}

// Animations
.toggle-icons,
.animation-stage {
  transition: all $global-transition ease-in-out;
}

.icons-hidden {
  .animation-stage {
    transform: translate(114px, 0);
  }

  button.toggle-icons svg {
    transform: scaleX(1);
  }

  .search-icons-container {
    width: 96px;
  }
}

.state-active-clear {
  .search-icons-container {
    width: 140px;
  }

  .close-icon {
    display: block;
    opacity: 1;
  }

  .search-icon-gray,
  .close-icon {
    &:hover {
      fill: #000;
    }
  }

  hr {
    display: none;
  }
}

svg.close-icon,
svg.close-icon:hover {
  fill: $c-text;
}

@include media('<=486px') {
  .search-input-wrapper {
    .search-input-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      transition: all $global-transition ease-in-out;
    }

    &.mobile-search-active {
      .search-input-wrapper {
        transform: translateX(0);
      }
    }
  }
}
