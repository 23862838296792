// Spacing.scss file
//
// ## All of the site's layout spacing patterns are contained in this file
// This enables designers to have more consistent control of all margins and padding
// throughout the site.
//
//
// Styleguide Spacing

body {
  .page:not(.cmc-content, .nte_product_page_layout) {
    .page-row:first-child:not(.page-title, .breadcrumbs-title, .page-title-margin-top, .tab-bar-wrapper) {
      @include modular-spacing(margin-top, 20px, 2);
    }
  }

  .page-row,
  %page-row,
  .narrow720.page-row,
  .narrow960.page-row,
  .background-image,
  .header_wrapper,
  .footer_grid_outer,
  .alert-row {
    @include modular-spacing(padding-left padding-right, 12px, 2);

    &.bleeder {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .page {
    @include scaled-spacing(margin-bottom, 5, 48px, 8);
  }

  .page-row:not(:last-child),
  %page-row:not(:last-child),
  .content_recommendation_espot:not(:last-child),
  .page-row.page-title,
  .section-row,
  %section-row {
    @include scaled-spacing(margin-bottom, 1, 24px, 8);
  }

  .page-row,
  %page-row,
  .section-row,
  %section-row {
    &.nte-card,
    & > .nte-card {
      @include scaled-spacing(padding, 6, 36px);
    }

    ul .nte-card {
      @include scaled-spacing(padding, 6, 36px);
    }

    &.tab-bar-wrapper {
      margin-bottom: 16px;
    }
  }

  .page-row {
    .section-row,
    %section-row {
      @include scaled-spacing(margin-bottom, 5, 40px);
    }
    @include media('<=667px') {
      .section-row,
      %section-row {
        margin-bottom: 36px;
      }
    }
  }

  @include media('<=740px') {
    .narrow720,
    .narrow960 {
      @include modular-spacing(padding-left padding-right, 12px, 2);
    }
  }

  .nav-right-content .right,
  .nav-left-content .left,
  .nte_modal {
    .section-row {
      @include scaled-spacing(margin-bottom, 4, 28px);
    }
  }

  #main-content .page-row:first-child.breadcrumb-row {
    margin-top: 0;
  }

  .page-row .page-row {
    // Prevent nested .page-rows from getting bottom margin or side padding
    margin-bottom: 0;
    margin-top: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }

  .background-image {
    @include modular-spacing(margin-left margin-right, 12px, 2);
  }

  .page-row-margin-top,
  .page-title-margin-top {
    @include modular-spacing(margin-top, 20px, 2);
  }

  .cmc-block {
    &.section {
      margin-bottom: 40px;
      @include media('>=1020px') {
        @include scaled-spacing(margin-bottom, 5, 32px);
      }
    }
  }

  .offline-page {
    @include modular-spacing(margin-top, 24px, 2);

    h1,
    button {
      @include modular-spacing(margin-bottom margin-top, 24px, 2);
    }
  }
}

//
/// Content Blocks
//
body {
  %content-block-margin, // Utility class
  .title-row,
  .nav-left-content .left .nte-card:not(:last-child),
  .nav-right-content .right .nte-card,
  .nte_accordion.stacked:not(:last-child) {
    // MUI override
    @include scaled-spacing(margin-bottom, 5, 32px);

    margin-top: 0; // MUI override
  }

  .nte-card-section:not(:last-child),
  .count-list {
    @include scaled-spacing(margin-bottom, 7, 40px);
  }
}

.nte_popper-paper,
.nte_drawer-paper,
.nte_accordion,
.MuiAutocomplete-paper,
body .left.nte-card {
  @include scaled-spacing(padding, 3, 24px);

  &.sandwich {
    @include scaled-spacing(padding-top padding-bottom, 3, 20px);
  }
}

// Alerts
.MuiAlert-root {
  @include scaled-spacing(margin-bottom, 4, 32px);

  max-width: calc(100vw - 24px);
  @include media('>=540px') {
    max-width: calc(100vw - 32px);
  }
  @include media('>=720px') {
    max-width: calc(100vw - 40px);
  }
  @include media('>=900px') {
    max-width: 740px;
  }

  &.inline {
    @include scaled-spacing(margin-bottom, 4, 24px);
  }
}

.nte-card,
.nte-border-card,
.nte-borderless-card,
.nte_accordion {
  .MuiAlert-root {
    @include scaled-spacing(margin-bottom, 4, 24px);
    @include scaled-spacing(padding-left padding-right, 2, 16px);
    @include scaled-spacing(padding-top padding-bottom, 2, 12px);
  }
}

body .MuiAlert-root {
  @include scaled-spacing(padding-left padding-right, 3, 24px);
  @include scaled-spacing(padding-top padding-bottom, 2, 16px);

  &.inline {
    @include scaled-spacing(padding-left padding-right, 2, 16px);
    @include scaled-spacing(padding-top padding-bottom, 2, 12px);
  }
}

.MuiPaper-root.MuiCard-root,
.nte-card:not(.no-padding),
.nte-card-padding,
%nte-card:not(.no-padding),
.nte_accordion.page-row,
.nte-borderless-card,
.nte_modal {
  @include scaled-spacing(padding, 5, 36px);

  &.sandwich {
    @include scaled-spacing(padding-top padding-bottom, 5, 24px);
  }

  &.padding48 {
    @include modular-spacing(padding, 12px, 3);
  }

  &.small-card {
    @include scaled-spacing(padding, 4, 28px);
  }
}

.cg-cols-auto,
.cg-cols-3,
.cg-cols-4 {
  .nte-card,
  .nte-card.no-padding .content-cell {
    @include scaled-spacing(padding, 6, 32px);
  }
}

.cmc-content .nte-card:not(.no-padding) .image-cell {
  @include scaled-spacing(margin-bottom, 6, 32px);
}

.cmc-content {
  ul.nte-ul-list,
  ol.nte-ol-list,
  ol.nte-numbered-list,
  dl.nte-dl-list {
    @include scaled-spacing(margin-bottom, 5, 40px);
  }
}

.nte-border-card,
.cmc-content .card .content-cell,
.cmc-content .link-wrapped-card.no-padding .content-cell,
.cmc-content .nte-card.no-padding .content-cell {
  @include scaled-spacing(padding, 4, 24px);
}

.nte_modal .nte_modal-paper,
.nte-card .card-content {
  @include scaled-spacing(padding, 5, 40px);

  &.sandwich {
    @include scaled-spacing(padding-top padding-bottom, 4, 24px);
  }
}

.section,
.title-subheader {
  .nte-card-transparent {
    padding: 0;
  }
}

//
/// Content Blocks Spacing
body .bottom-margin,
form .required {
  @include scaled-spacing(margin-bottom, 4, 24px);
}

// Grid spacing
.nte-card {
  &.title-bar {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }
}

.category_cards {
  .thumbnail {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }
}

body {
  .nav-left-content,
  .nav-right-content,
  *[class^='multicol'] {
    @include scaled-spacing(column-gap, 5, 32px);
  }
}

.page-row:not[class^='cg-cols-'] {
  .nte-card:not(:last-child) {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }
}

// CMC Bootstrap Grid Theming
html .cmc-content {
  .grid,
  &.grid {
    @include scaled-spacing(gap, 4, 32px);
  }

  .grid.no-padding {
    &.nte-card,
    &.nte-borderless-card,
    &.nte-border-card {
      gap: 0;
    }
  }

  > hr {
    @include scaled-spacing(margin-top margin-bottom, 4, 40px);
  }
}

.nte_product_page_layout .slot_4 .cmc-content .grid {
  @include scaled-spacing(gap, 4, 32px);
}

// Top Level Menu spacing
header.MuiAppBar-root {
  .MuiToolbar-regular {
    padding-bottom: 7px;
    padding-top: 12px;

    &.secondary .cart_label {
      padding-left: 16px;
    }

    &.primary.nav_bar {
      padding: 16px 0;
    }
  }
}

// Popper/drop down menu spacing
.nte_popper {
  .menu_list_body,
  .menu-list-body {
    padding: 0 8px 0 6px;

    li {
      padding: 16px 8px;
    }
  }

  .deals {
    padding: 0 8px 0 0;

    li a {
      display: block;
      padding: 16px 8px;
    }
  }

  .store-address {
    padding: 16px 8px;
  }
}

.nte_drawer-paper {
  .mini-cart-header-wrapper {
    & > div,
    & > button,
    & > hr {
      @include scaled-spacing(margin-bottom, 5, 32px);
    }

    .MuiGrid-justify-content-xs-flex-end {
      @include scaled-spacing(margin-bottom);
    }
  }
}

// Form elements
fieldset.not-mui,
.MuiFormGroup-root {
  .form-input-field:not(:last-child),
  .MuiFormControl-root:not(:last-child),
  .MuiAutocomplete-root {
    @include scaled-spacing(margin-bottom, 3, 28px);
  }
}

.MuiFormGroup-root label:not(:last-child),
.checkbox-wrapper.MuiGrid-root {
  @include scaled-spacing(margin-bottom, 3, 28px);
}

.nte_popper-paper,
.nte_modal-paper,
.nte_drawer-paper {
  fieldset.not-mui .form-input-field,
  fieldset.not-mui .MuiFormControl-root,
  :not(.button-row) > .MuiButtonBase-root.MuiButton-root {
    @include scaled-spacing(margin-bottom, 3, 24px);
  }

  .MuiButtonBase-root.MuiButton-root:last-child {
    margin-bottom: 0;
    width: 100%;
  }
}

.password-change-wrapper {
  @include scaled-spacing(column-gap, 2);
}

//ContinueToCart Styles
.paragraph_bottom-padding {
  padding-bottom: 32px;
}

// Content block inner title and text spacing
.has-background-image h1.page-title-header,
section > h2,
section > h2.MuiTypography-root,
.MuiCardHeader-root h2,
.MuiTabs-root {
  @include scaled-spacing(margin-bottom, 5, 32px);
}

.MuiPaper-root.MuiCard-root,
.nte-card:not(.title-bar, .nte_accordion),
.promo-card,
.nte-card-transparent,
.nte_drawer .MuiDrawer-paper,
.count-list {
  h1,
  h2:not(.has-subtitle),
  .sizeh1,
  .sizeh2 {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }

  h1.has-subtitle,
  h3,
  h4,
  .size24,
  .size20,
  .sizeh3,
  h2.sizeh3,
  .sizeh4 {
    @include scaled-spacing(margin-bottom, 4, 28px);
  }

  .nte-border-card,
  .nte-borderless-card {
    h2,
    h3,
    h4,
    h5 {
      @include scaled-spacing(margin-bottom, 3, 20px);
    }
  }
}

.cmc-content,
#product-feature-benefits {
  h2 {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }

  h3,
  h4 {
    @include scaled-spacing(margin-bottom, 3, 16px);
  }
}

h2.nte-card-title {
  @include scaled-spacing(margin-bottom, 5, 32px); // Utility class
}

h3.nte-card-title {
  @include scaled-spacing(margin-bottom, 4, 24px);
}

.nte_accordion.Mui-expanded {
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }
}

.nte_popper-paper .header-region,
.nte_modal-paper .header-region,
.nte_modal-paper {
  h2,
  h3 {
    @include scaled-spacing(margin-bottom, 4, 24px);
  }
}

.nte_popper-paper,
.nte_modal-paper {
  h4 {
    @include scaled-spacing(margin-bottom, 4, 24px);
  }
}

.title-block,
.MuiPaper-root.MuiCard-root .title-block {
  @include scaled-spacing(padding-bottom, 2);

  h2,
  h3 {
    margin-bottom: 8px;
  }
}

.card-content,
.plp-no-result-container {
  p {
    @include scaled-spacing(margin-bottom, 5, 32px);

    a {
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

figure {
  & > figcaption:not(:last-child) {
    margin-bottom: 8px;
  }

  img + figcaption {
    margin-top: 8px;
  }
}
