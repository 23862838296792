.checkout-header {
  .MuiPaper-root {
    background-color: transparent;

    * {
      color: #fff;
      font-weight: 700;
    }

    .MuiStepLabel-label {
      p {
        border-bottom: 2px solid transparent;
        transition: all $global-transition;

        &:hover {
          border-color: $c-secondary;
        }
      }

      a:hover {
        text-decoration-color: transparent;
      }
    }

    .Mui-disabled .MuiStepLabel-label {
      p:hover {
        border-color: transparent;
      }
    }

    svg.MuiSvgIcon-root:not(.MuiStepIcon-active, .MuiStepIcon-completed) {
      fill: $c-gray-50;
    }

    .MuiStepIcon-active text {
      fill: #000;
    }

    .MuiStepLabel-active {
      p {
        border-bottom: 2px solid $c-secondary;
      }

      a {
        cursor: initial;
      }
    }

    .MuiStepIcon-active {
      fill: $c-secondary;
    }

    .MuiStepConnector-horizontal {
      width: 32px;
    }

    .MuiStepIcon-root.MuiStepIcon-completed {
      fill: $c-secondary;
    }
  }
}

.cart-section {
  .cart_save_for_later_remove.false {
    display: flex;
    width: 100%;

    @include media('>=600px', '<768px') {
      justify-content: flex-end;
    }

    @include media('>=1080px') {
      justify-content: flex-end;
    }
  }
}

body .header-chat-link {
  cursor: pointer;

  svg {
    fill: $c-secondary;
  }

  &:hover svg {
    fill: #fff;
  }
}

.terms-privacy-policy p {
  @include media('>=768px') {
    text-align: center;
    margin: auto;
  }
}

.checkout-page {
  .nte-border-card {
    .MuiTypography-root.MuiFormControlLabel-label {
      background-image: none;
      transform: translateY(-2px);

      h3 {
        display: inline;
      }

      .MuiTypography-body2 {
        margin-top: 16px;
      }

      .icon-text {
        column-gap: 0;
        margin-top: 2px;

        p.MuiTypography-body2 {
          margin-left: 4px;
          margin-top: 0;
        }
      }

      &:hover {
        h3 {
          background-size: 0 3px, 100% 3px;
        }
      }
    }
  }
}

.promo_styles {
  margin-top: 32px;
}

.order_conf_promo_styles {
  margin-top: 7px;
}

.promo_styles p,
.order_conf_promo_styles P {
  line-height: 1.2;
}

.promo_styles > div:not(:first-child),
.order_conf_promo_styles > div:not(:first-child) {
  margin-top: 2px;
}

.credit-debit-form {
  .cg-cols-3 {
    gap: 24px;
    grid-template-areas:
      'exp-mos exp-yr'
      'security security';
    grid-template-columns: 130px 130px;

    @include media('>768px') {
      grid-template-areas: 'exp-mos exp-yr security';
      grid-template-columns: 96px 96px 120px;
    }
  }
}

.checkout_payment_page {
  .MuiFormGroup-root {
    flex-direction: column;
  }

  .margin_right_8 {
    margin-right: 8px;
  }

  .margin_right_8 {
    margin-right: 8px;
  }

  .margin_bottom_0 {
    margin-bottom: 0px !important;
  }

  .margin_bottom_24 {
    margin-bottom: 24px;
  }

  .add_debit_credit_card {
    @extend %field-margin;
  }

  .credit-debit-block {
    grid-template-areas:
      'card-input'
      'expiration-cell';

    &.has-save-button {
      grid-template-areas:
        'card-input'
        'expiration-cell'
        'save-card';
    }
  }

  .expiration-cell {
    grid-template-areas:
      'exp-mos exp-yr'
      'security security';
    grid-template-columns: 130px 130px;

    @include media('>1180px') {
      grid-template-areas: 'exp-mos exp-yr security';
      grid-template-columns: 96px 96px 120px;
    }
  }

  .security-code {
    grid-area: security;
    width: 130px;

    @include media('>768px') {
      width: 120px;
    }
  }

  .net-30 {
    .form-input-field:last-child,
    .MuiFormControlLabel-root,
    .checkbox-wrapper.MuiGrid-root {
      margin-bottom: 0;
    }

    .cg-cols-2 {
      grid-template-columns: 124px 1fr;
    }
  }

  .payment-section {
    .nte-card {
      @extend %content-block-margin;
    }

    .button-row {
      margin-left: 0;
      width: 43%;
    }
  }

  .gift_card_payment {
    max-width: 300px;
    .prompt-margin {
      max-width: 300px;
    }

    .field-row-2 {
      grid-template-columns: 1fr 140px;
      max-width: 300px;
    }

    .gift_card_payment-form {
      background-color: #f0f0f0;
    }

    .fb {
      column-gap: 16px;
    }

    .tool-tip {
      display: flex;
    }

    .gift_card_payment-form-pin {
      @include media('<768px') {
        width: 80%;
      }
    }

    button {
      @include media('<768px') {
        max-width: 300px;
        width: 100%;
      }
    }
  }

  .applied_gift_card {
    .cg-cols-auto {
      grid-auto-columns: max-content;
      grid-auto-flow: column;

      @include media('<600px') {
        grid-auto-flow: row;
      }
    }

    .title-block {
      padding-bottom: 16px;
    }

    .applied-card-details {
      max-width: 300px;

      @include media('<520px') {
        .label {
          display: block;
        }

        .content {
          margin-left: 16px;
        }
      }

      @include media('>=1024px') {
        .content {
          margin-left: 10px;
          font-size: 16px;
        }
      }
    }
  }

  .payment_billing_address {
    .nte-border-card {
      @include media('<768px') {
        max-width: 300px;
        width: 100%;
      }
    }
  }

  .checkout-payment-page-indent {
    @include media('>=1024px') {
      padding-left: 32px;
    }
  }

  .delivery_page {
    .address_dropdown {
      max-width: 100%;

      @include media('>=768px') {
        max-width: 360px;
      }

      @include media('>=960px') {
        max-width: 500px;
      }

      .MuiSelect-selectMenu {
        max-width: 100%;
      }
    }

    .add_pick_up_person_form.account-form .button-row {
      width: 100%;
    }
  }

  .delivery_page .shipping_info {
    .button-row {
      margin-left: 0;
      width: 100%;

      @include media('>=768px') {
        width: 43%;
      }
    }

    .shipping_method_card {
      height: 100%;
    }

    .shipping_method_label_iom {
      margin-bottom: 0px;
    }

    .shipping-address {
      min-width: 220px;
    }

    .shipping_address_section a {
      display: inline-block;
    }

    .advantage_membership_promo {
      width: 100%;

      .cg-cols-2 {
        @include media('<600px') {
          display: block;

          .MuiButton-text {
            margin-bottom: 24px;
          }

          .MuiButton-outlined {
            display: block;
            margin: 0 auto;
          }
        }

        grid-template-columns: 1fr 200px;
      }
    }
  }

  #advantage-details .delivery_advantage_details {
    .advantage-icon {
      text-align: center;

      img {
        max-width: 276px;
        width: 70%;
      }
    }
  }

  .order_review_page {
    .left {
      @include media('<=414px') {
        .cg-cols-3 {
          display: block;

          .nte-border-card {
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }

      @include media('<=1080px') {
        .cg-cols-3 {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      div.nte-card {
        @extend %content-block-margin;
      }

      .order-summary.nte-card {
        @include elevation(3);

        margin-bottom: 4px;
      }
    }

    .order_summary {
      .paypal-button {
        height: 44px;
        padding: 0;
      }
    }
  }

  .order_confirmation_page {
    .left {
      div.nte-card {
        @extend %content-block-margin;
      }
    }

    .cg-cols-3 {
      grid-template-columns: repeat(2, 1fr);

      @include media('>=1024px') {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .print_order {
    display: 'flex';
    justify-content: 'space-between';
  }

  .credit_card_payment.checkout {
    .saved-card {
      margin-bottom: 24px;
    }
  }

  .checkout--save-card {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
  }
  .checkout--save-card div label {
    margin-bottom: 0px !important;
  }

  .save-card--cancelbtn {
    margin-right: 24px;
  }

  @include media('<600px') {
    .credit-debit-form .cg-cols-3 {
      margin-bottom: 32px;
    }
  }
}

// checkout paymemnt section
.payment-card-credit-debit {
  background-color: #f0f0f0;
  padding: 24px 16px 24px 16px;
  width: fit-content;
}

.card-number-input {
  .form-input-field.card-number {
    .MuiFormControl-root.MuiTextField-root.form-input-field {
      max-width: 100%;
      width: 100%;
    }
  }
}

.payment_billing--billingcard {
  width: fit-content;
}

.save-card-use-new-card {
  display: flex;
}

.save-credit-card-checkbox {
  padding-right: 32px;
}

.save-credit-card-checkbox div label {
  margin-bottom: 0px !important;
}

// checkout shipping billing lift-gate
.lift-gate--form {
  .lift-gate--label {
    @include media('<600px') {
      align-items: flex-start;
    }

    align-items: baseline;
  }
}
@include media('<=600px') {
  .lift-gate {
    margin-top: 15px;
  }
}

.shipping_info {
  .shipping_info--card-row {
    grid-template-columns: 1fr;
    width: 100%;

    @include media('>=768px', '<1024px') {
      grid-template-columns: repeat(2, minmax(180px, 280px));
      width: auto;
    }

    @include media('>=1024px') {
      grid-template-columns: repeat(3, minmax(190px, 300px));
      width: auto;
    }
  }

  .shipping-card {
    // &:nth-child(3){
    //   grid-row-start: 2;
    // }
    h3 {
      margin-bottom: 8px;
      transform: translateY(-4px);
    }
  }

  .shipping-card--skeleton {
    width: 100%;
  }
}

.order_summary--deliverybtn {
  a {
    color: $c-link;
    padding: 0;
    margin: 0;
    font-weight: normal;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.show-giftcard-container {
  button {
    justify-content: start;
  }
}

.show-PO-container {
  button {
    justify-content: start;
  }
}
.payment-card-Net30 {
  background-color: #f0f0f0;
  padding: 24px 16px;

  @include media('>=768px') {
    margin-top: 24px;
    margin-left: 8px;
  }
  @include media('<768px') {
    margin-top: 16px;
  }

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 6px;
}

.saved-payment-card-Net30 {
  background-color: #f0f0f0;
  padding: 24px 16px;

  width: fit-content;
  border-radius: 6px;
  @include media('>=768px') {
    margin-top: 32px;
    margin-left: 32px;
    width: 360px;
  }
  @include media('<768px') {
    margin-top: 16px;
    width: 95%;
  }
}

.trevi-warning {
  margin: 0px 0px 8px 0px !important;
}
.payment-card-Trevipay {
  background-color: #f0f0f0;
  padding: 24px 16px;
  margin-bottom: 24px;

  .trevipay-logo {
    width: 300px;
  }

  .trevipay-signin {
    margin-bottom: 8px;
    @include scaled-spacing(margin-top, 3, 20px);
    display: flex;
    align-items: center;

    button {
      max-width: 200px;
    }

    p {
      margin-left: 12px;
      font-style: italic;
    }
  }

  .trevipay-checkbox {
    label {
      margin-bottom: 0px;
    }
  }
}

.delivery_page .add_pick_up_person_form {
  background-color: #f0f0f0;
  @include media('<=480px') {
    width: 100%;
  }
  @include media('>=480px') {
    width: fit-content;
  }

  h2 {
    font-size: 20px;
    margin-bottom: 0px !important;
  }
}

.delivery_page {
  .edit_create_address {
    display: flex;
    column-gap: 24px;
    gap: 16px;
  }
  .pickup-person-div {
    .cg-cols-2 {
      @include media('<=768px') {
        grid-template-columns: repeat(1, 1fr);
        display: block;
      }
    }
    @include media('>=768px') {
      width: 460px;
    }
  }
  .advantage_membership_promo {
    border: 2px solid #f5b100;
    padding: 16px;
    display: grid;
    gap: 16px;
    place-items: center;

    @include media('>=1080px') {
      display: flex;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 2px;
    }

    p {
      font-size: 14px;
      margin-bottom: 8px !important;
    }

    .advantage_icon {
      width: 180px;
      height: 60px;
    }

    .button_container {
      display: flex;
      gap: 10px;
    }
  }
}

a.free-store-pickup-link {
  font-size: 15px;
  font-weight: normal;
  padding: 5px 0px 0px 10px;
  display: none;
}

.checkout_espot {
  width: 100%;
}

.trevipay_saved_content {
  max-width: 380px;
}

.trevipay_saved {
  margin-top: 20px;
}

.trevipay_powered {
  font-style: italic;
  font-weight: bold;
  margin-bottom: 16px;
}
