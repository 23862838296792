.nte_product_page_layout {
  // Header
  .bread_crumbs {
    @include media('<=1024px') {
      height: 56px;
      position: relative;

      .separator {
        display: none;
      }

      li {
        position: absolute;
        transform: translateX(-100vw);

        &:first-child {
          display: none;
        }

        &:nth-last-child(2) {
          position: static;
          transform: translateX(0);

          a {
            &::before {
              content: '<';
              display: inline-block;
              margin-right: 8px;
            }
          }
        }

        &:last-child {
          display: inline-block;
          position: static;
          transform: translateX(0);

          .separator {
            display: inline-block;
          }
        }
      }
    }
  }

  // Product Card
  .nte-pdp-product-card {
    @extend %cg-cols-2;
    @include scaled-spacing(gap, 7, 48px);

    grid-template-areas:
      'slot1 slot1'
      'slot2 slot2'
      'slot3 slot3';

    @include media('>1024px') {
      grid-template-areas: 'slot1 slot1' 'slot2 slot3';
    }

    .slot_1 {
      @include media('<667px') {
        display: flex;
        flex-direction: column;

        .product_badges {
          margin-bottom: 8px;
          order: 1;
        }

        .card_page_title {
          order: 2;
        }

        .rating_summary {
          order: 3;
        }
      }

      grid-area: slot1;
    }

    .slot_2 {
      grid-area: slot2;
    }

    .slot_3 {
      grid-area: slot3;
    }

    .skeleton-container {
      .MuiSkeleton-circle {
        border-radius: 0;
      }
    }
  }

  .rating_summary {
    align-items: center;
    column-gap: 16px;
    display: inline-flex;
    @include media('<1080px') {
      .product-id {
        display: none;
      }
    }
  }

  .ratings {
    align-items: center;
    column-gap: 16px;
    display: inline-flex;
    font-size: 14px;
  }

  .bv_main_container button {
    box-shadow: none;
  }

  .product_badges {
    vertical-align: middle;
  }

  .product_image {
    @extend %cg-cols-2;

    max-width: 640px;
    width: 100%;

    .thumbnails {
      @extend %nte-scrollbar;
      @include safari-fb-fix(li, 8px);

      display: flex;
      gap: 8px;
      grid-area: thumbs;
      max-width: 100%;

      li {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex: 1 0 50px;
        justify-content: center;
        max-height: 90px;
        max-width: 90px;
        overflow: visible;
        position: relative;

        &.thumbnail--active button {
          border: 2px solid $c-yellow;
        }
      }

      img {
        height: auto;
        width: 100%;
      }

      .more-count {
        align-items: center;
        background-color: rgb(0 0 0 / 60%);
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        top: 0;
        width: 100%;

        p {
          color: #fff;
          font-family: $family-h1;
          font-weight: 700;
          line-height: 20px;
          text-align: center;
          @include media('<=768px') {
            font-size: 13px;
          }
        }
      }
    }

    .zoom-image {
      grid-area: zoom;
    }
    @include media('<600px') {
      grid-template-areas:
        'zoom zoom'
        'thumbs thumbs';

      .zoom-image {
        margin-bottom: 8px;
      }
    }
    @include media('>600px') {
      grid-template-areas: 'thumbs zoom';
      grid-template-columns: 104px 1fr;

      .thumbnails {
        @include safari-fb-fix(li, 8px, vert);

        flex-direction: column;
      }

      .zoom-image {
        margin-bottom: 0;
      }
    }
  }

  .noClick {
    pointer-events: none;
  }

  #PDP_OL_IMAGES {
    margin-top: 24px;
    text-align: center;
  }
  @include media('>600px', '<=960px') {
    .slot_2 p.align-center {
      margin-left: 104px;
    }
  }

  .slot_3 .price-cell {
    .price {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    .sub-product-offer-price.pxs {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
      &.order-today {
        font-weight: 700;
      }
    }
    &.block .sub-product-offer-price {
      @extend %field-margin;
    }
  }

  .product-offer-list {
    @extend %field-margin;

    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .add_to_cart_btn {
    @extend %field-margin;

    @include media('>=600px') {
      @include scaled-spacing(column-gap, 4, 48px);

      align-items: center;
      display: flex;
    }

    .qty-cell {
      width: 104px;
    }

    .save-to-list {
      @include media('<600px') {
        margin-right: 10%;
      }

      margin-top: 24px;
      text-align: right;
    }
  }
  .save-to-list {
    @extend %field-margin;
  }

  .giftcard_amount_text {
    margin-bottom: 24px;
  }

  .quantity-container {
    @include scaled-spacing(column-gap, 4, 40px, 8);
    @include media('<668px') {
      justify-content: flex-end;

      button.MuiButtonBase-root.MuiButton-root {
        margin-left: 16px;
        max-width: 280px;
        padding: 16px 24px;
      }
    }

    align-items: flex-end;
    display: flex;

    label {
      display: inline-block;
      font-size: 16px;
      left: 0;
      margin-bottom: 8px;
    }
  }

  .slot_3 .product_shipping {
    @extend %field-margin;

    .product-shipping-list li {
      align-items: start;
      column-gap: 12px;

      &.shipping {
        button {
          margin-top: 8px;
        }
      }
    }
    .store-pickup {
      display: block;
    }

    .store-stock-label {
      > button {
        margin-top: 8px;
      }

      .pickup-message--default {
        color: $c-gray-76;
      }

      .pickup-message--instock {
        color: $c-green;
      }

      .pickup-message--d365 {
        margin-top: 16px;
      }
    }

    .label-wrapper > span {
      display: block;
      font-size: 14px;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    .shipping {
      svg {
        height: 48px;
        max-height: 48px;
        transform: translateY(-16px);
      }
      
      .pdp-shipping-info-icon {
        height: 20px;
        max-height: 20px;
        transform: translateY(3px);
      }
    }

    .store_stock .store_pick_up_icon {
      transform: translateY(-6px);
    }
  }

  .short_description {
    & > ul {
      @extend %nte-ul-list;

      margin-bottom: 16px;
    }

    & + .content_recommendation_espot {
      @extend %field-margin-top;
    }
  }

  .slot_3 .messages-cell * {
    font-size: inherit;
  }

  .cart_pricing_table {
    margin: 16px 0;

    &.nte_table {
      td {
        padding: 12px;
        border: 1px solid $c-gray-c8;
      }
    }
  }

  // Page Sections
  //
  .slot_4 {
    @include media('<=500px') {
      .grid {
        div[class^='g-col-'] {
          grid-column: auto / span 12;
        }
      }
    }

    #_PDP_WARNINGS,
    #_PDP_REPLACEMENT_PARTS,
    .page-row:not(.frequently_bought_together_wrapper, .tab-bar-wrapper, .product_comparison, .rvi) {
      margin-bottom: 16px;
      .accordion {
        margin-bottom: 0;
      }
    }

    & > .content_recommendation_espot {
      @extend %page-row;
    }
  }

  // Product Summary
  #hotbar-description {
    h4 {
      margin-bottom: 16px;
    }

    & + section.feature_benefits {
      display: none; // Hack for duplicate features and benefits
    }
  }

  .prod-desc-outer {
    ul {
      @extend %nte-ul-list;
    }
  }

  .pdfs {
    @extend %field-margin-top;
  }

  #hotbar-reviews {
    @include media('<=420px') {
      .customer_image_slide {
        text-align: center;
      }
    }
  }

  .frequently_bought_together {
    .solution-product-list {
      align-items: center;
      display: grid;
      gap: 16px 8px;
      grid-template-columns: 1fr 24px 1fr 24px;
      justify-content: start;
      margin-bottom: 16px;

      .thumb a {
        display: block;
        padding: 8px;
        transition: all $global-transition ease-in-out;

        &:hover {
          @include elevation(4);
        }
      }

      .add-icon {
        svg {
          height: auto;
          width: 100%;
        }
      }

      @include media('>768px') {
        @include scaled-spacing(column-gap, 4, 32px);

        display: flex;

        .thumb {
          flex: 0 1 178px;
        }

        .add-icon {
          flex: 0 1 32px;
        }

        .cta-block {
          margin-left: 16px;
        }
      }
    }
    @include media('>=1080px') {
      .solution-product-list {
        display: inline-flex;
        max-width: calc(100% - 192px);
      }

      .cta-block {
        display: inline-block;
        float: right;
        margin-top: 16px;
      }
    }

    .add-checkboxes {
      li {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .nte-price18 {
          margin-left: 8px;
        }
      }
    }

    span.accessibility {
      position: absolute;
      left: -9999px;
      width: 100px;
      height: auto;
      overflow: hidden;
    }
  }

  // Bazaar Voice
  button.bv-text-link {
    box-shadow: none;
    transition: none;
  }

  // Complete your solution
  .frequently_bought_together {
    .checkbox-wrapper.MuiFormControlLabel-root {
      max-width: 100%;
    }
  }

  .tab-bar-wrapper.page-row {
    @include media('<960px') {
      display: none;
    }

    margin-bottom: 8px;

    .nte-card {
      @include elevation(3);
    }
  }

  .MuiAccordionDetails-root {
    h4,
    h5,
    h6 {
      margin-bottom: 8px;
    }
  }
}

#product-feature-benefits {
  .see-feature-benefit-image {
    @extend %nte-scrollbar;

    overflow-x: auto;
    text-align: center;
  }

  .feature-auxdescription ul {
    @extend %nte-ul-list;

    li {
      break-inside: avoid-column;
    }
  }
}

#media-modal-screen {
  .feature-auxdescription ul {
    @extend %nte-ul-list;
  }
}

.product_key_specs {
  @include media('<420px') {
    .cg-cols-2 {
      row-gap: 40px;
    }
  }

  table {
    width: 100%;

    td {
      overflow-wrap: anywhere;
      width: 50%;
    }
  }
}

.video-play-desc {
  aspect-ratio: 300 / 150;
}

.video-play-placeholder {
  display: flex;
  justify-content: center;
  border: 1px solid black;
  margin: auto;
}
.video-play-button {
  margin: auto;
}
.video-play-button::after {
  width: 40% !important;
}

#media-modal-screen {
  .nte_modal-paper {
    max-width: $max-width;
    width: calc(100vw - 40px);
  }

  .media-modal-tabpanel {
    padding: 0;
  }

  .media-modal {
    @include media('>=980px') {
      @include scaled-spacing(column-gap, 5, 24px);

      display: grid;
      grid-template-columns: 224px 1px 1fr;
      justify-content: center;
      width: 100%;
    }
    @include media('>=1200px') {
      grid-template-columns: 338px 1px 1fr;
    }
  }

  .thumbnails {
    @extend %nte-scrollbar;
    display: flex;
    margin-bottom: 24px;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px;

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      @include media('>768px') {
        overflow: visible;
      }

      &:not(:last-child) {
        margin-right: 24px;
      }
      &:hover {
        @include elevation(3);
      }
    }

    @include media('>=980px') {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(2, 90px);
      grid-template-rows: repeat(auto-fill, 90px);
      margin-bottom: 0;
      max-height: 600px;
      overflow-x: hidden;
      overflow-y: auto;

      li:not(:last-child) {
        margin-right: 0;
      }
    }

    @include media('>=1200px') {
      grid-template-columns: repeat(3, 90px);
    }

    li img {
      cursor: pointer;
      transition: all $global-transition ease-in-out;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }

    .video-link {
      @include media('<768px') {
        width: 180px;
        margin-top: 30px;
      }
    }
    .threesixty-link {
      @include media('<768px') {
        width: 180px;
        height: auto;
        margin-top: 30px;
      }
    }
  }

  .media_modal_customer_review {
    @include media('>=1024px') {
      grid-template-columns: 224px 1px 1fr;
    }

    @include media('>=1200px') {
      grid-template-columns: 338px 1px 1fr;
    }

    .thumbnails.nte-borderless-card {
      grid-template-columns: repeat(2, 90px);
      grid-template-rows: repeat(auto-fill, 90px);

      @include media('>=1200px') {
        grid-template-columns: repeat(3, 90px);
      }

      li img {
        @include media('>=768') {
          width: 120px;
        }
      }
    }
  }

  .media-img-cont {
    flex: 1 0 auto;
    text-align: center;

    img {
      height: auto;
      max-width: 580px;
      width: 100%;
    }
  }

  .selected-review img {
    display: block;
    height: auto;
    width: auto;
    max-width: 400px;
    max-height: 400px;
    margin: auto;
  }

  .thumbnail--active {
    border: 2px solid $c-yellow;
  }

  .media_modal_customer_review--image-caption {
    margin: 0 auto;
    max-width: 640px;
    overflow: hidden;
  }

  .reviewdetails-header {
    margin-bottom: 16px;
  }

  .reviewdetails-username {
    margin-bottom: 4px;
  }

  svg.MuiSvgIcon-root {
    fill: $c-yellow;
  }
}

#media-video-tab {
  width: 100%;
}

.customer-images-carousel {
  .slick-track {
    margin: 0 -8px;
  }
  .slick-slide {
    margin: 0 8px;
    max-width: 120px;
  }
}

body .customer_image_slide {
  button {
    @include elevation(1);
    @include aspect-ratio(1, 1);

    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $radius;
    display: block;
    margin-bottom: 4px;
    width: 100%;
  }
}

.nte_product_page_layout .rvi {
  @extend %section-row-top;
}

#PDP_OL_KEYSPECS {
  @extend %field-margin-top;
}
