@mixin display-grid {
  display: grid;
  grid-template-rows: auto;
  @include scaled-spacing(gap, 5, 32px);
}

%grid-helpers-base {
  @include display-grid;

  grid-template-columns: 1fr;
}

.cols-auto-fit {
  @extend %grid-helpers-base;
  @include media('>medium') {
    grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
  }
}
// .cg-cols-[1 - 12] classes
//
// Utility classes used to create a column grid block element. There can be between 1 to 12 columns.
// 'cg' stands for css grid.
//
// Markup:
// <div class='cg-cols-3'><div>Column 1 - 3 column layout = .cg-cols-3</div><div>Column 2</div><div>Column 3</div></div><hr /><div class='cg-cols-4'><div>Column 1 - 4 column layout = .cg-cols-4</div><div>Column 2</div><div>Column 3</div><div>Column 4</div></div><hr /><div class='cg-cols-5'><div>Column 1 - 5 column layout...etc.</div><div>Column 2</div><div>Column 3</div><div>Column 4</div><div>Column 5</div></div><hr />
//
// Styleguide Layout.CssGrid.cg-cols-#

@for $i from 2 to 12 {
  .cg-cols-#{$i},
  %cg-cols-#{$i},
  .cols-#{$i} {
    @include scaled-spacing(gap, 7, 32px);

    display: grid;
    grid-template-rows: auto;
    @include media('>=600px') {
      grid-template-columns: repeat($i, 1fr);
    }
    *[class^='cg-cols'] {
      @include scaled-spacing(gap, 2, 16px);
    }
  }
}

.cg-cols-auto {
  @include display-grid;

  grid-column: minmax(200px, 1fr);
}
