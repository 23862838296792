footer {
  background-color: $c-gray-e5;
  @include scaled-spacing(margin-top, 5, 48px);
  .customer-care {
    grid-area: customercare;
  }
  .resources {
    grid-area: resources;
  }
  .about-us {
    grid-area: aboutus;
  }
  .deal-divider {
    grid-area: dealdivider;
  }
  .email_details {
    grid-area: deals;
  }
  @include media('<=medium') {
    .deal-divider {
      justify-self: stretch;
      margin: 24px 0;
      width: 100%;
    }
  }
  .footer_grid_outer {
    margin: 0 auto;
    max-width: $xl_1440;
    position: relative;
    width: 100%;
    .footer_link {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        box-sizing: border-box;
        color: $c-gray-50;
        font-size: 14px;
      }
    }
  }
  .feedback_cta {
    border-radius: $radius;
    margin: 0 auto;
    max-width: 360px;
    justify-content: center;
    display: flex;
    .feedback_container {
      align-items: center;
      background-color: #f2f2f2;
      border-radius: $radius;
      display: flex;
      margin-top: 32px;
      padding: 8px 16px;
      position: relative;
    }
    h4 {
      font-size: 16px;
    }
    svg {
      margin-right: 8px;
    }
    div {
      display: flex;
    }
    @include media('<=400px') {
      max-width: 284px;
      h4 {
        font-size: 12px;
      }
    }
  }

  .footer_links_block {
    @include media('<small') {
      .tablet-only {
        display: none;
      }
    }
    .tablet-only h3 {
      @include scaled-spacing(margin-bottom, 5, 32px);
    }
    .nte_accordion {
      background-color: transparent;
      border: 1px solid $c-gray-c8;
    }
  }

  .email_details {
    max-width: 420px;
    min-width: 280px;
    width: 100%;
    p {
      padding-bottom: 16px;
    }
  }

  .footer_grid_inner {
    display: grid;
    grid-template-areas:
      'deals'
      'dealdivider'
      'customercare'
      'resources'
      'aboutus';
    grid-template-columns: auto;
    justify-items: center;
    padding-bottom: 24px;
    padding-top: 32px;
    @include media('<600px') {
      .footer_links_block {
        width: 100%;
        margin-bottom: 16px;
        &.about-us {
          margin-bottom: 0;
        }
      }
    }
    @include media('>=600px') {
      @include modular-spacing(column-gap row-gap, 16px, 2);

      grid-template-areas:
        'deals deals deals'
        'dealdivider dealdivider dealdivider'
        'customercare resources aboutus';
      grid-template-columns: repeat(3, auto);
      grid-template-rows: auto;
    }
    @include media('<=1080px') {
      .tablet-only .footer_link {
        margin-bottom: 40px;
      }
    }
    @include media('>=1080px') {
      grid-template-areas: 'customercare resources aboutus deals';
      grid-template-columns: repeat(4, auto);
      justify-content: space-between;
    }
  }

  .email-signup-form {
    display: grid;
    grid-template-columns: 1fr 116px;
    grid-template-rows: 48px;
    div.MuiInputBase-root {
      border-color: $c-gray-89;
      border-radius: $radius 0 0 $radius;
    }
    input.MuiInputBase-input.MuiOutlinedInput-input {
      background-color: #fff;
      border-radius: $radius 0 0 $radius;
      padding-right: 16px;
    }
  }
  div.MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $c-gray-50;
  }

  .sign-up-button.MuiButton-outlined {
    border-radius: 0 $radius $radius 0;
    border-width: 2px 2px 2px 1px;
    box-shadow: none;
    margin-left: -1px;
  }

  .bottom_logo {
    background-color: #000;
    padding: 16px;
    svg {
      height: 36px;
      margin: 0 auto;
      max-width: 98vw;
      @include media();
    }
  }

  .footer-contact-no {
    @include link-effect;

    font-weight: 700;
    margin-left: 8px;
    transform: translateY(-1px);
    @include media('<=510px') {
      display: block;
    }
  }

  .footer-social-section {
    padding: 32px 0;
    @include media('<=medium') {
      align-items: center;
      flex-direction: column;
      .connect-with-us {
        padding-bottom: 48px;
      }
    }
    @include media('>medium') {
      align-items: flex-end;
      justify-content: space-between;
    }
    .connect-with-us {
      max-width: 320px;
      width: 60%;
      h3 {
        padding-bottom: 16px;
      }
    }
    .social-icons {
      justify-content: space-between;
    }
    a {
      border-width: 0;
      display: block;
    }
  }

  .checkout_footer {
    ul {
      break-inside: avoid;
      columns: 140px 2;
      @include modular-spacing(grid-gap, 20px, 2);
      li {
        margin-bottom: 0;
        &:nth-child(4) {
          break-after: avoid-column;
          display: inline;
          a {
            display: block;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .confidence-logo {
    @media (max-width: $vs_360) {
      .MuiBox-root {
        text-align: center;
      }
    }
  }
  .social-media-icon {
    height: 32px;
    width: 32px;

    a:focus svg {
      @include nte-focus;
    }

    img {
      height: 100%;
      width: 100%;
    }

    @media (max-width: $vs_360) {
      height: 24px;
      width: 24px;
    }
  }

  .bottom-text {
    padding: 8px 16px 16px;
    @include media('<=medium') {
      display: block;
      text-align: center;
    }
    p {
      font-size: 14px;
      &.copyright {
        font-size: 13px;
      }
    }
  }
}

#footerBannerMessage a {
  color: $c-text;
  font-family: $family-headers;
}
