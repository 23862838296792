body {
  .alert-row {
    @extend .full-width;
  }

  .MuiAlert-root {
    @include elevation(4);

    align-items: center;
    border: 1px solid transparent;
    color: #000;
    margin-top: 24px;

    svg {
      margin-right: 8px;
    }

    &.MuiAlert-standardWarning {
      background-color: $c-beige;
      border-color: $c-info;

      svg {
        fill: $c-info;
      }
    }

    &.MuiAlert-standardError {
      background-color: lighten($c-cta, 60%);
      border-color: $c-warning;

      svg {
        fill: $c-warning;
      }
    }

    &.inline {
      @include elevation(1);
      display: table;
      .MuiAlert-icon,
      .MuiAlert-message {
        vertical-align: middle;
        display: table-cell;
      }
    }

    &.outline-error {
      box-shadow: none;
      background-color: transparent;
    }

    &.outline-warning {
      box-shadow: none;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    .MuiAlert-action {
      align-self: flex-start;

      svg {
        margin-right: 0;
      }
    }
  }

  .nte-card,
  .nte-border-card,
  .nte-borderless-card,
  .nte_popper-paper,
  .nte_accordion {
    .MuiAlert-root {
      display: table;
      .MuiAlert-icon,
      .MuiAlert-message {
        vertical-align: middle;
        display: table-cell;
      }
    }
  }

  .MuiAlert-icon,
  .MuiAlert-message {
    padding: 0;
  }

  .MuiAlert-message p {
    max-width: 100%;
  }

  .sold_in_store .MuiAlert-root,
  &.no-top-margin {
    margin-top: 0;
  }
}

.page-title + .MuiAlert-root,
.alert-row .MuiAlert-root,
.page-title-margin-top + .MuiAlert-root {
  margin-top: 0;
}
