.nte-home-page-carousel {
  margin: 0 auto;
  max-width: 1360px;
  width: 100%;

  a {
    display: block;
    min-height: 100%;
    text-decoration: none;
    text-underline-offset: unset;
    transition: none;
  }

  .carousel-navigation-icon {
    font-size: 36px;
  }

  button {
  &.slick-next,
  &.slick-prev {
    @include media('<=820px') {
      display: none;
    }
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    height: 56px;
    width: 56px;
    svg {
      @include svg-elevation(4);

      fill: #fff;
      height: 48px;
      width: 48px;
    }

    &:not([disabled]):hover {
      background: rgba(255, 255, 255, .4);
      svg {
        @include svg-elevation(6);
      }
    }
  }}

  .slick-prev {
    left: -4px;
  }

  .slick-next {
    right: -4px;
  }
  @include media('>=1472px') {
    .slick-prev {
      left: -24px;
    }
    .slick-next {
      right: -24px;
    }
  }
}

// Sections
.home-section {
  &.carousel.bleeder {
    border-top: 1px solid #fff;
  }
  .MuiCardMedia-root {
    background-repeat: no-repeat;
    height: 75px;
    margin: 0 auto;
    width: 100px;
  }

  .MuiCardContent-root {
    padding: 16px 0 0;
    text-align: center;

    h4 {
      margin-bottom: 8px;
    }

    p {
      color: $c-red;
      font-weight: 700;
    }
  }

  &.all-promo-content {
    .MuiPaper-root {
      padding: 0;
    }

    .promo_card_content {
      padding-bottom: 16px;
      padding-top: 16px;
    }

    .intrinsic-image {
      @include intrinsic-ratio(16 9);
    }
    
    .cg-cols-3 {     
      @include media('>=600px') {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }  
  }
}

#HP_09 .promo-card {
  grid-template-columns: 40% 1fr;
  .intrinsic-image {
    max-height: 100%;
  }
}

.home-section.promo-cards-a-content {
  .cg-cols-3 {
    grid-template-columns: 1fr;
    @include media('>=640px', '<768px') {
      .nte-card {
        display: grid;
        grid-template-columns: 60% 1fr;
      }
    }
    @include media('>=768px') {
      grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    }
  }
}

  // Ipad only fix for Homepage CMC content block - remove pseudo element - distorts image.
.home-section.promo-cards-b-content {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      .cmc-content {
        .image-cell::before {
          display: none;
        }
      }
  }
}

.category_cards.home-section {
  .resize-lazy-image{
    aspect-ratio: 1/1;
  }
}

.Homepage_Carousel_Skeleton{
  min-height: 240px;

  @include media('<=766px'){
    aspect-ratio: 1.84;
  }
  
  @include media('>766px', '<=1360px'){
    aspect-ratio: 1360/490;
  }

  @include media('>1360px'){
    height: 490px;
  }
  
}