.search-another-way-container {
  line-height: 24px;
  list-style: disc;
  li {
    margin-left: 32px;
  }
}

// Temp  fix in CMC
.no-result-espot-2 {
  p.MuiTypography-body1 {
    font-size: 14px;
    margin: 16px 0;
  }
  a {
    display: inline-block;
    margin-left: 8px;
  }
}

.no-result-feedback {
  h3 {
    align-items: center;
    display: flex;
    text-align: left;
    svg {
      height: 32px;
      margin-right: 8px;
      width: 32px;
    }
  }
  button {
    margin-left: 36px;
  }
}
