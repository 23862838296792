.input-button-pair {
  display: grid;
  grid-template-columns: 1fr 88px;
  justify-items: start;
  .MuiFormControl-root {
    max-width: 100%;
  }
  .MuiInputBase-root {
    border-radius: $radius 0 0 $radius;
  }
  button.MuiButton-root.MuiButtonBase-root,
  button {
    border-radius: 0 $radius $radius 0;
  }
}

body .MuiFormHelperText-root {
  line-height: 20px;
  margin-top: 4px;
}

form {
  .required {
    font-size: 14px;
  }
}

form.address-form {
  @extend .section-row;
  width: fit-content;
  background-color: #f0f0f0;
}

.zip-city-state {
  @include media('>=768px') {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: nowrap;
  }
}

.zip-code-address-form {
  @include media('<768px') {
    margin-bottom: 20px;
  }
  @include media('>=768px') {
    float: left;
    margin-right: 16px;
  }
}

.city-address-form {
  @include media('<768px') {
    margin-bottom: 20px;
  }
  @include media('>=768px') {
    float: left;
    margin-right: 16px;
  }
}

.state-address-form-catalog {
  @include media('<768px') {
    margin-bottom: 20px;
  }
  @include media('>=768px') {
    float: right;
  }
}

.state-address-form-address-form {
  @include media('<768px') {
    margin-bottom: 20px;
  }
  @include media('>=768px') {
    display: inline-block;
    float: right;
  }
}
