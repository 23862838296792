@use 'sass:math' as math;
@use 'sass:map' as map;
@use 'sass:list' as list;

// =========================================================================
//
//  PRECISE CONTROL OVER SCALING LAYOUT
//  ---------------------------------------------------
//  Fred Tranfield fred.tranfield@ey.com

// A curated list of most common breakpoints for today's devices, spaced evenly
// from each other (except for the first)
$spacing-breakpoints: 320px, 360px, 540px, 720px, 900px, 1080px, 1281px;

@function if-important($important){
  @return #{if($important, '!important', '')};
}

@mixin scaled-spacing($properties, $breaks: 4, $max: 32px, $grid: 4, $important: false) {
  $value: $max - (($breaks - 1) * $grid);
  $start: (7 - $breaks) + 1;
  @for $i from $start through 7 {
    $bp: nth($spacing-breakpoints, $i);
    @if $i == $start {
      @each $property in $properties {
        #{$property}: $value if-important($important);
      }
    } @else {
      @media all and (min-width: $bp) {
        @each $property in $properties {
          #{$property}: $value if-important($important);
        }
      }
    }
    $value: $value + $grid;
  }
}

@mixin scaled-spacing-height-calc ($breaks: 4, $max: 32px, $grid: 4) {
  $value: $max - (($breaks - 1) * $grid);
  $start: (7 - $breaks) + 1;
  @for $i from $start through 7 {
    $bp: nth($spacing-breakpoints, $i);
    @if $i == $start {
        height: calc(100% - (2 * #{$value}));
    } @else {
      @media all and (min-width: $bp) {
        height: calc(100% - (2 * #{$value}));
      }
    }
    $value: $value + $grid;
  }
}

// These work better than the standard modular ratios
$layout-ratios: (
  '1': 1,
  '2': 1.14,
  '3': 1.2,
);

@function roundto($value, $nearest) {
  @return math.ceil(math.div($value, $nearest)) * $nearest;
}

@mixin modular-spacing($properties, $min: 8px, $layout-ratio: 3) {
  $ratio: map.get($layout-ratios, #{$layout-ratio});
  $steps: $min;
  @each $bp in $spacing-breakpoints {
    @if $bp == 320px {
      @each $property in $properties {
        #{$property}: $min;
      }
    } @else {
      @media all and (min-width: $bp) {
        @each $property in $properties {
          #{$property}: $steps;
        }
      }
    }
    $this_step: $steps;
    // Round off the increment to be divisible by 4
    $steps: roundto($steps * $ratio, 4);
    // If the rounding didn't go up 4px then we force it.
    @if $steps == $this_step {
      $steps: $steps + 4;
    }
    //@debug 'steps: #{$steps}';
  }
}

//
// Modular font sizing on an 4dp vertical grid
//
@mixin fluid-font-size(
  $min-size: 16px,
  $max-size: 24px,
  $min-bp: 360px,
  $max-bp: 1440px
) {
  $line-height: $min-size;

  $slope: math.div($max-size - $min-size, $max-bp - $min-bp);
  $base: $max-size - ($slope * $max-bp);
  // By default headers are set to line height of 1
  font-size: $min-size;
  line-height: $min-size;

  // Fluid sizing based on properties passed
  @each $bp in $spacing-breakpoints {
    @if $bp == $min-bp {
      @media all and (min-width: $bp) {
        font-size: calc(#{$base} + 100vw * #{$slope});
      }
    }

    //
    // Increment line height value and ensure it's on the grid
    //
    $this-line-height: $line-height;
    // Increment the line-height by 4px if the font-size is greater than the
    // current line height
    $index: list.index($spacing-breakpoints, $bp);
    @if $index <= 6 {
      $max-bp: list.nth($spacing-breakpoints, $index + 1) - 1;
      $max-font-size: $base + ($max-bp * $slope);

      // Increment the line-height when the font size can get larger than line height
      @if $max-font-size > $line-height {
        $line-height: roundto($max-font-size + 4, 4);
        @media all and (min-width: $bp) {
          line-height: $line-height;
        }
      }
    }
  }

  @media all and (min-width: $max-bp) {
    font-size: $max-size;
    line-height: $max-size + 4;
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin intrinsic-ratio($ratio: 1 1, $selector: '&::before') {
  $selector: unquote($selector);

  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  #{$selector} {
    content: '';
    display: block;
    padding-bottom: percentage(math.div(nth($ratio, 2), nth($ratio, 1)));
  }
}

// https://gist.github.com/knolaust/2e5cc0b14c7e7c68ae9b1e4d7074cdaa
@mixin aspect-ratio($width, $height) {
  aspect-ratio: math.div($width, $height);
  // Fallback (current, using padding hack)
  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      content: '';
      float: left;
      padding-top: percentage(math.div($height, $width));;
    }

    &::after {
      clear: both;
      content: '';
      display: block;
    }
  }
}

// https://codepen.io/dibdab/pen/VzWYNv
// Values for shadows are based on valyes from https://material.io/guidelines/resources/shadows.html#shadows-sketch however this doesn't include all values and some of them look strange in css so changes were made when nessecary, e.g. the original values for elevation 12 on the material guidelines site make a for a real strange dark shadow.

$key-shadow-umbra-colour: rgba(#000, 0.14);
$key-shadow-penumbra-colour: rgba(#000, 0.12);
$ambient-shadow-colour: rgba(#000, 0.2);

$key-shadow-umbra-map: (
  1: 0 0 2px 0 $key-shadow-umbra-colour,
  2: 0 0 4px 0 $key-shadow-umbra-colour,
  3: 0 1px 6px 0 $key-shadow-umbra-colour,
  4: 0 2px 4px 0 $key-shadow-umbra-colour,
  5: 0 4px 6px 0 $key-shadow-umbra-colour,
  6: 0 6px 10px 0 $key-shadow-umbra-colour,
  7: 0 6px 10px 0 $key-shadow-umbra-colour,
  8: 0 8px 10px 1px $key-shadow-umbra-colour,
  9: 0 9px 12px 2px $key-shadow-umbra-colour,
  10: 0 10px 13px 2px $key-shadow-umbra-colour,
  11: 0 11px 14px 2px $key-shadow-umbra-colour,
  12: 0 12px 16px 2px $key-shadow-umbra-colour,
  13: 0 13px 18px 2px $key-shadow-umbra-colour,
  14: 0 14px 21px 2px $key-shadow-umbra-colour,
  15: 0 15px 22px 2px $key-shadow-umbra-colour,
  16: 0 16px 23px 2px $key-shadow-umbra-colour,
  17: 0 17px 24px 2px $key-shadow-umbra-colour,
  18: 0 18px 25px 3px $key-shadow-umbra-colour,
  19: 0 19px 26px 3px $key-shadow-umbra-colour,
  20: 0 20px 28px 3px $key-shadow-umbra-colour,
  21: 0 21px 30px 3px $key-shadow-umbra-colour,
  22: 0 22px 32px 3px $key-shadow-umbra-colour,
  23: 0 23px 36px 3px $key-shadow-umbra-colour,
  24: 0 24px 38px 3px $key-shadow-umbra-colour,
);

$key-shadow-penumbra-map: (
  1: 0 2px 2px 0 $key-shadow-penumbra-colour,
  2: 0 3px 4px 0 $key-shadow-penumbra-colour,
  3: 0 3px 4px 0 $key-shadow-penumbra-colour,
  4: 0 4px 5px 0 $key-shadow-penumbra-colour,
  5: 0 4px 5px 0 $key-shadow-penumbra-colour,
  6: 0 1px 18px 0 $key-shadow-penumbra-colour,
  7: 0 2px 18px 1px $key-shadow-penumbra-colour,
  8: 0 3px 14px 2px $key-shadow-penumbra-colour,
  9: 0 3px 18px 3px $key-shadow-penumbra-colour,
  10: 0 4px 16px 3px $key-shadow-penumbra-colour,
  11: 0 4px 18px 3px $key-shadow-penumbra-colour,
  12: 0 5px 22px 4px $key-shadow-penumbra-colour,
  13: 0 5px 26px 4px $key-shadow-penumbra-colour,
  14: 0 5px 29px 4px $key-shadow-penumbra-colour,
  15: 0 6px 34px 5px $key-shadow-penumbra-colour,
  16: 0 6px 30px 5px $key-shadow-penumbra-colour,
  17: 0 6px 36px 5px $key-shadow-penumbra-colour,
  18: 0 7px 33px 6px $key-shadow-penumbra-colour,
  19: 0 7px 37px 6px $key-shadow-penumbra-colour,
  20: 0 8px 34px 6px $key-shadow-penumbra-colour,
  21: 0 8px 39px 7px $key-shadow-penumbra-colour,
  22: 0 9px 38px 7px $key-shadow-penumbra-colour,
  23: 0 9px 40px 7px $key-shadow-penumbra-colour,
  24: 0 9px 46px 8px $key-shadow-penumbra-colour,
);

$ambient-shadow-map: (
  1: 0 1px 3px 0 $ambient-shadow-colour,
  2: 0 1px 5px 0 $ambient-shadow-colour,
  3: 0 1px 7px 0 $ambient-shadow-colour,
  4: 0 1px 8px 0 $ambient-shadow-colour,
  5: 0 1px 10px 0 $ambient-shadow-colour,
  6: 0 3px 5px 0 $ambient-shadow-colour,
  7: 0 3px 8px 0 $ambient-shadow-colour,
  8: 0 4px 15px 0 $ambient-shadow-colour,
  9: 0 5px 11px 0 $ambient-shadow-colour,
  10: 0 5px 14px 0 $ambient-shadow-colour,
  11: 0 6px 10px 0 $ambient-shadow-colour,
  12: 0 6px 13px 0 $ambient-shadow-colour,
  13: 0 7px 10px 0 $ambient-shadow-colour,
  14: 0 7px 12px 0 $ambient-shadow-colour,
  15: 0 7px 14px 0 $ambient-shadow-colour,
  16: 0 8px 13px 0 $ambient-shadow-colour,
  17: 0 8px 15px 0 $ambient-shadow-colour,
  18: 0 9px 11px 0 $ambient-shadow-colour,
  19: 0 9px 13px 0 $ambient-shadow-colour,
  20: 0 9px 15px 0 $ambient-shadow-colour,
  21: 0 10px 12px 0 $ambient-shadow-colour,
  22: 0 10px 15px 0 $ambient-shadow-colour,
  23: 0 11px 13px 0 $ambient-shadow-colour,
  24: 0 11px 15px 0 $ambient-shadow-colour,
);

$svg-key-shadow-umbra-map: (
  1: 0 0 2px $key-shadow-umbra-colour,
  2: 0 0 4px $key-shadow-umbra-colour,
  3: 0 1px 6px $key-shadow-umbra-colour,
  4: 0 2px 4px $key-shadow-umbra-colour,
  5: 0 4px 6px $key-shadow-umbra-colour,
  6: 0 6px 10px $key-shadow-umbra-colour,
  7: 0 6px 10px $key-shadow-umbra-colour,
  8: 0 8px 10px $key-shadow-umbra-colour,
);

$svg-key-shadow-penumbra-map: (
  0: 0 0 0 transparent,
  1: 0 2px 2px $key-shadow-penumbra-colour,
  2: 0 3px 4px $key-shadow-penumbra-colour,
  3: 0 3px 4px $key-shadow-penumbra-colour,
  4: 0 4px 5px $key-shadow-penumbra-colour,
  5: 0 4px 5px $key-shadow-penumbra-colour,
  6: 0 1px 18px $key-shadow-penumbra-colour,
  7: 0 2px 18px $key-shadow-penumbra-colour,
  8: 0 3px 14px $key-shadow-penumbra-colour,
);

$svg-ambient-shadow-map: (
  0: 0 0 0 transparent,
  1: 0 1px 3px $ambient-shadow-colour,
  2: 0 1px 5px $ambient-shadow-colour,
  3: 0 1px 7px $ambient-shadow-colour,
  4: 0 1px 8px $ambient-shadow-colour,
  5: 0 1px 10px $ambient-shadow-colour,
  6: 0 3px 5px $ambient-shadow-colour,
  7: 0 3px 8px $ambient-shadow-colour,
  8: 0 4px 15px $ambient-shadow-colour,
);

@mixin elevation($elevation) {
  box-shadow: map-get($key-shadow-umbra-map, $elevation),
    map-get($key-shadow-penumbra-map, $elevation),
    map-get($ambient-shadow-map, $elevation);
}

@mixin svg-elevation($elevation) {
  filter:
    drop-shadow(map-get($svg-key-shadow-penumbra-map, $elevation))
    drop-shadow(map-get($svg-ambient-shadow-map, $elevation));
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@property --offset {
  inherits: false;
  initial-value: 0;
  syntax: '<length>';
}

@mixin link-effect {
  color: $c-link;
  line-height: inherit;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: var(--offset, 0);
  transition: --offset $global-transition,
    text-decoration-color $global-transition;
  &:hover {
    @include link-animation;
  }
  &:focus {
    text-decoration: underline;
    outline: none;
  }

  &::selection {
    background: $c-link;
    color: #fff;
    text-shadow: none;
  }
}

@mixin link-animation {
  --offset: 2px;

  cursor: pointer;
  text-decoration-color: $c-link;
  transition: all 600ms ease-out;
  @supports not (background: paint(something)) {
    a, .link-effect {
      transition: text-underline-offset $global-transition,
      text-decoration-color $global-transition;
      &:hover,
      &:focus {
        text-underline-offset: 2px;
      }
    }
  }
}

@mixin menu-margin($margin) {
  flex-wrap: nowrap;
  li {
    margin: 0 $margin;
  }
}

@mixin line-clamp($lines: 3) {
  /* stylelint-disable */
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  /* stylelint-enable */
  -webkit-line-clamp: $lines;
  overflow: hidden;
}

@mixin truncate($width) {
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $radius;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  }

  scrollbar-color: $foreground-color $background-color;
}

@mixin nte-focus {
  box-shadow: 0 0 0 1px $color-focus-light, 0 0 1px 2px $color-focus-dark !important;
  // Add support for Windows High Contrast Mode (WHCM)
  // The transparent color only shows when WHCM is triggered
  outline: 2px solid transparent;
  outline-offset: 2px;
}

// https://css-tricks.com/creating-a-maintainable-icon-system-with-sass/
@function get-icon( $icon, $color: #505050 ) {

  @if 'color' != type-of( $color ) {

    @warn 'The requested color - "' + $color + '" - was not recognized as a Sass color value.';
    @return null;
  }

  @if map-has-key( $svg-icons, $icon ) {

    $icon: map-get( $svg-icons, $icon );
    $placeholder: '%%COLOR%%';
    $data-uri: str-replace( url( $data-svg-prefix + $icon ), $placeholder, $color );

    @return str-replace( $data-uri, '#', '%23' );
  }

  @warn 'The requested icon - "' + $icon + '" - is not defined in the $icons map.';
  @return null;
}

// https://codepen.io/kevinweber/pen/dXWoRw
$encoding-reference: (
  //('%','%25'),  // Encode "%" first, otherwise the "%" from encoded code would be encoded again (which would be bad)
        ('<','%3C'),
        ('>','%3E'),
      //('"','%22'),  // Replace " with ' because that's shorter than %22 and normally working
        ('"','\''),
        ('#','%23'),
        ('&','%26')
  // Here are a few more characters you could encode
  //(' ','%20'),
  //('!','%21'),
  //('$','%24'),
  //(',','%27'),
  //('(','%28'),
  //(')','%29'),
  //('*','%2A'),
  //('+','%2B'),
  //('"','%2C'),
  //('/','%2F'),
  //(':','%3A'),
  //(';','%3B'),
  //('=','%3D'),
  //('?','%3F'),
  //('@','%40'),
  //('[','%5B'),
  //(']','%5D'),
  //('^','%5E'),
  //('`','%60'),
  //('{','%7B'),
  //('|','%7C'),
  //('}','%7D'),
  //('~','%7E'),
  //(',','%E2%80%9A'),
  //('\\','%5C'),
  //('_','%5F'),
  //('-','%2D'),
  //('.','%2E'),
  // ('\','%5C'),
  // (' ','%7F'),
  // ('`','%E2%82%AC'),
  //('ƒ','%C6%92'),
  //('„','%E2%80%9E'),
  //('…','%E2%80%A6'),
  //('†','%E2%80%A0'),
  //('‡','%E2%80%A1'),
  //('ˆ','%CB%86'),
  //('‰','%E2%80%B0'),
  //('Š','%C5%A0'),
  //('‹','%E2%80%B9'),
  //('Œ','%C5%92'),
  //('','%C5%8D'),
  //('Ž','%C5%BD'),
  //('','%8F'),
  //('','%C2%90'),
  //(','%'E2%80%98'),
  //(','%'E2%80%99'),
  //('“','%E2%80%9C'),
  //('”','%E2%80%9D'),
  //('•','%E2%80%A2'),
  //('–','%E2%80%93'),
  //('—','%E2%80%94'),
  //('˜','%CB%9C'),
  //('™','%E2%84'),
  //('š','%C5%A1'),
  //('›','%E2%80'),
  //('œ','%C5%93'),
  //('','%9D'),
  //('ž','%C5%BE'),
  //('Ÿ','%C5%B8'),
  //(' ','%C2%A0'),
  //('¡','%C2%A1'),
  //('¢','%C2%A2'),
  //('£','%C2%A3'),
  //('¤','%C2%A4'),
  //('¥','%C2%A5'),
  //('¦','%C2%A6'),
  //('§','%C2%A7'),
  //('¨','%C2%A8'),
  //('©','%C2%A9'),
  //('ª','%C2%AA'),
  //('«','%C2%AB'),
  //('¬','%C2%AC'),
  //(','%'C2%AD'),
  //('®','%C2%AE'),
  //('¯','%C2%AF'),
  //('°','%C2%B0'),
  //('±','%C2%B1'),
  //('²','%C2%B2'),
  //('³','%C2%B3'),
  //('´','%C2%B4'),
  //('µ','%C2%B5'),
  //('¶','%C2%B6'),
  //('·','%C2%B7'),
  //('¸','%C2%B8'),
  //('¹','%C2%B9'),
  //('º','%C2%BA'),
  //('»','%C2%BB'),
  //('¼','%C2%BC'),
  //('½','%C2%BD'),
  //('¾','%C2%BE'),
  //('¿','%C2%BF'),
  //('À','%C3%80'),
  //('Á','%C3%81'),
  //('Â','%C3%82'),
  //('Ã','%C3%83'),
  //('Ä','%C3%84'),
  //('Å','%C3%85'),
  //('Æ','%C3%86'),
  //('Ç','%C3%87'),
  //('È','%C3%88'),
  //('É','%C3%89'),
  //('Ê','%C3%8A'),
  //('Ë','%C3%8B'),
  //('Ì','%C3%8C'),
  //('Í','%C3%8D'),
  //('Î','%C3%8E'),
  //('Ï','%C3%8F'),
  //('Ð','%C3%90'),
  //('Ñ','%C3%91'),
  //('Ò','%C3%92'),
  //('Ó','%C3%93'),
  //('Ô','%C3%94'),
  //('Õ','%C3%95'),
  //('Ö','%C3%96'),
  //('×','%C3%97'),
  //('Ø','%C3%98'),
  //('Ù','%C3%99'),
  //('Ú','%C3%9A'),
  //('Û','%C3%9B'),
  //('Ü','%C3%9C'),
  //('Ý','%C3%9D'),
  //('Þ','%C3%9E'),
  //('ß','%C3%9F'),
  //('à','%C3%A0'),
  //('á','%C3%A1'),
  //('â','%C3%A2'),
  //('ã','%C3%A3'),
  //('ä','%C3%A4'),
  //('å','%C3%A5'),
  //('æ','%C3%A6'),
  //('ç','%C3%A7'),
  //('è','%C3%A8'),
  //('é','%C3%A9'),
  //('ê','%C3%AA'),
  //('ë','%C3%AB'),
  //('ì','%C3%AC'),
  //('í','%C3%AD'),
  //('î','%C3%AE'),
  //('ï','%C3%AF'),
  //('ð','%C3%B0'),
  //('ñ','%C3%B1'),
  //('ò','%C3%B2'),
  //('ó','%C3%B3'),
  //('ô','%C3%B4'),
  //('õ','%C3%B5'),
  //('ö','%C3%B6'),
  //('÷','%C3%B7'),
  //('ø','%C3%B8'),
  //('ù','%C3%B9'),
  //('ú','%C3%BA'),
  //('û','%C3%BB'),
  //('ü','%C3%BC'),
  //('ý','%C3%BD'),
  //('þ','%C3%BE'),
  //('ÿ','%C3%BF')
);

@function svg-encode($icon) {
  @if map-has-key( $svg-icons, $icon ) {

    $icon: map-get( $svg-icons, $icon );
    @each $char, $encoded in $encoding-reference {
      $icon: str-replace($icon, $char, $encoded);
    }
    @return url( $data-svg-prefix + $icon );
  }
}

@function get-icon( $icon, $color: #505050 ) {

  @if 'color' != type-of( $color ) {

    @warn 'The requested color - "' + $color + '" - was not recognized as a Sass color value.';
    @return null;
  }

  @if map-has-key( $svg-icons, $icon ) {

    $icon: map-get( $svg-icons, $icon );
    $placeholder: '%%COLOR%%';
    $data-uri: str-replace( url( $data-svg-prefix + $icon ), $placeholder, $color );

    @return str-replace( $data-uri, '#', '%23' );
  }

  @warn 'The requested icon - "' + $icon + '" - is not defined in the $icons map.';
  @return null;
}

@mixin safari-fb-fix($selector: li, $gap: 24px, $orientation: h) {
  // Safari 14< does not support flex gap - https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    #{$selector}:not(:last-child) {
      @if $orientation == h {
        margin-right: $gap;
      } @else {
        margin-bottom: $gap
      }
    }
  }
}
