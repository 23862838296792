div.MuiPaper-root.MuiDrawer-paper {
  max-width: 360px;
  min-width: 320px;
  overflow: hidden;
}
.compare-products-drawer {
  div.MuiPaper-root.MuiDrawer-paper {
    height: 100%;
    left: auto;
    max-width: calc(100% - 32px);
    min-width: 320px;
    overflow: visible;
    right: 0;
    top: 48px;

    .product_compare_modal {
      @extend %nte-scrollbar;

      padding-bottom: 24px;
      max-height: calc(100vh - 178px);
      overflow: auto;
    }

    .modal_body {
      padding-right: 4px;
      padding-bottom: 8px;
    }
    @include media('>=960px') {
      left: 50%;
      max-width: 90vw;
      right: auto;
      transform: translateX(-50%);
      .modal_body {
        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
      }
    }
  }
}

.store_card-component {
  ul.stores {
    padding-bottom: 24px;
  }
}
