.tab-bar {
  @include scaled-spacing(column-gap, 4, 40px);

  display: flex;
}

.MuiTabs-root {
  border-bottom: 1px solid $c-divider;

  .MuiTab-root {
    box-shadow: none;
    margin: 4px;
    padding: 8px 16px;
  }

  .MuiTabs-indicator {
    height: 4px;
    margin-bottom: 4px;
  }

  .MuiTab-textColorInherit.Mui-disabled {
    background-color: transparent;
    color: $c-gray-c8;
    opacity: 1;
  }

  &.scrollable {
    .MuiTabs-scroller.MuiTabs-fixed {
      @extend %nte-scrollbar-3px;

      overflow-x: auto !important;
    }
  }
}
