@import '../../../theme/scss/variables';

.menuStyle {
    text-decoration: none;
  }
  
  .numberStyle {
    color: #1460aa;
  }
  
  .textStyle {
    text-decoration: none;
    color: #000000;
  }

  .feedbackStyle {
      padding: 10px;
      border: .5px solid rgba(153, 0, 0, 1);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      border-width: 1px;
      border-radius: 3px;
      text-align: center;
      width: 130px;
  }

  .api-error-details {
    visibility: hidden;
    display: none;
  }