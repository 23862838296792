@import 'bootstrap-5.1.3/scss/functions';

@import 'bootstrap-5.1.3/scss/variables';
@import 'bootstrap-5.1.3/scss/mixins';
//
// Bootstrap Grid
//
$enable-grid-classes: false;
$enable-cssgrid: true;

$spacer: 4px;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$grid-gutter-width: 40px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px
);

// Prefix for :root CSS variables

$variable-prefix: nte-;

@import 'bootstrap-5.1.3/scss/grid';
@import 'bootstrap-5.1.3/scss/tables';

html .cmc-content {
    .grid .grid {
        height: auto;
        gap: 0 7%;
    }
    div[class^='g-col-'].lazyloaded {
        background-repeat: no-repeat;
    }
}
