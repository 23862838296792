.bread-crumbs-container {
  padding-bottom: 16px;
  padding-top: 16px;

  * {
    font-size: 14px;
    line-height: 24px;
  }

  @include media('<=667px') {
    height: 56px;
    position: relative;

    .separator {
      display: none;
    }
    li {
      position: absolute;
      transform: translateX(-100vw);

      &:first-child,
      &:last-child {
        display: none;
      }

      &:nth-last-child(2) {
        transform: translateX(0);

        a {
          &::before {
            content: '<';
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.bread-crumbs-link {
  display: inline-block;

  .disable-link {
    cursor: default;
    font-weight: 700;
    pointer-events: none;
  }

  .text-white {
    color: #fff;
  }

  .text-dark {
    color: #000;
  }
}
