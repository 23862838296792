.nte_table,
.nte-table {
  border-radius: $radius;
  th:not([scope='row']) {
    border: 1px solid $c-gray-c8;
    background-color: #000;
    color: #fff;
    font-weight: 700;
  }
  th, td {
    padding: 16px 12px;
    text-align: left;
  }
  .MuiTableCell-root, td, th[scope='row'] {
    border-bottom: 1px solid $c-gray-c8;
    border-collapse: collapse;
  }
  .MuiTableCell-head,
  .MuiTableCell-root {
    font-size: 16px;
    line-height: 24px;
  }
  .MuiTableCell-head {
    background: #000;
    color: #fff;
    font-weight: 700;
  }
  tr:last-child {
    .MuiTableCell-root {
      border-bottom-color: transparent;
    }
  }
}

