.carousel {
  margin: 0 auto;
  max-width: calc(100% - 16px);
  position: relative;
  width: auto;

  .slick-track {
    align-items: stretch;
    display: flex;
    margin-left: 0;

    .slick-slide {
      align-items: stretch;
      display: flex;
      height: auto;
      justify-content: center;

      &> div {
        width: 100%;
      }
    }
  }

  .carousel-slide {
    height: 100%;
  }

  button.slick-prev,
  button.slick-next {
    background: $c-gray-e5;
    border: 4px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 900;
    height: 48px;
    line-height: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    z-index: 1;
    @include elevation(3);

    &::before {
      content: '';
    }

    svg {
      fill: $c-gray-50;
    }

    &:not([disabled]):hover {
      background: #fff;
      transition: all $global-transition ease-in-out;

      svg {
        fill: #000;
      }
    }

    &:disabled {
      cursor: default;
      opacity: 0.3;
    }
  }

  .category_carousel_slide p.number_of_items {
    color: $c-gray-aa-readable;
    font-weight: 400;
    position: absolute;
    right: 4px;
    top: 4px;
    transform: none;
    width: auto;
  }

  .slick-prev {
    left: -16px;
  }

  .slick-next {
    right: -16px;
  }

  button.play-pause {
    @include elevation(3);

    background-color: #fff;
    border: 4px solid #fff;
    border-radius: $radius;
    bottom: -22px;
    height: 32px;
    left: 50%;
    padding: 0;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 48px;
    z-index: 2;

    svg {
      vertical-align: middle;
    }

    &:hover {
      svg {
        fill: #000;
      }
    }
  }
}

