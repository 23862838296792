.cmc-content {
  @extend %nte-base-style;

  h1 {
    //@include scaled-spacing(margin-bottom, 5, 32px);
  }

  .card_page_title h1 {
    margin-top: 0;
  }

  h2, h3 {
    line-height: 1.3;
  }

  img[data-sizes='auto'] {
    display: block;
    width: 100%;
  }

  .bread-crumbs-link:last-child {
    font-weight: 700;
  }

  .page-title {
    position: relative;

    &.has-background-image {
      background-color: #000;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;

      .wrap-all {
        @extend .full-width;

        height: 128px;
      }

      .background-image {
        position: relative;

        &::before,
        &::after {
          bottom: 0;
          content: '';
          display: inline-block;
          height: 100%;
          position: absolute;
          width: 200px;
        }

        &::before {
          background: linear-gradient(90deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 80%) 9%, rgb(255 255 255 / 0%) 100%);
          left: 0;
        }

        &::after {
          background: linear-gradient(270deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 80%) 9%, rgb(255 255 255 / 0%) 100%);
          right: 0;
        }
      }

      .content-wrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: $max-width;
        padding-left: 40px;
        width: 100%;
      }

      * {
        color: #fff;
      }
    }
  }

  .nte-card {
    p:last-child {
      margin-bottom: 0;
    }
  }

  p:not(:last-child, .section-row, .field-margin),
  button.inline-text:not(:last-child ){
    margin-bottom: 16px;
  }

  .search-input-wrapper {
    .MuiToolbar-root {
      justify-content: flex-end;
      min-height: auto;
      padding: 0;
      position: relative;

      input {
        padding-left: 40px;
      }

      svg {
        position: absolute;
        right: 166px;
        top: 12px;
        z-index: 1;
      }
    }
  }

  .product-list {
    margin-top: 4px;

    .product-block {
      .content {
        column-gap: 32px;
        display: grid;
        grid-template-columns: 204px 1fr 224px;
        height: 200px;
      }

      .image-col,
      .price-col {
        background-color: $c-gray-c8;
      }

      .info-col {
        background-color: $c-gray-e5;
      }
    }
  }

  .centered {
    margin: 0 auto;
  }

  button.tab {
    @extend %reset-button;

    background-color: inherit;
    color: inherit;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;

    &:hover {
      background-color: inherit;
    }
  }

  *.tab {
    &:not(.active) {
      @include link-effect;

      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      color: $c-text;
      font-weight: 700;
    }
  }
  // Accordions
  .accordion {
    .header {
      @extend %reset-button;

      align-items: center;
      background-color: inherit;
      color: inherit;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;

      h2, h3, h4 {
        margin-bottom: 0;
      }

      &:hover {
        background-color: inherit;
      }

      &::after {
        border-radius: 50%;
        color: inherit;
        content:  svg-encode('caret');
        display: inline-block;
        height: 24px;
        transition: all $global-transition;
        width: 24px;
      }

      h3, h4 {
        margin-right: 40px;
      }
    }

    &.open {
      .header {
        margin-bottom: 24px;
      }

      .content {
        visibility: visible;
      }
    }

    .content {
      max-height: 0;
      opacity: .2;
      overflow: hidden;
      transition: all 400ms ease-in-out;
      visibility: hidden;
    }
    // Header animation
    &:not(.open) {
      .header {
        &:hover::after {
          @include elevation(3);
        }
      }
    }

    &.open {
      .header {
        &::after {
          transform: rotate(180deg);
        }

        &:hover::after {
          @include svg-elevation(3);
        }
      }

      .content {
        max-height: 5000px;
        opacity: 1;
        overflow: hidden;
      }
    }
  }

  &:not(:last-child).open .content {
    @include scaled-spacing(margin-bottom, 4, 28px);
  }

  .accordion:not(:last-child) {
    border-bottom: 1px solid $c-gray-e5;
    @include scaled-spacing(margin-bottom, 4, 28px);
    @include scaled-spacing(padding-bottom, 4, 28px);
  }


  // Tabs
  .right {
    position: relative;
  }

  .tab-content {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 600ms;
    visibility: hidden;
    z-index: -1;

    &.active {
      opacity: 1;
      position: static;
      visibility: visible;
      z-index: 2;
    }
  }

  .page-tab-row {
    @include scaled-spacing(margin-bottom, 5, 40px);

    height: 32px;
    max-width: $max-width;
    width: 100%;

    .tab-row-ui {
      border-bottom: 1px solid $c-gray-c8;
      left: 0;
      position: absolute;
      width: 100%;
    }

    ul.tab-row {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 960px;
      padding-bottom: 7px;
    }
  }

  figure.block-quote {
    @include scaled-spacing(padding, 5, 40px);

    border-radius: $radius;
    margin-top: 24px;

    &.gray-bg {
      background-color: $c-gray-f2;
    }

    & > * {
      color: $c-gray-50;
    }
  }

  blockquote {
    & > * {
      font-family: $family-headers;
      font-size: 20px;
      font-style: italic;
      font-weight: 700;
      line-height: 28px;
    }
  }

  figcaption {
    margin-top: 16px;
    text-align: right;

    &::before {
      content: '\2014';
      margin-right: 4px;
    }
  }

  //
  // Rich Content
  &.rich,
  &.promo {
    &> .grid {
      margin: 0 auto;
      max-width: 960px;
    }

    div[class^='g-col-'] {
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .image-cell {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .grid {
    display: grid;
  }

  .grid,
  .nte-card {
    height: 100%;
  }

  .nte-card.no-padding {
    .content-cell {
      @include scaled-spacing(padding, 5, 32px);
    }
  }

  .image-cell {
    text-align: center;

    &.lazyloaded {
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      img {
        left: 0;
        object-fit: cover;
        top: 0;
      }
    }

    &.with-aspect {
      background-position: center;
      background-size: cover;
    }
  }

  figcaption {
    font-size: 14px;
    line-height: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
  }
  // Dark UI
  .dark-ui {
    background-color: #000;

    *:not(.link-effect) {
      color: #fff;
    }

    a, .link-effect {
      color: $c-light-blue;
    }

    .accordion .header::after {
      background-color: $c-secondary;
    }
  }

  .white-ui {
    background-color: #fff;
  }

  hr, hr.MuiDivider-root {
    @include scaled-spacing(margin-bottom margin-top, 5, 40px);
  }

  // Home Slides
  .home-slide {
    @include aspect-ratio(1360, 490);

    background-repeat: no-repeat;
    background-size: cover;
    min-height: 240px;
    position: relative;
    width: 100%;

    .image-cell {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .content-cell {
      padding-left: 24px;
      padding-right: 24px;
      @include scaled-spacing(padding-top padding-bottom, 6, 36px);

      @include media('>820px') {
        @include scaled-spacing(padding-left padding-right, 2, 56px);
      }

      * {
        text-decoration-color: transparent !important;
      }

      &.background-gradient {
        background-color: rgb(0 0 0 / 80%);
        display: inline-block;
      }

      &.vcenter {
        @include media('>1024px') {
          @include vertical-align;
        }

         // Ipad only fix for vertical align of carosel in ipad
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
          top: 0%;
          -ms-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }

    .branding {
      @include svg-elevation(2);
    }

    &.grid,
    .grid {
      gap: 0;
      place-items: center;
      position: relative;
      z-index: 0;
    }

    &:hover {
        text-decoration: none;
        text-underline-offset: unset;
    }

    button {
      @include media('<=720px') {
        padding: 9px 16px;
      }
      @include fluid-font-size(14px, 24px, 360px, 1440px);

      font-weight: 900;
      height: auto;
      max-width: 100% !important;

      &.contained {
        background-color: $c-yellow;
        color: #000;

        &:hover {
          @include  elevation(4);

          background-color: $c-gray-f2;
        }
      }
    }

    .pill {
      border-radius: $radius;
      display: inline-block;
      font-family: $family-headers;
      font-size: 18px;
      font-weight: 700;
      padding: 4px 8px;
    }

    h2 {
      @include fluid-font-size(24px, 64px, 360px, 1440px);

      font-weight: 900;
      line-height: 1.1 !important;
    }

    h3 {
      @include fluid-font-size(18px, 48px, 360px, 1440px);

      line-height: 1.1 !important;
    }

    .branding {
      @include aspect-ratio(1, 1);

      height: auto;
      max-width: 142px;
      position: absolute;
      width: 12vw;

      &.bottom {
        @include scaled-spacing(bottom, 6, 36px);
      }

      &.top {
        @include scaled-spacing(bottom, 6, 36px);
      }

      &.left {
        left: 24px;
      }

      &.right {
        right: 24px;
      }
      @include media('>820px') {
        &.left {
          @include scaled-spacing(left, 2, 56px);
        }

        &.right {
          @include scaled-spacing(right, 2, 56px);
        }
      }
    }
  }
  // layout for cms content 4 cols
  // breakdown to 2 for tablet below
  .content_recommendation_espot {
    .cg-cols-4 {
      @include media('<=768px') {
        grid-template-columns: repeat(2, 1fr);
      }
    } 
  }
}

