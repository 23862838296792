.swatch_section.cg-cols-2 {
  @extend %section-row;
  align-items: center;
  grid-template-columns: min-content 1fr;
  h4 {
    display: inline-block;
  }
}

ul.swatch_list {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

html .MuiChip-root {
  font-size: 16px;
}

.swatch-button {
  @include elevation(1);

  align-items: center;
  background-color: $c-gray-e5;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  padding: 2px 12px 2px 4px;
  transition: all $global-transition ease-in;
  &:hover {
    background-color: $c-gray-c8;
    transition: all $global-transition ease-out;
  }

  .sample {
    border: 1px solid #000;
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }
}

.swatch-container.swatch-active {
  .swatch-button {
    background-color: $c-gray-c8;
  }
}
