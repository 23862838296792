.category_layout {
  .category-list li {
    max-width: 316px;
  }
}

.page.plp_page,
.page.search_page {
  position: relative;

  .load-more-container {
    h5 {
      padding: 8px;
      text-align: center;
    }

    .load-more-button {
      display: block;
      margin: auto;
    }
  }

  .product-feedback-container {
    display: flex;
    text-align: center;
    width: 100%;

    .feedback-content {
      margin: auto;

      .button-outer-container {
        display: flex;

        .button-inner-container {
          margin: auto;

          button {
            margin: 0 16px;
          }
        }
      }
    }
  }

  .product-feedback-container {
    svg {
      margin-right: 8px;
      transform: translateY(6px);
    }
  }

  .pagination-container {
    margin: auto;
    padding: 24px;
    text-align: center;
  }

  .left:not(.gradient-overlay),
  .right:not(.gradient-overlay) {
    margin-top: 8px;
  }

  .right .product-list {
    h2.page-count-container {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 28px;
    }
  }

  .product-container:not(:first-child) .page-count-container {
    margin-top: 24px;
  }

  ul.product-list {
    margin-top: 24px;
  }

  .store_finder ul.stores {
    height: auto;
    max-height: 440px;
  }

  .grid-product-card-load-more-spacing {
    margin-top: 2em;
  }
}

.nte-category-carousel {
  .slick-slide {
    max-width: 190px;
  }
  .category_carousel_slide {
    @include elevation(1);

    background-color: #fff;
    border-radius: $radius;
    cursor: pointer;
    height: calc(100% - 4px);
    margin: 0 8px 4px;
    padding: 16px;
    position: relative;
    transition: all $global-transition ease-in-out;

    a {
      min-height: 100%;
      text-decoration: none;
      text-underline-offset: unset;
      transition: none;

      .thumbnail {
        text-align: center;

        img {
          display: inline;
          text-align: center;
          width: 74%;
        }
      }
    }

    &:hover {
      @include elevation(4);
    }

    h3 {
      font-family: $family-condensed;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin: 4px auto 0;
      transform: translateX(-4px);
      width: 80%;
    }
  }
}

@include media('<=667px') {
  .plp-store-page .bread_crumbs {
    height: 24px;
  }
}

@include media('<=1080px') {
  .plp_page {
    .header-container {
      display: grid;
      grid-template-areas:
        'filter-row'
        'count';
      grid-template-columns: 100%;

      h3 {
        grid-area: count;
      }

      .sort-container {
        grid-area: filter-row;
        @include scaled-spacing(margin-bottom, 5, 32px);
      }

      .mobile-filters-button {
        margin-right: 24px;

        .MuiButton-label {
          display: inline-block;
          width: auto;
        }
      }
    }

    button.back-to-top {
      .MuiButton-label {
        white-space: nowrap;
      }

      @include media('>1080px') {
        display: none;
      }
    }
  }
}

.category-list {
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  @include media('>=720px') {
    grid-template-columns: repeat(3, minmax(180px, 1fr));
  }
  @include media('>=960px') {
    grid-template-columns: repeat(4, minmax(208px, 1fr));
  }

  .nte-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 148px;
    position: relative;
    text-align: center;

    h3 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
      margin-top: 8px;
      @include media('>=600px') {
        margin-top: 16px;
      }
    }
  }

  .number_of_items {
    position: absolute;
    right: 8px;
    top: 4px;
  }

  .thumbnail {
    margin: 8px auto 0;
    max-width: 200px;
    width: 100%;
  }
}

.category_recommendation_layout--button-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;

  a {
    margin: 0;
  }
}

.brands--grid {
  .category-list {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    grid-template-rows: 150px;
    grid-auto-rows: 150px;
    @include media('>=680px') {
      grid-template-columns: repeat(3, minmax(180px, 1fr));
      grid-template-rows: 180px 180px 180px 180px;
      grid-auto-rows: 180px;
    }
    @include media('>960px') {
      grid-template-columns: repeat(4, minmax(180px, 1fr));
      grid-template-rows: 180px 180px 180px;
      grid-auto-rows: 180px;
    }
    @include media('>=1280px') {
      grid-template-columns: repeat(6, minmax(170px, 1fr));
      grid-template-rows: 170px 170px;
      grid-auto-rows: 170px;
    }
  }
}

.brands--grid {
  .nte-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .thumbnail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      width: 100%;
      overflow: hidden;
    }
  }
}

.sticky-target {
  top: 0;
}

html .sort-filter-container {
  padding: 16px 12px;
  transition: all $global-transition ease;

  .sort-only {
    align-items: center;
    display: flex;
  }

  .plp-grid {
    display: flex;
  }

  .plp-sort-select label {
    opacity: 0;
  }
  .MuiFormControl-root {
    min-width: 150px;
  }

  h2 {
    @include fluid-font-size(16px, 24px, 360px, 1440px);

    margin-right: 16px;
  }

  button.MuiIconButton-root.back-to-top-icon {
    background-color: #fff;
    border: 1px solid $c-cta;
    margin-left: 8px;
    width: 48px;
    @include elevation(2);
  }

  @include media('<520px') {
    button {
      padding: 4px 8px;

      &.mobile-filters-button {
        background-color: #fff;
        margin-right: 16px;
        min-width: 48px;
      }
    }

    select.MuiSelect-root {
      margin-right: 8px;
    }

    h3 {
      font-size: 16px;
    }

    .MuiButton-label {
      font-size: 12px;
    }
  }
  @include media('<360px') {
    transform: translateX(-12px);
  }
  @include media('>=360px', '<=540px') {
    padding: 16px;
    transform: translateX(-16px);
  }
  @include media('<=540px') {
    .back-to-top {
      display: none;
    }
  }
  @include media('>540px') {
    .back-to-top-icon {
      display: none;
    }
  }
  @include media('>=540px', '<900px') {
    padding: 16px 32px 16px 20px;
    transform: translateX(-20px);
  }
  @include media('>=720px', '<900px') {
    padding: 16px 40px 16px 24px;
    transform: translateX(-24px);
  }
  @include media('>=900px', '<1080px') {
    padding: 16px 48px 16px 28px;
    transform: translateX(-28px);
    .plp-grid {
      padding-right: 5%;
    }
  }
  @include media('<=1080px') {
    align-items: center;
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;

    .mobile-filters-button {
      margin-right: 24px;
    }
  }

  @include media('<900px') {
    align-items: center;
    background-color: rgb(255 255 255 / 40%);
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;

    .mobile-filters-button {
      margin-right: 24px;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 18px;
      right: 0;
      background-color: rgb(255 255 255 / 94%);
      z-index: 2;
      @include elevation(4);
    }
  }
  @include media('>1080px') {
    padding: 0;
    position: relative;

    .backToTopContainer{
      display: none;
    }

    .sort-only {
      position: absolute;
      right: 170px;
      top: -62px;

      h2,
      .MuiFormControl-root {
        display: inline-block;
        vertical-align: middle;
      }
    }

    // Will be applied when grid toggle is not displayed
    .sort-only-right-0 {
      right: 0;
    }

    .plp-grid {
      position: absolute;
      top: -62px;
      right: 0;
    }
  }
}

.category_cards {
  text-align: center;

  .nte-card {
    text-align: center;
  }

  .title-block {
    text-align: left;
  }

  button {
    display: block;
    margin: 0 auto;
  }
}

#root .facets,
.facet_mobile {
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    align-items: start;
  }

  .MuiAccordionDetails-root {
    padding-bottom: 0;

    ul.facet-list {
      margin-right: 8px;
      max-height: 238px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 8px 0 8px 8px;
      transition: max-height 300ms ease-in-out;
      @extend %nte-scrollbar;

      &.open {
        max-height: 372px;
      }

      li {
        margin-right: 4px;
      }

      label.MuiFormControlLabel-root {
        align-items: flex-start;

        span.MuiButtonBase-root.MuiIconButton-root {
          height: auto;
          margin-right: 0;
        }
      }
    }
  }

  .sold_in_store {
    .find-store {
      margin-top: 24px;
    }
  }

  .is-anchor-store {
    .my-store {
      font-size: 13px;
      grid-template-columns: 20px 1fr;
      transform: translateX(32px);

      svg.star {
        fill: $c-green;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.facet-rating {
  display: flex;

  .MuiRating-icon,
  svg {
    height: 20px;
    width: 20px;
  }

  p {
    margin-left: 8px;
  }
}

.facet_mobile {
  .filters li {
    margin: 0 4px;

    .MuiButtonBase-root {
      align-items: center;
    }

    h3 {
      line-height: 1;
    }
  }
}

ul.filters > li {
  .nte_accordion.MuiAccordion-root {
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p.find-store {
    align-items: center;
    display: flex;
  }

  .MuiAccordionDetails-root {
    column-gap: 4px;
    display: flex;
    flex-direction: column;
  }

  .see-more {
    font-size: 14px;
    margin-top: 24px;
  }

  .price_facet {
    @include scaled-spacing(margin-bottom, 5, 32px);
    @include media('<=1180px') {
      .MuiFormControl-root,
      .MuiTypography-body2 {
        margin-bottom: 8px;
      }

      .MuiTypography-body2 {
        margin-left: 8px;
      }

      button {
        margin-top: 8px;
      }
    }
    @include media('>1180px') {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .MuiFormControl-root {
        margin-bottom: 0;
        width: 72px;
      }

      button {
        min-width: 56px;
        width: 56px;
      }
    }
  }
}

.active_facet {
  .MuiAccordion-root,
  .MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 40px;
  }

  h3 {
    font-weight: 600;
  }

  .clear-all {
    margin-top: 24px;
  }

  button.MuiButtonBase-root.MuiButton-root {
    white-space: normal;
  }
}

.product-list {
  position: relative;
}

.float.compare-bar {
  @include media('<960px') {
    .icon_text {
      order: 2;
    }

    button {
      margin-right: 24px;
      order: 1;
    }
  }

  align-items: center;
  background-color: rgb(255 255 255 / 92%);
  bottom: 1px;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 16px 0;
  width: 100%;
  z-index: 20;
  @include elevation(24);

  h3,
  label {
    margin-right: 24px;
  }

  .icon_text span {
    font-size: 14px;
  }

  ul {
    display: flex;

    li {
      align-items: center;
      background-color: $c-gray-e5;
      border-radius: 6px;
      display: flex;
      margin-right: 16px;
      padding: 8px;
    }
  }

  .thumbnail {
    height: 64px;
    width: 64px;
  }

  .placeholder {
    background-color: $c-gray-e5;
    border-radius: 6px;
    height: 80px;
    margin-right: 16px;
    width: 112px;
  }

  .intrinsic-image {
    @include intrinsic-ratio(1 1);

    width: 64px;
  }

  button {
    margin-left: 8px;
  }

  .remove-all {
    margin-right: 16px;
  }
}

#product-results-feedback {
  @include media('<=412px') {
    width: calc(100% - 32px);
  }

  background-color: #fff;
  border: 1px solid $c-gray-c8;
  border-radius: $radius;
  margin-left: auto;
  margin-right: auto;
  max-width: 380px;
  padding: 16px;
  width: 100%;

  .MuiTypography-body2 {
    display: flex;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
  }

  svg {
    height: 32px;
    margin-right: 8px;
    width: 32px;
  }
}

#mobile-filter {
  height: 100vh;

  .scrollable-region {
    height: calc(100vh - 64px);
  }

  .active_facet {
    margin-top: 16px;

    .MuiButtonBase-root {
      width: auto;
    }
  }

  .nte_drawer-paper {
    background-color: $c-gray-f2;
  }

  ul.filters {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }

  button.view-results {
    display: block;
    margin: 0 auto;
    width: calc(100% - 16px);
  }
}
