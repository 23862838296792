// NOTE: All padding/margin specifications are in the layout.scss file
header {
  border-bottom: 1px solid $c-gray-50;
  .header_wrapper {
    margin: 0 auto;
    @extend .full-width;

    button *,
    .cart_label p {
      color: #fff;
      text-decoration: none;
      text-decoration-color: transparent;
      text-underline-offset: unset;
    }

    h2 {
      font-family: $family-h1;
    }

    button.MuiButton-text {
      &:hover {
        h2,
        .MuiTypography-body2 {
          color: $c-secondary;
          transition: all $global-transition ease-in-out;
        }

        svg {
          fill: $c-secondary;
        }
      }

      &[aria-expanded='true'] {
        h2,
        .MuiTypography-body2 {
          color: $c-secondary;
        }

        svg.MuiSvgIcon-root.expand-icon {
          transform: rotate(180deg);
        }
      }

      span.MuiButton-label {
        &:hover {
          text-decoration-color: transparent;
        }
      }
    }

    .store-top-menu span.open {
      @include media('<=760px') {
        display: none;
      }

      display: block;
    }

    .hamburger-container {
      display: flex;
      justify-content: center;
      min-width: 64px;

      button {
        padding: 0;
      }

      svg:not(.MuiCircularProgress-svg) {
        fill: $c-secondary;
        height: 48px;
        width: 48px;
      }
    }

    // Layout
    button.MuiButton-text.MuiButtonBase-root.MuiButton-root span.MuiButton-label {
      align-items: center;
      display: flex;
      svg:not(.cart-icon) {
        margin-right: 6px;
      }
    }

    ul:not(.MuiAutocomplete-listbox):not(.MuiAutocomplete-groupUl) {
      align-items: center;
      display: grid;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      @include media('>=540px') {
        column-gap: 8px;
      }
      @include media('>=640px') {
        column-gap: 16px;
      }
      @include media('>=720px') {
        column-gap: 24px;
      }
      @include media('>=1080px') {
        column-gap: 32px;
      }
    }

    .nav_bar {
      column-gap: 8px;
      display: flex;
    }

    nav,
    ul {
      justify-content: space-between;
    }

    .primary.nav_bar {
      height: 88px;

      .nav-bar-skeleton {
        column-gap: 16px;

        .nav-bar-skeleton-bg {
          min-width: 100px;
        }
      }

      .menu-button span {
        font-family: $family-headers;
        font-size: 24px;
        font-weight: 700;
      }

      @include media('>=720px') {
        column-gap: 8px;
      }
      @include media('>=1080px') {
        justify-content: space-between;

        ul {
          column-gap: 4px;
        }
        .home-link {
          display: flex;
        }
        .nte_primary_search_bar {
          width: 44%;
        }
      }
      @include media('>=1080px') {
        column-gap: 32px;
        display: grid;
        grid-template-columns: 192px auto 1fr;
        width: 100%;
        ul {
          column-gap: 20px;
        }
        .top-level-menu-list {
          min-width: 407px;
          column-gap: 24px;
        }
        .nte_primary_search_bar {
          width: 100%;
        }
      }
      @include media('>=1280px') {
        .top-level-menu-list {
          min-width: 423px;
          column-gap: 32px;
        }
      }

      &.checkout {
        .home-link {
          margin-bottom: 8px;
        }
      }
    }

    .secondary.nav_bar {
      width: 100%;
      @include media('<360px') {
        svg.menu_icon {
          display: none;
        }
      }
      @include media('<960px') {
        .catalog_menu {
          display: none;
        }
      }
    }
  }
}

header {
  display: block;

  .MuiToolbar-regular {
    min-height: auto;
  }

  svg.expand-icon {
    fill: $c-gray-89;
    height: 24px;
    width: 24px;
  }

  .secondary.nav_bar {
    svg.MuiSvgIcon-root:not(.expand-icon) {
      fill: $c-secondary;
      height: 32px;
      width: 32px;
    }
    button.menu-button span:not(.open) {
      display: block;
      font-size: 14px;
      line-height: 16px;
    }
    * {
      font-weight: 400;
    }

    @include media('>small') {
      * {
        font-weight: 700;
      }
    }

    .store_locator {
      .MuiTypography-root.MuiTypography-body2 {
        line-height: 20px;
      }
      .menu-title {
        @include media('<360px') {
          @include truncate(104px);
        }
        @include media('>=360px', '<400px') {
          @include truncate(82px);
        }
      }
    }

    .find_Store_Skeleton{
      min-width: 152.81px;
      @include media('<360px') {
        min-width: 110.19px;
      }
      @include media('>358px', '<=600px') {
        min-width: 148.19px;
      }
    }
  }

  .header-topbarSection,
  .MuiTypography-root.expanded-menu-text {
    color: #fff;
  }

  .home-link {
    height: 48px;
    width: 48px;
  }

  svg {
    &.logo {
      height: 48px;
      width: 48px;
    }

    &.logo_text {
      height: 50px;
      margin-left: 8px;
      margin-right: 8px;
      width: auto;
    }
  }

  .MuiDivider-root.header_divider {
    background-color: $c-gray-50;
    border-bottom: 1px solid $c-gray-50;
  }

  .cart_label {
    color: #fff;
    padding-right: 8px;
    @include media('>=640px') {
      align-items: center;
      display: flex;
    }
    &:hover {
      color: #fff;
    }

    .MuiBadge-root {
      padding-left: 8px;
    }
  }

  .arrowIcon {
    margin-left: 5px;
  }

  .middle {
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .cart_icon_header_badge {
    background-color: $c-cta;
  }

  .cart_icon_header_badge,
  .cart_icon_header_badge_0 {
    font-family: $family-h1;
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 5px;
  }
}

#mini-cart-drawer {
  max-width: 360px;

  div.MuiPaper-root.MuiDrawer-paper {
    border-radius: $radius 0 0 $radius;
    height: 100%;
  }

  .block-list {
    max-height: calc(100vh - 348px);
    overflow-y: auto;
    @extend %nte-scrollbar;
    li.block-list-item:last-child {
      @include scaled-spacing(margin-bottom, 2);
    }
  }

  .block-list-item {
    margin-right: 16px;
  }

  .cart_icon_badge {
    background-color: $c-cta;
    font-weight: 700;
    position: absolute;
    right: 10px;
    top: 5px;
  }

  li.block-list-item {
    column-gap: 16px;
    display: grid;
    grid-template-columns: 33.33% 1fr;

    .product-name {
      margin-bottom: 8px;
    }

    .price {
      font-size: 18px;
      overflow: hidden;
    }
  }

  .thumbnail-container {
    padding-left: 8px;
  }
}

.header-branding {
  a {
    display: block;
    line-height: 1;
  }

  img {
    fill: green;
    height: 60px;
  }
}

// Horizontal alignment of stepper and chat link in checkout header.
.checkout-header {
  @include media('<600px') {
    left: -22px;
    position: absolute;
    top: 60px;

    .MuiStepConnector-horizontal {
      display: none;
    }

    .MuiStepLabel-labelContainer {
      p {
        font-size: 10px;
      }
    }
  }
}

.MuiToolbar-root.MuiToolbar-regular.primary.nav_bar.checkout.MuiToolbar-gutters {
  justify-content: flex-start;

  @include media('<600px') {
    flex-direction: column;
    height: 140px;

    .home-link {
      align-self: start;
    }
  }
}

// Horizontal alignment of chat icon and text.
.header-chat-link {
  align-self: start;
  color: #fff;
  display: flex;
  gap: 8px;
  justify-self: end;

  p {
    color: #fff;
  }

  @include media('<960px') {
    display: none;
  }
}
