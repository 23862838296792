.requirement-passed {
  color: rgb(0, 133, 0);
}

.requirement-passed .MuiSvgIcon-root {
  fill: rgb(0, 133, 0);
}
.requirement-failed-on-hold {
  color: rgb(137, 137, 137);
}

.requirement-failed-on-hold .MuiSvgIcon-root {
  fill: rgb(137, 137, 137);
}

.requirement-failed {
  color: rgb(224, 0, 0);
}

.requirement-failed .MuiSvgIcon-root {
  fill: rgb(224, 0, 0);
}
