body svg.MuiSvgIcon-root {
  background-color: transparent;
  fill: #000;
  height: 24px;
  outline: none;
  transition: all $global-transition ease-in-out;
  width: 24px;

  &:hover {
    fill: #000;
  }

  &.MuiSvgIconGray-root {
    fill: #808080;
  }

  &.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    height: 24px;
    width: 24px;
  }

  &.MuiSvgIcon-fontSizeLarge {
    font-size: 32px;
    height: 32px;
    line-height: 32px;
    width: 32px;
  }

  &.MuiSvgIcon-colorDisabled {
    fill: rgba(0, 0, 0, 0.26);
  }
}

.MuiButton-label,
.MuiBadge-root,
.icon-text {
  svg {
    height: 32px;
    width: 32px;
    &.icon-small,
    &.MuiCircularProgress-svg {
      height: 24px;
      width: 24px;
    }
  }
}

.close_icon.MuiIconButton-root,
.MuiAlert-action button.MuiButtonBase-root.MuiIconButton-root {
  box-shadow: none;
  height: 24px;
  transition: all $global-transition ease-in-out;
  width: 24px;
  &:not(:hover) {
    svg {
      @include svg-elevation(1);
    }
  }
  &:hover {
    @include elevation(1);

    background-color: $c-gray-c8;
  }
}
.MuiCheckbox-root.Mui-checked,
.MuiRadio-root.Mui-checked {
  span.MuiIconButton-label {
    @include elevation(1);
  }
}
svg.checked {
  @include svg-elevation(1);
}
.MuiCheckbox-root {
  span.MuiIconButton-label {
    border-radius: $radius;
  }
  path {
    background-color: $c-green;
  }
}
.MuiRadio-root {
  span.MuiIconButton-label {
    border-radius: 50%;
  }
}

body svg {
  overflow: visible;
}

// Adjusting width and height for icons on delivery page.
.shipping-truck-icon {
  height: 24px;
  width: 40px;
}

.store-pickup-icon {
  height: 24px;
  width: 24px;
}

.info-icon-wrapper,
.icon-text.info-icon {
  column-gap: 4px;
  display: inline-flex;
  position: relative;
  transition: fill $global-transition ease-in-out;
  .info-icon {
    position: absolute;
    right: -28px;
    top: -4px;
  }

  .info-icon button {
    position: relative;
    right: 30%;
    top: -10px;
  }

  svg.MuiSvgIcon-root {
    cursor: pointer;
    fill: $c-brown;
  }
  &:hover svg {
    fill: $c-secondary;
  }
}

.credit-card-image-container {
  align-items: center;
  display: flex;
}
