body {
  .MuiFormControl-root {
    display: block;
    max-width: 300px;
    width: 100%;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 0 0 0 16px;
  }
}

div.MuiInputBase-root,
select:not(.MuiSelect-select) {
  background-color: #fff;
  border: 2px solid $c-gray-e5;
  border-radius: $radius;
  color: $c-text;
  display: flex;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  transition: all $global-transition ease-in-out;

  &.Mui-focused {
    @include nte-focus;
  }

  fieldset {
    border: 0;
    border-radius: 0;
    height: 48px;
    top: 0;
  }

  &.Mui-error {
    border: 1px solid $c-bright-red;
  }
}

textarea {
  background-color: #fff;
  border: 2px solid $c-gray-e5;
  border-radius: $radius;
  color: $c-text;
  font-family: $family-sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
}

.checkbox-wrapper .Mui-focusVisible .MuiIconButton-label,
.MuiFormControlLabel-root[tabindex='0']:focus {
  @include nte-focus;
}

select:not(.MuiSelect-select) {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' focusable='false' viewBox='0 0 24 24' aria-hidden='true'><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path></svg>");
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  background-size: 24px;
  cursor: pointer;
  min-width: 180px;
  padding: 0 40px 0 16px;
}

.field-labels {
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

body input[type='text'],
input.MuiInputBase-input,
select.MuiSelect-select {
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  max-width: 300px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 0;
  width: 100%;

  &::placeholder {
    color: $c-gray-aa-readable;
    font-size: 16px;
    font-weight: 400;
    opacity: 1;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

label.MuiFormLabel-root {
  background-color: #fff;
  color: $c-gray-50;
  font-size: 14px;
  padding: 1px 8px;

  &.MuiInputLabel-outlined {
    transform: translate(8px, 16px) scale(1);
  }

  &.MuiInputLabel-shrink {
    color: #000;
    transform: translate(8px, -5px) scale(0.75);
  }
}

p.MuiFormHelperText-root {
  font-size: 14px;
}

.MuiFormGroup-root.stacked-radios {
  flex-direction: column;
}

.checkbox-wrapper.MuiGrid-root,
.checkbox-wrapper.MuiFormControlLabel-root,
.MuiFormControlLabel-root.radio-wrapper {
  align-items: flex-start;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;

  span.MuiIconButton-label {
    transition: all $global-transition ease-in-out;
  }

  .MuiFormControlLabel-label.MuiTypography-body1 {
    cursor: pointer;
  }

  &:hover {
    span.MuiIconButton-label {
      @include elevation(4);
    }

    .Mui-disabled .MuiIconButton-label {
      box-shadow: none;
    }
  }

  .Mui-disabled svg path:nth-of-type(1) {
    fill: rgba(0, 0, 0, 0.38);
  }

  .MuiFormControlLabel-label {
    transform: translateY(1px);
  }
}

// Horizontal alignment of input fields.
body,
fieldset.not-mui,
.MuiFormGroup-root {
  @for $i from 1 to 6 {
    .field-row-#{$i} {
      align-items: start;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat($i, minmax(80px, 300px));

      &.auto-width {
        display: inline-grid;
        grid-gap: 24px;
        grid-template-columns: repeat($i, auto);
      }

      .MuiButton-outlined.MuiButton-sizeSmall {
        height: 48px;
      }
    }
  }
}

html body .MuiFormControl-root.comments {
  max-width: 100%;

  input#comments {
    max-width: 100%;
  }
}
