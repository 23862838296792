.shopping_cart_page {
  .content-wrapper {
    h1,
    h3 {
      display: inline-block;
    }

    h1 {
      @include scaled-spacing(margin-right, 3, 24px);
    }
  }

  .cart-items-container {
    .empty-cart {
      grid-template-columns: 60px 1fr;

      .cart_icon_badge {
        background-color: transparent;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        left: 16px;
        position: absolute;
        right: revert;
        top: 16px;
      }
      .cart_icon_badge_lower {
        background-color: transparent;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        left: -4px;
        position: absolute;
        right: revert;
        top: 6px;
      }
    }

    .MuiBadge-root {
      display: block;
      position: relative;
    }
  }

  .continue-shopping {
    li {
      margin-bottom: 16px;
    }
  }

  .promo_code {
    margin: 8px 0px 2px 0px;

    .field-row-2,
    .cg-cols-2 {
      grid-template-columns: 1fr 65px;
      @include media('>=360px') {
        grid-template-columns: 1.2fr 0.8fr;
      }
      label.MuiFormLabel-root.MuiInputLabel-outlined {
        font-size: 12px;
        transform: translate(8px, 18px);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          background-color: #fff;
          color: #000;
          transform: translate(8px, -8px) scale(0.8);
        }
      }
    }
  }
  .promo-code-error-message {
    color: #e00000;
    margin: 4px 8px 16px;
    font-size: 14px;
  }

  .promo-code-message {
    color: #0000008a;
    margin: 4px 8px 16px;
    font-size: 14px;
  }
  @media (min-width: 900px) {
    .MuiAlert-root {
      max-width: 745px;
    }
  }
}

.shipping-options-table {
  height: auto;
  border: 1px solid $c-gray-c8;
  td.MuiTableCell-root {
    font-size: 13px;
    padding: 8px 8px 7px 8px;
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.pickup-steps {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media('>425px') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .pickup-steps--row {
    padding: 0 12px;
    display: flex;
    align-content: baseline;
    flex-wrap: wrap;
    justify-content: center;
    svg {
      justify-self: center;
    }

    h6 {
      justify-self: baseline;
      width: 100%;
    }
  }
}

.remove-promo {
  padding: 0px;
  align-items: start;
}

.promo-desc {
  display: flex;
  margin-top: 8px;
}

.promo-desc button {
  padding: 0 !important;
  height: 30px !important;
}

.str-pickup {
  overflow-wrap: break-word;
}
