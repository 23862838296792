// Mobile Safari < 15 doesn't support aspect-ratio

.image-cell {
    @supports not (aspect-ratio: 16 / 9) {
        @include intrinsic-ratio(16 9);
    }
    @supports not (aspect-ratio: 2 / 1) {
        @include intrinsic-ratio(2 1);
    }
}
