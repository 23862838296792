.free_catalog {
    display: flex;
    align-items: center;
    .Free_Catalog_Privacy {
      margin-left: 28px;          
    }
    @include media('<=650px') {
        display: block;
        .Free_Catalog_Privacy {
          margin: 16px 0 0;          
        }
      }
}