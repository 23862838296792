img.thumbnail-image_full-width {
  width: fit-content;
}

.thumbnail {
  position: relative;
  /* Ensures container wraps around images */
}

.advantage-badge-grid {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
}
