// .nte-card class
//
// Base card block element with radius, background color, responsive padding, and elevation.
//
// Markup:
// <div class="nte-card {{modifier_class}}">Basic NTE Card Component</div><hr>
//
// .sandwich - For full width cards with headers; reduces the top and bottom margin.
// .title-bar - Adds correct bottom margin for free standing cards with headers.
//
// Styleguide Components.Cards.nte-card
%nte-card,
.nte-card {
  background-color: #fff;
  border-radius: $radius;
  @include elevation(1);
  word-break: break-word;
}

//
// CMC Cards
//
.cmc-block {
  @include elevation(1);

  background-color: #fff;
  border-radius: $radius;
  & > .promo-card {
    background-color: transparent;
    box-shadow: none;
  }

  p {
    max-width: 740px;
  }

  &.full-width > div {
    background-color: #fff;
    border-radius: $radius;
    @include elevation(1);
  }

  &.title-subheader p {
    font-size: 18px;
  }
}

.cmc-block.page-row .promo-card.image-left {
  @include media('>415px') {
    grid-template-columns: 20% 1fr;
  }

  .card-content {
    width: 100%;
  }
}

#session-invalid {
  max-width: 540px;
}

button .MuiCard-root {
  text-align: left;
}

.MuiPaper-root.MuiCard-root {
  .MuiCardActions-root {
    a {
      font-size: 14px;
    }
  }
}

//
// Category Cards
//

.page-row .category-list {
  li > a {
    display: block;
    height: 100%;
  }

  .nte-card {
    height: 100%;
    position: relative;

    p {
      color: $c-gray-aa-readable;
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      position: absolute;
      right: 6px;
      text-align: right;
      top: 4px;
      &:hover {
        text-decoration-color: transparent;
      }
    }

    h3 {
      margin-bottom: 0;
      text-align: center;
    }
  }

  .intrinsic-image {
    @include intrinsic-ratio(1 1);

    margin: 0 auto;
    max-width: 280px;
  }
  a.no-link-effect {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 204px;
  }
}

//
// Promo Cards
//
.promo-card {
  h2 {
    line-height: 32px;
  }

  &.dark {
    @include elevation(3);
  }

  &.image-left {
    // Promo card B
    display: grid;
    @include media('<=414px') {
      grid-template-rows: auto auto;
      .intrinsic-image {
        @include intrinsic-ratio(2 1.5);
      }
    }
    @include media('>414px', '<=1024px') {
      grid-gap: 8px;
    }
    @include media('>=415px') {
      grid-template-columns: 48% 1fr;
      .intrinsic-image {
        @include intrinsic-ratio(1 1.2);
      }
    }
    @include media('>1280px') {
      .intrinsic-image {
        @include intrinsic-ratio(1.2 1);
      }
    }
  }
}

.promo-card.dark {
  background-color: #000;
  color: #fff;

  a {
    color: $c-light-blue;

    &:hover {
      border-color: $c-light-blue;
    }
  }
}

.promo-cards-a-content {
  @include media('<=600px') {
    .content_recommendation_layout {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .intrinsic-image {
    @include intrinsic-ratio(16 9);
    @include media('>600px') {
      @include intrinsic-ratio(16 12);
    }
  }
}

.promo-cards-b-content {
  .intrinsic-image {
    max-height: 200px;
    @include media('>768px') {
      max-height: 230px;
    }
  }

  @include media('<=768px') {
    .content_recommendation_layout {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

//
// Misc
//
.MuiPaper-root.MuiCard-root {
  &.stacked {
    display: grid;
    grid-template-rows: auto auto 1fr;

    .MuiCardActions-root {
      align-self: flex-end;
    }
  }

  &.add-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .MuiCardHeader-root {
      display: none;
    }

    .MuiCardContent-root,
    .MuiCardActions-root {
      text-align: center;

      p {
        margin-left: 0;
      }
    }
  }
}

.shipping-cards,
.saved-lists {
  .MuiCardActions-root {
    a:not(:first-child),
    p:not(:first-child) {
      margin-left: 16px;
    }
  }
}

ul.address-cards {
  @include scaled-spacing(margin-bottom, 5, 32px);

  grid-template-columns: repeat(auto-fit, minmax(240px, 320px));
}

.address-container {
  border: 1px solid $c-gray-c8;
  box-shadow: none;

  p.MuiTypography-root {
    font-size: 14px;
    line-height: 20px;
  }
}

body .address_book {
  p.MuiTypography-root {
    margin-bottom: 0;
  }
  & + a {
    font-size: 14px;
  }
}

.delivery-page-address-line-one * {
  display: inline;
}

.delivery-page-address-line-two * {
  display: inline;
}

span.badge {
  &.save-price-badge {
    font-weight: 1;
  }
  color: $c-text;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  height: 22px;
  justify-self: start;
  line-height: 22px;
  padding: 0 8px;
  white-space: nowrap;
}

.nte-border-card > .badge {
  transform: translate(-6px, -6px);
}

#root > .MuiAlert-root {
  bottom: 180px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 328px;
  position: fixed;
  right: 0;
  width: 50%;
  z-index: 100;
}

.nte-border-card {
  border: 1px solid $c-gray-c8;
  border-radius: $radius;
  width: auto;
}

// Horizontal alignment of shipping method cards
.shipping-methods {
  display: flex;
}

a.link-wrapped-card {
  display: block;
  height: 100%;
  width: 100%;
  *:not(.link-effect) {
    color: $c-text;
  }
  &.nte-card:hover {
    @include elevation(6);

    transition: all $global-transition ease-in-out;
  }
  &:hover {
    text-decoration: none;
    text-decoration-color: transparent;
    text-underline-offset: initial;
    transition: none;

    *:not(.link-effect) {
      text-decoration: none;
    }

    .cursor-pointer,
    .nte-card {
      @include elevation(6);

      transition: all $global-transition ease-in-out;
    }
    .link-effect {
      @include link-animation;
    }
  }
  &:focus {
    @include nte-focus;
  }
}

.store_card-block {
  .pickup_store_option {
    height: 100%;
  }
  .nte-border-card {
    display: inline-block;
    .cg-cols-2 {
      column-gap: 8px;
      grid-template-columns: 24px 1fr;
    }
    &.add-card {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
