.nte_popper {
  border-radius: $radius;
  z-index: 1301;
  @include elevation(4);

  &[x-placement='top'] {
    .nte_popper-wrapper {
      position: relative;
      hr.nte_popper-divider {
        bottom: 0;
        position: absolute;
        width: 100%;
      }
      span.nte_popper-arrow {
        bottom: 0;
        position: absolute;
        transform: rotate(180deg);
      }
    }
  }
}

.MuiPaper-root {
  &.nte_popper-paper {
    //max-height: 98vh;
    max-width: 382px;
    overflow: auto;
  }
}

hr.MuiDivider-root {
  &.light {
    background-color: #0000001f;
    border: 0.5px solid #0000001f;
  }
  &.dark {
    background-color: #000;
    border: 0.5px solid #000;
  }
  &.nte_popper-divider {
    background-color: #f5b100;
    border: 2px solid #f5b100;
  }
  &.header_divider {
    background-color: #808080;
  }
}

.nte_popper-arrow {
  font-size: 7px;
  height: 3em;
  position: absolute;
  width: 24px;

  &::before {
    background: inherit;
    border: 10px solid transparent;
    border-bottom-color: $c-secondary;
    border-bottom-width: 10px;
    border-top-width: 0;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    top: -10px;
    width: 0;
  }
}

#root {
  position: relative;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: all $global-transition ease-in;
}

.backdrop-active .backdrop {
  height: 100%;
  left: 0;
  opacity: 1;
  position: absolute;
  top: 145px;
  width: 100%;
  z-index: 2;
}

.nte_popper .save_to_list_menu,
.nte_popper .unsigned_save_list {
  min-width: 200px;
  .menu-list-body.MuiList-padding {
    max-height: 344px;
    overflow-y: auto;
    padding: 0;
    width: calc(100% - 6px);
  }
  p.link-effect {
    font-size: 14px;
    margin-left: 8px;
  }
  li.MuiButtonBase-root {
    width: calc(100% - 8px);
    a {
      align-items: center;
      justify-content: start;
      span {
        align-items: center;
        display: flex;
      }
    }
    svg {
      margin-right: 8px;
    }
  }
}

.nte_popper-wrapper {
  background-color: #fff;
}

.MuiAutocomplete-paper {
  border-top: 4px solid $c-yellow;
}
