@import 'vendor/index';
@import 'variables';
@import 'global_mixins/global_mixins';

.MuiContainer-root {
  padding: 0 !important;
}

.nte-card {
  .MuiSwitch-switchBase {
    top: 8px;
    left: 8px;
  }
}

.MuiPaper-root {
  color: $c-text !important;
  transition: none !important;
}

header.MuiAppBar-root .MuiToolbar-regular {
  @include media('>xlarge') {
    padding-left: 0;
    padding-right: 0;
  }
}

.MuiCardHeader-root,
.MuiCardContent-root,
.MuiCardActions-root,
.MuiAccordionDetails-root {
  padding: 0;
}

.MuiAccordionDetails-root {
  display: block;
}

.MuiButton-startIcon {
  margin-left: 0;
}

.MuiIconButton-edgeEnd {
  margin-right: 0;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0;
}

.MuiPaper-root.MuiDrawer-paper {
  border-radius: $radius;
}

.MuiAccordion-rounded,
.MuiAccordion-rounded:last-child {
  border-radius: $radius;
  @include elevation(1);
}

.MuiAccordionSummary-root {
  padding: 0;
}

.MuiFormControlLabel-root {
  margin-left: inherit;
}

.MuiIconButton-root {
  padding: 0;
}
.MuiFormControlLabel-root {
  margin-right: 0;
}

.MuiImageListItem-item {
  overflow: visible;
}


.MuiSkeleton-root {
  margin: 0 auto;
}

.MuiFormGroup-root {
  flex-direction: row;
}

.MuiPaper-elevation1 {
  @include elevation(1);
}
.MuiPaper-elevation2 {
  @include elevation(2);
}

button.MuiIconButton-root {
  border-radius: 50%;
}
.MuiIconButton-root.MuiCheckbox-root {
  border-radius: 0;
}

// Accommodate the focus drop shadow
.MuiButtonBase-root {
  margin: 0 2px 2px;
}
