.nte_modal {
  .nte_modal-paper {
    display: inline-block;
    left: 50%;
    //max-height: 80vh;
    max-width: 98vw;
    min-width: 320px;
    position: fixed;
    top: 10vh;
    transform: translateX(-50%);
    width: auto;

    .close_icon {
      transform: translate(16px, -16px);
    }
  }

  &.wide {
    .nte_modal-paper {
      max-width: 900px;
    }
  }
}

.nte_modal,
.nte_drawer,
.nte_popper:not(.no-scroll) {
  .nte_modal-paper{
    max-height: 88vh;
    overflow-y: auto;
  }
  .nte_popper-paper {
    @include elevation(6);
    @extend %nte-scrollbar;

    background-color: #fff;
    border-radius: $radius;
    //max-height: 88vh;
    overflow-y: auto;
    box-shadow: none;
    //overflow: hidden;

    /* @include media('>=600px') {
      max-height: 86vh;
    } */
  }
}

.save_to_list_menu,
.unsigned_save_list {
  .menu-list-body {
    @include scaled-spacing(margin-bottom, 5, 32px);
  }

  .form-input-field {
    width: 100%;
  }

  svg[aria-label='already-saved'] {
    fill: $c-green;
  }
}

.nte_popper-wrapper {
  form {
    .form-input-field,
    .MuiFormControl-root {
      max-width: 320px;
    }
  }
}

.repair_plan_modal {
  grid-template-columns: 64px 1fr;
}

.modal-body-gift-card-pin-details {
  overflow: auto;
  @include media('<=600px') {
    max-height: 52vh;
  }
}

// CMC Modals
.cmc-content .modal-container,
#video-modal-container {
  @extend %nte-card;
  @include elevation(6);

  display: inline-flex;
  flex-direction: column;
  height: auto;
  left: 50%;
  max-height: 80vh;
  max-width: 600px;
  min-width: 300px;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  top: 14vh;
  transform: translateX(-500%);
  visibility: hidden;
  z-index: 200;

  &.active {
    opacity: 1;
    transform: translateX(-50%);
    visibility: visible;
  }

  .close_icon {
    @include svg-elevation(1);

    align-self: flex-end;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    border: none;
    border-radius: 50%;
    box-shadow: none;
    display: block;
    height: 24px;
    line-height: 1;
    min-height: 24px;
    padding: 0;
    width: 24px;

    &:hover {
      @include elevation(1);

      background-color: $c-secondary;
    }
  }
}

#video-modal-container.modal-container {
  max-height: 1000px;
  max-width: 1040px;
  min-width: auto;

  .close_icon {
    margin-bottom: 16px;
  }
}
