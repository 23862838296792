%nte-base-style {
  color: $c-text;
  font-family: $family-sans-serif;
  font-size: 16px;
  line-height: 24px;
}

html, p, li, td, section, div {
  @extend %nte-base-style;
}

html a, a.MuiTypography-colorPrimary {
  @include link-effect;
  p,
  .MuiTypography-root {
    @include link-effect;
  }
}

.MuiPaper-root {
  p {
    @extend %nte-base-style;
    &.body14,
    &.body2,
    &.MuiTypography-body2 {
      font-size: 14px;
    }
  }
  a,
  a.MuiLink-root {
    font-size: inherit;
    @include link-effect;
  }
  .MuiList-padding {
    padding-bottom: 0;
    padding-top: 0;
  }
}

p:not(.size24), .cmc-content li:not(.product-block), dt, dd {
  max-width: $optimal-readability-width;
}

body {
  h2, h3, h4, h5, h6 {
    font-family: $family-headers;
  }
  h1,
  h1.MuiTypography-h1,
  %h1 {
    @include fluid-font-size(28px, 48px, 360px, 1440px);

    font-family: $family-h1;
    font-weight: 700;
  }

  h2,
  h2.MuiTypography-h2,
  %h2 {
    @include fluid-font-size(20px, 32px, 320px, 1440px);
    font-weight: 700;
  }

  h3,
  h3.MuiTypography-h3,
  %h3 {
    @include fluid-font-size(16px, 24px, 360px, 1440px);
    font-weight: 700;
  }

  h4,
  h4.MuiTypography-h4,
  %h4 {
    @include fluid-font-size(14px, 20px, 360px, 1440px);
    font-weight: 700;
  }

  @include media('<=720px') {
    h1,
    h1.MuiTypography-h1 {
      line-height: 1.2;
    }
    h2, h3,
    h2.MuiTypography-h2, h3.MuiTypography-h3{
      line-height: 32px;
    }
  }

  strong {
    font-weight: 700;
  }
}

.cmc-content .narrow720:not(.grid),
.cmc-content .narrow960:not(.grid) {
  display: flow-root;
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
  }
}

// Horizontal alignment of typography and icon in headings.
body {
  .icon-text {
    align-items: center;
    column-gap: 8px;
    display: inline-flex;
    grid-template-columns: auto 1fr;
    // Safari 14< does not support flex gap - https://ppuzio.medium.com/flexbox-gap-workaround-for-safari-on-ios-14-13-and-lower-ffcae589eb69
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      svg,
      .credit-card-image-container {
        margin-right: 8px;
      }
    }

    h2, h3 {
      margin-bottom: 0 !important;
    }
  }

  h2.icon-text, h3.icon-text {
    align-items: center;
  }
}

