body {
  background-color: $c-gray-f2;
  overflow-y: scroll;
  width: 100%;
}

.title-block {
  h2,
  h3 {
    + p a {
      margin-left: 16px;
    }
  }
}

.page-title,
.page-title-margin-top {
  &.has-background-image {
    background-color: #000;

    .content-wrapper {
      @include modular-spacing(padding-left, 12px, 2);

      background-color: rgb(0 0 0 / 30%);
      grid-area: content;
      height: 100%;
      place-self: start;
      width: 100%;
      z-index: 1;

      h2,
      h3 {
        font-size: 18px;
        line-height: 24px;
      }

      & > *,
      .bread_crumbs li {
        color: #fff;
        text-shadow: 2px -1px 5px rgb(0 0 0 / 80%);
      }

      .cmc-content {
        background-color: rgb(0 0 0 / 70%);
        padding: 16px;

        a {
          color: $c-light-blue;
        }
      }
    }

    &.xtrahigh {
      .background-image {
        height: 100%;
      }
    }
  }

  .background-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    grid-area: content;
    height: 140px;
    width: 100%;
  }
}

.gradient-overlay {
  height: 100%;
  width: 20%;

  &.left {
    background: linear-gradient(
      90deg,
      rgb(0 0 0 / 100%) 0%,
      rgb(0 0 0 / 90%) 9%,
      rgb(0 0 0 / 70%) 68%,
      rgb(255 255 255 / 0%) 100%
    );
    grid-area: content;
    place-self: start;
    @include modular-spacing(width, 280px, 2);
  }

  &.right {
    background: linear-gradient(
      270deg,
      rgb(0 0 0 / 100%) 0%,
      rgb(0 0 0 / 80%) 9%,
      rgb(255 255 255 / 0%) 100%
    );
    grid-area: content;
    place-self: end;
  }
}

.background-image-grid,
.wrap-all {
  @extend .full-width;

  display: grid;
  grid-template-areas: 'content';
  grid-template-columns: 1fr;
  place-content: center;
  place-items: center;
}

.skip-to-content-link {
  background: $c-blue;
  color: #fff;
  cursor: pointer;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 10;

  &:focus {
    transform: translateY(0%);
  }
}

.content_recommendation_layout {
  .MuiImageListItem-tile > div {
    height: 100%;
  }
}

.category_top_brands {
  .cg-cols-auto {
    grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));

    @include media('>medium') {
      grid-template-columns: repeat(6, 1fr);
    }

    a {
      display: block;
    }

    .MuiPaper-root.MuiCard-root {
      height: 100%;
      position: relative;

      p {
        color: $c-gray-aa-readable;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        position: absolute;
        right: 4px;
        text-align: right;
        top: 4px;
      }
    }
  }

  .category-list .thumbnail {
    max-width: 150px;
    width: 100%;
  }
}

body .icon-row.my-store {
  svg {
    fill: $c-green;
    height: 24px;
    width: 24px;
  }
}

.icon_text.MuiFormControlLabel-root {
  margin-left: 0;

  svg {
    margin-right: 8px;
  }
}

.brands_layout {
  .page-title {
    height: auto;

    h1 {
      padding-left: 0;
    }
  }
}

.fulfillment-content {
  ul {
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));

    @include media('>=960px') {
      grid-auto-flow: row;
      grid-gap: 8px;
      grid-template-columns: repeat(6, 1fr);
    }

    @include media('<960px') {
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media('<=480px') {
      grid-auto-flow: row;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.product-list {
  .icon-row.fulfillment {
    @include scaled-spacing(margin-bottom padding-bottom, 5, 24px);

    border-bottom: 1px solid $c-gray-e5;
    column-gap: 12px;

    svg {
      transform: translateY(0);
    }

    .label-wrapper {
      font-family: $family-headers;
    }

    .shipping-label {
      color: $c-gray-aa-readable;
    }

    .shipping-status {
      font-weight: 700;
    }
  }
}

.paypal-logo {
  height: 32px;
  padding: 4px 12px;
  width: 88px;

  & + p {
    line-height: 32px;
  }
}

.suggested_address {
  max-height: 200px;
  overflow-y: auto;
  @extend %nte-scrollbar;

  .MuiFormControlLabel-root.radio-wrapper {
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}

.street-address-container {
  position: relative;

  #type-ahead-suggestions {
    background-color: white;
    border: 1px solid black;
    position: absolute;
    z-index: 100;
  }
}

.phone-tool-tip {
  display: flex;
  .form-input-field {
    flex-grow: 1;
  }
}

.address_suggestion_list {
  .selectable {
    font-weight: 700;
  }

  &.menu-list-body {
    max-height: 300px;
  }
}

.avs_modal.modal_body {
  .address-suggestions {
    @include elevation(4);
  }
}

.backdrop-active .avs-input {
  z-index: 3;
}

.catalog_request_form {
  .add-business-name-container,
  .add-street-address2 {
    button.plain-icon-button {
      display: inline-flex;
    }
  }
}

.change_password_form {
  .cg-cols-2 {
    display: block;

    @include media('>=680px') {
      display: grid;
      grid-template-columns: 300px 300px;
      grid-template-rows: 48px auto;
      margin: 0;
    }
  }

  .password_input {
    grid-column: 1;
    grid-row: 1;
  }

  .nte_set_password_fields {
    @include media('<680px') {
      margin-bottom: 24px;
    }

    grid-column: 1;
    grid-row: 2;

    .error {
      &.MuiFormControl-root {
        margin-bottom: 12px;
      }
    }

    .custom-error-text {
      margin-bottom: 16px;
    }
  }

  .password-requirements-container {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .form-field-error {
    margin-bottom: 24px;
  }
}

.password_input {
  button.MuiButtonBase-root.MuiIconButton-root {
    border-radius: $radius;
    font-size: 14px;
    height: 32px;
    padding: 0 8px;
  }
}

hr,
hr.MuiDivider-root {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $c-gray-c8;

  &.MuiDivider-vertical {
    border-right: 1px solid $c-gray-c8;
  }
}

.store_finder {
  .menu-back-link {
    margin-bottom: 16px;
  }

  .store-name {
    font-weight: 700;
    margin-bottom: 12px;

    &:not(.is-selected) {
      margin-bottom: 12px;
    }
  }

  ul.stores {
    @extend %nte-scrollbar;
    @include scaled-spacing(margin-top, 2);

    //max-height: calc(100vh - 320px);
    overflow-y: auto;

    button.nte_collapse {
      &.expanded {
        margin-bottom: 8px;
      }
    }

    li {
      margin-right: 16px;

      .my-store-button {
        margin: 0 0 12px 0;
        display: block;
      }
    }

    .store-name-row {
      flex-wrap: nowrap;
    }

    .store-details--button {
      padding: 0;
      height: 32px;

      &:hover {
        background: #fff;
      }
    }

    .miles-distant {
      min-width: 64px;
      text-align: right;
      white-space: nowrap;
    }
  }

  .my-store {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;

    svg.MuiSvgIcon-root {
      fill: $c-green;
      height: 24px;
      margin-right: 4px;
      width: 24px;
    }
  }

  .near_by_store_tile {
    .announcements-block {
      margin: 8px 0;
    }

    .hours-block {
      margin: 8px 0;
    }

    .phone {
      margin-bottom: 8px;
    }

    .nte_alert {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}

#stock-locator-header {
  margin-bottom: 16px;
}

.store_page_layout {
  .page-title {
    @include scaled-spacing(margin-bottom, 6, 32px);
  }

  .store-badge {
    margin-bottom: 8px;
  }

  span.sizeh4.block {
    margin-bottom: 0;
  }

  .store-pages {
    grid-template-columns: 360px 1fr;

    .store_finder {
      @extend %nte-card;
    }
  }

  .store-hours {
    li {
      border-bottom: 1px solid $c-gray-c8;
      display: flex;
      justify-content: space-between;
      padding: 7px 0;

      &:last-child {
        border-bottom-color: transparent;
      }
    }
  }

  .check-icon {
    fill: $c-green;
  }

  .google-review {
    .icon-text {
      align-items: center;
    }

    img {
      height: 24px;
      width: 24px;
    }
  }

  .store_finder {
    .nte_primary_search_bar {
      margin-top: 0;
    }

    ul.stores {
      height: auto;
    }
  }

  // Ipad only fix for 4col store locator layout
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .cg-cols-2.page-row {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: max-content;

      .nte-card {
        height: auto;
      }
    }

    .cg-cols-4.page-row {
      grid-template-rows: max-content;

      .cmc-content {
        .image-cell::before {
          display: none;
        }
      }
    }
  }
}

.nte_google_map {
  display: flex;
  height: 100%;
  min-height: 360px;

  @include media('>=600px') {
    min-height: 400px;
  }

  @include media('>=960px') {
    min-height: 446px;
  }

  @include media('>=1280px') {
    min-height: 520px;
  }

  .map_flex {
    flex-grow: 1;
  }
}

.catalog_text {
  align-items: baseline;
  display: grid;
  grid-template-columns: 60px 1fr;

  .bold-weight {
    font-weight: 700;
  }
}

.catalog_heading {
  display: grid;
  grid-template-columns: 20px 1fr 1fr 1fr;
  justify-items: center;
}

.catalog_form-inputs {
  align-items: start;
  column-gap: 16px;
  grid-template-columns: 28px 1fr 1fr 1fr;

  h3.catalog_form-num.MuiTypography-h3 {
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.catalog_order_form {
  .margin-left {
    margin-left: 48px;

    @include media('<600px') {
      margin-left: 40px;
      width: 80%;
    }
  }
}

.gift_card_balance {
  .fb {
    column-gap: 16px;
    @include safari-fb-fix(div, 16px);

    .balance {
      @include media('<=943px') {
        margin-bottom: 24px;
      }

      @include media('>943px') {
        margin-left: 16px;
      }

      background-color: $c-gray-f2;
      height: 48px;
      line-height: 48px;
      padding: 0 12px;
    }
  }

  .tool-tip {
    display: flex;
  }
}

.wattage_appliance-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.wattage-footer-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .MuiButton-contained {
    @include media('<=420px') {
      width: 40%;
    }
  }
}

.wattage-textbtn-wrap {
  display: flex;
  gap: 60px;

  @include media('<600px') {
    gap: 15px;
  }
}

.error {
  .MuiAlert-standardError {
    display: flex;
  }
}

.print-page {
  @include modular-spacing(right, 12px, 2);

  position: absolute;
  top: 20px;
  align-items: center;
  color: #000;
  column-gap: 8px;
  display: flex;
  font-size: 14px;
  cursor: pointer;
}

.print-page:hover {
  text-decoration: underline;
}

.hide-print-button {
  @include media('<1080px') {
    display: none;
  }
}

.print-logo {
  display: none;
}

.print-image {
  display: none;
}

@media print {
  .print-exclude {
    display: none !important; // hides the components when user prints a page
  }
  .print-logo {
    justify-content: center;
    margin: 10px 0px;
    width: 100%;
    display: flex;
  }

  .print-logo svg {
    width: 40%;
    height: auto;
  }

  .print-title .card_page_title > h1 {
    font-size: 24px;
    line-height: normal;
  }

  .print-image {
    display: flex;
    justify-content: center;
  }

  .print-image .zoom-image {
    width: 85%;
  }

  #PDP_OL_IMAGES {
    display: none;
  }
}

#account-signin {
  .nte_modal-paper {
    max-width: 348px;
  }
}

.wattage_calculator {
  margin-top: 48px;

  .MuiAccordion-rounded {
    margin-bottom: 0;
  }

  .margin-top {
    margin-top: 24px;
  }

  .wattage-footer-divder {
    margin-top: 24px;
    position: relative;
  }

  .MuiChip-clickable {
    height: auto;

    .MuiChip-label {
      white-space: normal;
    }
  }
}

.sitemap_page {
  .sitemap-links {
    column-count: 3;
    column-width: 310px;
  }

  section:not(:last-child) {
    @include scaled-spacing(margin-bottom, 5, 40px);

    break-inside: avoid-column;
  }
}

.update_address_form {
  position: relative;

  .address-suggestions {
    max-height: 300px;

    ul {
      background-color: #fff;
      overflow: hidden;
      z-index: 1;
    }
  }

  ul.list-scroll {
    overflow-y: scroll;
    @include elevation(2);
  }

  .not-selectable {
    color: $c-gray-c8;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.info-icon {
  cursor: pointer;
}

.grecaptcha-badge {
  visibility: hidden;
}

.product_comparison,
.product_compare_modal {
  .MuiAccordionDetails-root,
  .modal_body {
    position: relative;

    div[role='rowheader'] {
      height: 48px;

      span {
        left: 0;
        position: absolute;
      }
    }
  }

  .swipe-prompt {
    @include elevation(6);

    align-items: center;
    background-color: rgb(255 255 255 / 90%);
    border-radius: $radius;
    bottom: 0;
    justify-content: center;
    left: 50%;
    padding: 4px 8px;
    position: fixed;
    text-align: center;
    transform: translateX(-50%);
    width: 212px;
    z-index: 2000;

    @include media('>=1440px') {
      display: none;
    }

    p {
      margin: 0 8px;
    }
  }
}

.product_compare_table {
  display: table;
  padding: 0;

  .table-header,
  .table-row:not(.background-color-c8) {
    column-gap: 4px;
    display: grid;
    grid-auto-flow: column;
    width: 100%;

    span {
      font-size: 16px;
      font-weight: 400;
      width: 266px;

      @include media('>768px') {
        width: 288px;
      }
    }

    &.highlight {
      background-color: $c-yellow;
    }
  }

  span.table-cell {
    font-size: 16px;
    font-weight: 400;
  }

  .table-body .table-row {
    padding-bottom: 12px;
    padding-top: 12px;

    span {
      @include scaled-spacing(padding-left, 4, 24px);
    }
  }

  .background-color-c8 .product-compare_table-cell {
    font-weight: 700;
  }

  .table-header {
    display: flex;

    .table-cell {
      @include scaled-spacing(padding, 4, 24px);

      background-color: #000;
    }

    span {
      color: #fff;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      line-height: 24px;
    }
  }
}

.nte_product_page_layout {
  .product_compare_table .table-header {
    .table-cell {
      width: 288px;

      @include media('>768px') {
        width: 318px;
      }
    }

    span {
      width: auto;
    }
  }
}

.card_save_list {
  svg {
    margin-right: 4px;
    transition: all $global-transition ease-in-out;
  }

  &:hover svg {
    @include svg-elevation(3);
  }
}

#gift-card-pin-details .nte_modal-paper {
  max-width: 96vw;
}

.gift_card_pindetails {
  img {
    height: auto;
    min-width: 300px;
    width: 100%;
  }

  @include media('<660px') {
    display: block;

    div:nth-child(2) {
      margin-bottom: 40px;
    }
  }

  @include media('>=660px') {
    grid-template-columns: 300px 264px;
  }

  @include media('>=836px') {
    grid-template-columns: 350px 350px;
  }
}

.MuiTypography-root.product-title.MuiTypography-body1 a {
  font-size: 18px;
}

.frequently_bought_together {
  .thumbnail {
    transition: $global-transition;

    &.thumbnail-opacity {
      opacity: 0.4;
    }
  }
}

.shipping_info_drawer {
  .qty-cell {
    width: 104px;
  }
}

.shipping_lift_warning {
  font-weight: bold;
  font-style: italic;
}

.catalog_request_thank_you {
  .cg-cols-2 {
    grid-template-columns: 1fr 30%;
  }

  .address_book * {
    font-size: 16px;
  }
}

.catalog_number_example {
  .modal_body {
    grid-template-columns: 36% 1fr;
  }
}

.image-link,
.video-link,
.video-trigger,
.threesixty-link {
  display: block;
  transition: all $global-transition ease-in-out;

  &:hover {
    @include elevation(6);
  }
}

.video-link,
.video-trigger {
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    height: 90px;
    position: absolute;
    width: 90px;
    z-index: 1;
  }

  &::after {
    @include elevation(3);

    background-image: url('https://assets.northerntool.com/icons/nte-play.svg');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    content: '';
    height: 36px;
    left: 0;
    margin: 0 auto;
    padding-bottom: 40%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    z-index: 2;
  }
}

.threesixty-link button,
.media-modal .threesixty-link {
  position: relative;

  &::before {
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  &::after {
    @include elevation(3);

    background-color: $c-yellow;
    background-image: url('https://assets.northerntool.com/icons/rotate360.svg');
    background-position: 60% center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: 2px solid $c-text;
    border-radius: 50%;
    content: '';
    height: 36px;
    left: 0;
    margin: 0 auto;
    @include media('<768') {
      padding-bottom: 27%;
    }

    @include media('>768') {
      padding-bottom: 40%;
    }
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 38px;
    z-index: 2;
  }
}

.media-modal .threesixty-link {
  cursor: pointer;

  &::after {
    width: 40px;
  }
}

#edeals-signup-modal {
  p.details {
    @extend %field-margin;
  }
}

.edeals-signup-form {
  .form-input-field {
    margin-bottom: 28px;
  }

  @include media('>=600px') {
    display: flex;
    overflow: hidden;

    .form-input-field {
      margin-bottom: 0;
      margin-right: 24px;
      min-width: 300px;
    }
  }
}

.catalog-order-form {
  label.MuiFormLabel-root.MuiInputLabel-outlined {
    transform: translate(8px, -5px) scale(0.75);
  }

  .catalog_form-inputs:nth-child(n + 2) {
    label.MuiFormLabel-root:not(.Mui-error) {
      display: none;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin-bottom: 12px;
  }
}

body .contact_us_form {
  max-width: 616px;

  .MuiFormControl-root,
  select.MuiSelect-select {
    max-width: 100%;
  }

  textarea {
    min-width: 100%;
  }
}

// Payment forms

.add_new_saved_card,
.checkout_payment {
  label.credit-debt-radio {
    margin: 0;
  }

  .credit-cards,
  .net30-wrapper {
    grid-template-columns: 1fr 80px;

    @include media('>=568px') {
      grid-template-columns: 1fr 50%;
    }

    @include media('>=720px') {
      grid-template-columns: 260px 1fr;
    }
  }

  .remove-old-trevi {
    background-color: #f0f0f0;
    padding: 24px 16px;
    width: 52%;
  }

  .trevi-add-card {
    display: flex;
    label {
      margin-right: 8px;
    }
  }

  .field-row-2 {
    align-items: start;

    @include media('>=568px') {
      align-items: center;
    }
  }

  .form-input-field {
    margin-bottom: 0;
  }

  button {
    @include media('>768px') {
      max-width: 200px;
    }
  }

  .card-number,
  .card-number.MuiFormControl-root {
    min-width: 272px;
    width: 100%;

    &.net-30-card-number {
      height: auto;

      @include media('<=425px') {
        max-width: none;
      }
    }
  }

  .save-card-checkbox {
    margin-top: 24px;
  }

  #creditCardDiv {
    grid-area: card-input;
  }

  .credit-debit-block {
    display: grid;
    gap: 32px;
    position: relative;

    .card-number {
      grid-area: card-input;
    }

    .expiration-cell {
      display: grid;
      gap: 16px;
      grid-area: expiration-cell;
    }

    button {
      grid-area: button;
    }

    .checkout--save-card {
      grid-area: save-card;
    }

    .card-logo {
      left: 288px;
      position: absolute;
      top: 12px;
    }
  }

  .net30-form .field-row-2 {
    grid-template-columns: 1fr;
    column-gap: 24px;
    align-items: start;

    @include media('>=768px') {
      grid-template-columns: 300px 70px;
    }
  }

  .MuiFormControl-root {
    &.expiration-month,
    &.expiration-year {
      margin-bottom: 0 !important;
    }

    &.expiration-month {
      grid-area: exp-mos;
    }

    &.expiration-year {
      grid-area: exp-yr;
    }
  }
}

.payment-brands-container {
  align-items: center;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 36px);

  @include media('>=568px') {
    grid-gap: 16px;
    grid-template-columns: repeat(4, 45px);
  }
}

.credit-card-image-container {
  img {
    width: 36px;

    @include media('>=640px') {
      height: 28px;
      width: 45px;
    }
  }
}

.store-feedback-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.enableFeedbackButton {
  display: none;
}
.cover-Paypal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5); /* Adjust transparency here */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto; /* Allow interactions within the overlay itself */
  z-index: 1000;
}
.overlay-text {
  color: white;
  font-family: Arial, sans-serif;
  text-align: center;
}

.paypal-logo {
  font-weight: bold;
  color: rgb(249, 170, 5);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.paypal-logo span {
  color: rgb(252, 212, 105);
}
