.store-address {
  h4 {
    margin-bottom: 8px;
  }
}

#my-store,
div[id$='-menu'] {
  max-width: 360px;
  min-width: 320px;

  &.nte_popper .nte_popper-paper {
    max-height: 100%;
  }
}

.nte_popper,
.nte_drawer,
.store_finder {
  .menu_list_header {
    border-bottom: 1px solid $c-gray-c8;
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;
    position: relative;

    .cta-top {
      display: flex;
      justify-content: space-between;
    }

    h2 {
      font-size: 24px;
    }

    &:not(.show-back) {
      .cta-top {
        justify-content: flex-end;
      }
    }
  }

  .deals a {
    @extend %no-link-effect;

    &:hover {
      color: #000;
    }
  }

  .menu_list_body,
  .menu-list-body,
  .deals {
    @include media('<=360px') {
      padding-bottom: 24px;
    }
    @extend %nte-scrollbar;

    max-height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;

    @include media('>667px') {
      max-height: calc(86vh - 224px);
    }

    li {
      border-bottom: 1px solid $c-gray-e5;
      cursor: pointer;
      transition: all $global-transition ease-in-out;

      &:last-child {
        border-bottom-color: transparent;
      }

      &:nth-child(1) {
        border-bottom-color: $c-gray-e5;
      }

      &:hover {
        background-color: $c-gray-e5;
      }

      a {
        color: $c-gray-50;
        flex-wrap: nowrap;
        font-size: 14px;
        line-height: 24px;
      }
    }

    p.support_sales_item,
    p.support_customer_care_item,
    p.support_text_item {
      color: inherit;
    }
  }

  .nte_popper-paper {
    .searchBarWrapper {
      @include scaled-spacing(margin-bottom);
      @include elevation(1);

      border-width: 1px;
    }
  }

  .no-store {
    padding-bottom: 24px;
  }
}

#stock-locator-header.field-margin-top {
  display: inline-block;
}

#account-menu {
  .content-wrapper > div hr:last-child {
    display: none;
  }

  .menu_list_header {
    border-bottom-color: transparent;
  }
}

ul.stores {
  li {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}

.menu-back-link {
  @include scaled-spacing(margin-bottom, 3, 32px);
  @include media('<=720px') {
    margin-bottom: 28px;
  }
}

// Menu Animation
div[id$='-menu'] {
  .nte_popper-wrapper {
    overflow: hidden;
  }

  .nte_popper-paper {
    animation: dropdownmenu 250ms;
    animation-timing-function: ease-in-out;
  }
}

#suggestion-menu .nte_popper-paper {
  animation: none;
  max-height: calc(100vh - 164px);
}

#my-store-menu,
#store-locator-drawer,
.store_page_layout {
  .nte_popper-paper {
    animation: none;
  }

  .out-of-stock,
  .in-stock {
    font-weight: 700;
    margin-bottom: 16px;
  }

  .out-of-stock {
    color: $c-gray-aa-readable;
  }

  .in-stock {
    color: $c-success;
  }

  .d365 {
    margin-bottom: 16px;
  }
}

@keyframes dropdownmenu {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slidesideways {
  0% {
    opacity: 0;
    transform: translateY(-100%) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.nte_keyboard-action {
  background-color: $c-gray-e5;
}
